import React from 'react'
import Header from '../common/Header'
import Footer from '../common/Footer'

function RistaApps() {
  return (
    <div>
      

        <div className='new-des'>
          {/* <div className='banner-latest-add'>
              <img src={require('../../images/banner-latest-add.png')} alt="" />
          </div> */}
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSefxooDfqjfMQH0zmjVmF6uRpYN-CtaYj9ZzMR_Yk71V4jdCw/viewform" width="640" height="1303" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </div>

      <Footer />
    </div>
  )
}

export default RistaApps