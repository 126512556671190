//prettier-ignore
import Footer from "../common/Footer";
import Header from '../common/Header';
import BrandLogo from '../common/BrandLogo';
// import "./home.css";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import mid_background from '../../images/Background.jpg';
import hm_srvc_icon1 from '../../images/hm_srvc_icon1.svg';
import hm_srvc_icon2 from '../../images/hm_srvc_icon2.svg';
import hm_srvc_icon3 from '../../images/hm_srvc_icon3.svg';
import hm_srvc_icon4 from '../../images/hm_srvc_icon4.svg';
import cestar from '../../images/cestar.svg';
import whyicon1 from '../../images/why_rista_icon1.svg';
import whyicon2 from '../../images/why_rista_icon2.svg';
import whyicon3 from '../../images/why_rista_icon3.svg';
import whyicon4 from '../../images/why_rista_icon4.svg';
import whyicon5 from '../../images/why_rista_icon5.svg';
import whyicon6 from '../../images/why_rista_icon6.svg';
import whyicon7 from '../../images/why_rista_icon7.svg';
import whyicon8 from '../../images/why_rista_icon8.svg';
import whyicon9 from '../../images/why_rista_icon9.svg';
import whyicon10 from '../../images/why_rista_icon10.svg';
import whyicon11 from '../../images/why_rista_icon11.svg';
import whyicon12 from '../../images/why_rista_icon12.svg';
import hmleararrow from '../../images/hm_leararrow.svg';
import whyicon1mob from '../../images/why_rista_icon1_mob.svg';
import whyicon2mob from '../../images/why_rista_icon2_mob.svg';
import whyicon3mob from '../../images/why_rista_icon3_mob.svg';
import whyicon4mob from '../../images/why_rista_icon4_mob.svg';
import whyicon5mob from '../../images/why_rista_icon5_mob.svg';
import whyicon6mob from '../../images/why_rista_icon6_mob.svg';
import hmleftarrow from '../../images/hmleft_arrow.svg';
import hmrightarrow from '../../images/hmright_arrow.svg';
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import './homes.css';
import NiceSelect from '../../NiceSelect/NiceSelect';
import successcheck2 from '../../images/success-check2.svg';
import successcheck3 from "../../images/brand-1.png";
import successcheck4 from "../../images/brand-2.png";
import successcheck5 from "../../images/brand-3.png";
import successcheck6 from "../../images/brand-4.png";
import successcheck7 from "../../images/brand-5.png";
import successcheck8 from "../../images/brand-6.png";

import successcheck9 from "../../images/brand-7.png";
import successcheck10 from "../../images/brand-8.png";
import successcheck11 from "../../images/brand-9.png";
import successcheck12 from "../../images/brand-10.png";
import successcheck13 from "../../images/brand-11.png";
import successcheck14 from "../../images/brand-12.png";

import successcheck15 from "../../images/brand-13.png";
import successcheck16 from "../../images/brand-14.png";
import successcheck17 from "../../images/brand-15.png";
import successcheck18 from "../../images/brand-16.png";
import successcheck19 from "../../images/brand-17.png";
import successcheck20 from "../../images/brand-18.png";
import successcheck21 from "../../images/brand-19.png";

import successcheck22 from "../../images/brand-20.png";
import successcheck23 from "../../images/brand-21.png";
import successcheck24 from "../../images/brand-22.png";


import { Helmet } from 'react-helmet-async';
// import '../../Ticker.css';
import '../../Ticker.css'; // Ensure this CSS file is created as shown next
import SeamlessLogos from "../common/SeamlessLogos";


const companies = [
  {
    name: "Coinbase",
    logo: successcheck3,
  },
  { name: "Wise", logo: successcheck4 },
  {
    name: "Headspace",
    logo: successcheck5,
  },
  { name: "Airbnb", logo: successcheck6 },
  { name: "Uber", logo: successcheck7 },
  { name: "Nike", logo: successcheck8 },
];

const Ticker = ({ direction }) => {
  const animationClass = direction === "left" ? "scroll-left" : "scroll-right";

  return (
    <div className={`ticker-container`}>
      <div className={`ticker ${animationClass}`}>
        <div className="ticker-content">
          {companies.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>{" "}
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>{" "}
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>{" "}
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <img src={company.logo} alt={`${company.name} logo`} />
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const companies1 = [
  {
    name: "Coinbase",
    logo: successcheck9,
  },
  { name: "Wise", logo: successcheck10 },
  {
    name: "Headspace",
    logo: successcheck11,
  },
  { name: "Airbnb", logo: successcheck12 },
  { name: "Uber", logo: successcheck13 },
  { name: "Nike", logo: successcheck14 },
];

const Ticker1 = ({ direction }) => {
  const animationClass = direction === "left" ? "scroll-left" : "scroll-right";

  return (
    <div className={`ticker-container`}>
      <div className={`ticker ${animationClass}`}>
        <div className="ticker-content">
          {companies1.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>{" "}
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies1.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>{" "}
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies1.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>{" "}
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies1.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <img src={company.logo} alt={`${company.name} logo`} />
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies1.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
const companies2 = [
  {
    name: "Coinbase",
    logo: successcheck13,
  },
  { name: "Wise", logo: successcheck14 },
  {
    name: "Headspace",
    logo: successcheck15,
  },
  { name: "Airbnb", logo: successcheck16 },
  { name: "Uber", logo: successcheck17 },
  { name: "Nike", logo: successcheck18 },
  { name: "Nike", logo: successcheck19 },
  { name: "Nike", logo: successcheck20 },
  { name: "Nike", logo: successcheck21 },
];
const Ticker2 = ({ direction }) => {
  const animationClass = direction === "left" ? "scroll-left" : "scroll-right";

  return (
    <div className={`ticker-container`}>
      <div className={`ticker ${animationClass}`}>
        <div className="ticker-content">
          {companies2.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>{" "}
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies2.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>{" "}
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies2.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>{" "}
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies2.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <img src={company.logo} alt={`${company.name} logo`} />
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies2.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const companies3 = [
  {
    name: "Coinbase",
    logo: successcheck22,
  },
  { name: "Wise", logo: successcheck23 },
  {
    name: "Headspace",
    logo: successcheck24,
  },
];
const Ticker3 = ({ direction }) => {
  const animationClass = direction === "left" ? "scroll-left" : "scroll-right";

  return (
    <div className={`ticker-container`}>
      <div className={`ticker ${animationClass}`}>
        <div className="ticker-content">
          {companies3.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>{" "}
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies3.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>{" "}
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies3.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>{" "}
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies3.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <img src={company.logo} alt={`${company.name} logo`} />
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies3.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies3.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies3.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies3.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies3.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies3.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="ticker-content">
          {companies3.map((company, index) => (
            <div className="ticker-item" key={index}>
              <div className="logo-container">
                <span>
                  <img src={company.logo} alt={`${company.name} logo`} />
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1,
  },
};

const Home = () => {
  const location = useLocation();
  const resetForms = () => {
    setFormData({
      name: '',
      mobileNumber: '',
      pincode: '',
      incomeSource: '',
      buss_age: '',
      buss_turnover: '',
      email: '',
    });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  };

  const [firstFormId, setFirstFormId] = useState('');

  const [isSectionVisible, setIsSectionVisible] = useState(false);
  const [areTabsVisible, setAreTabsVisible] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);


  // const [activeTab, setActiveTab] = useState('flamingo');

  const [activeTab, setActiveTab] = useState('flamingo');
  // const [activeTab, setActiveTab] = useState("flamingo");

  const handleNavItemClick = (tabId) => {
    setActiveTab(tabId);
  };

  const handlePrevTab = () => {
    const tabOrder = ["flamingo", "cuckoo", "ostrich", "tropicbird-1"];
    const currentIndex = tabOrder.indexOf(activeTab);
    if (currentIndex > 0) {
      setActiveTab(tabOrder[currentIndex - 1]);
    }
  };

  const handleNextTab = () => {
    const tabOrder = ["flamingo", "cuckoo", "ostrich", "tropicbird-1"];
    const currentIndex = tabOrder.indexOf(activeTab);
    if (currentIndex < tabOrder.length - 1) {
      setActiveTab(tabOrder[currentIndex + 1]);
    }
  };
  // const handlePrevTab = () => {
  //   switch (activeTab) {
  //     case 'cuckoo':
  //       setActiveTab('flamingo');
  //       break;
  //     case 'ostrich':
  //       setActiveTab('cuckoo');
  //       break;
  //     case 'tropicbird':
  //       setActiveTab('ostrich');
  //       break;
  //     case 'tropicbird-1':
  //       setActiveTab('tropicbird');
  //       break;
  //     default:
  //       break;
  //   }
  // };

  // const handleNextTab = () => {
  //   switch (activeTab) {
  //     case 'flamingo':
  //       setActiveTab('cuckoo');
  //       break;
  //     case 'cuckoo':
  //       setActiveTab('ostrich');
  //       break;
  //     case 'ostrich':
  //       setActiveTab('tropicbird');
  //       break;
  //     case 'tropicbird':
  //       setActiveTab('tropicbird-1');
  //       break;
  //     default:
  //       break;
  //   }
  // };

  // const handleNavItemClick = (tab) => {
  //   setActiveTab(tab);
  // };

  // const handlePrevTab = () => {
  //   console.log('Current activeTab before Prev:', activeTab);

  //   switch (activeTab) {
  //     case 'cuckoo':
  //       setActiveTab('flamingo');
  //       break;
  //     case 'ostrich':
  //       setActiveTab('cuckoo');
  //       break;
  //     case 'tropicbird':
  //       setActiveTab('ostrich');
  //       break;
  //     case 'tropicbird-1':
  //       setActiveTab('tropicbird');
  //       break;
  //     default:
  //       console.log('No previous tab action for:', activeTab);
  //       break;
  //   }

  //   console.log('New activeTab after Prev:', activeTab);
  // };

  // const handleNextTab = () => {
  //   console.log('Current activeTab before Next:', activeTab);

  //   switch (activeTab) {
  //     case 'flamingo':
  //       setActiveTab('cuckoo');
  //       break;
  //     case 'cuckoo':
  //       setActiveTab('ostrich');
  //       break;
  //     case 'ostrich':
  //       setActiveTab('tropicbird');
  //       break;
  //     case 'tropicbird':
  //       setActiveTab('tropicbird-1');
  //       break;
  //     default:
  //       console.log('No next tab action for:', activeTab);
  //       break;
  //   }

  //   console.log('New activeTab after Next:', activeTab);
  // };

  // const handleNavItemClick = (tab) => {
  //   console.log('NavItem clicked:', tab);
  //   setActiveTab(tab);
  // };


  const [hasError, setHasError] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    countryCode: '+91',
    mobileNumber: '',
    pincode: '',
    incomeSource: '',
    buss_age: '',
    buss_turnover: '',
    email: '',
  });

  const [formErrors, setFormErrors] = useState({
    name: '',
    mobileNumber: '',
    pincode: '',
    incomeSource: '',
    buss_age: '',
    buss_turnover: '',
    email: '',
  });

  const inputName = useRef(null);
  const inputNumber = useRef(null);
  const inputEmail = useRef(null);
  //const countryCodes = ["+91", "+1", "+44", "+61", "+81", "+86"];
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [modalIsOpen2, setModalIsOpen2] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openModal1 = () => {
    setModalIsOpen1(true);
  };

  const openModal2 = () => {
    setModalIsOpen2(true);
    setModalIsOpen1(false);
  };

  const closeModal1 = () => {
    setModalIsOpen1(false);
  };

  const closeModal2 = () => {
    setModalIsOpen2(false);
  };

  const [selectedImage, setSelectedImage] = useState({
    src: require('../../images/home-webp/Never-miss-a beat.webp'),
    alt: 'Send Promotional & Transactional Messages with WhatsApp Marketing by Dotpe',
    title:
      'Send Promotional & Transactional Messages with WhatsApp Marketing by Dotpe',
  });

  const handleCardClick = (imageSrc, title, alt) => {
    setSelectedImage({
      src: imageSrc,
      title: title,
      alt: alt,
    });
  };

  const [emailSuggestions, setEmailSuggestions] = useState([]);

  const domains = ['gmail.com', 'yahoo.com', 'hotmail.com'];

  const validateSecondForm = () => {
    const errors = {};
    let formIsValid = true;
    const nameRegex = /^[A-Za-z\s]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const numberRegex = /^\d+$/;

    if (!formData.name || !formData.name.trim()) {
      formIsValid = false;
      errors.name = 'Name is required';
    } else if (formData.name.trim().length < 3) {
      formIsValid = false;
      errors.name = 'Name should have at least 3 characters';
    } else if (formData.name.trim().length > 30) {
      formIsValid = false;
      errors.name = 'Name should have at max 30 characters';
    } else if (!nameRegex.test(formData.name.trim())) {
      formIsValid = false;
      errors.name = 'Name should contain only letters and spaces';
    }

    if (!formData.email || !formData.email.trim()) {
      formIsValid = false;
      errors.email = 'Email is required';
    } else if (!emailRegex.test(formData.email)) {
      formIsValid = false;
      errors.email = 'Invalid email address';
    }

    if (!formData.mobileNumber || !formData.mobileNumber.trim()) {
      formIsValid = false;
      errors.mobileNumber = 'Mobile Number is required';
    } else if (!numberRegex.test(formData.mobileNumber)) {
      formIsValid = false;
      errors.mobileNumber = 'Invalid mobile number';
    } else if (formData.mobileNumber.trim().length !== 10) {
      formIsValid = false;
      errors.mobileNumber = 'Mobile number must be exactly 10 digits';
    } else {
      // Ensure mobile number starts with 6, 7, 8, or 9
      const firstDigit = formData.mobileNumber.trim().charAt(0);
      if (!['6', '7', '8', '9'].includes(firstDigit)) {
        formIsValid = false;
        errors.mobileNumber = 'Mobile number must start with 6, 7, 8, or 9';
      }
    }

    setFormErrors(errors);
    setHasError(!formIsValid);

    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    if (name === 'name') {
      if (value === '' || /^[^\s]/.test(value)) {
        newValue = value;
      } else {
        newValue = value.trimStart();
      }
    } else if (name === 'mobileNumber') {
      console.log(newValue.length);
      newValue = value.replace(/\D/g, '');

      if (newValue.length > 10) {
        newValue = newValue.slice(0, 10);
      }
    } else if (name === 'email') {
      if (value.includes('@')) {
        const [localPart, domainPart] = value.split('@');
        setEmailSuggestions(
          domains
            .filter((domain) => domain.startsWith(domainPart))
            .map((domain) => `${localPart}@${domain}`)
        );
      } else {
        setEmailSuggestions([]);
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateSecondForm();
  };

  const formClassName = hasError ? 'form-error form-group' : 'form-group';

  const validateAndOpenModal2 = async () => {
    if (await validateSecondForm()) {
      const apiUrl = process.env.REACT_APP_FORM_ONE;
      const combinedData = {
        name: formData.name,
        phone: formData.mobileNumber,
        email: formData.email,
        source: window.location.href,
      };
      localStorage.setItem('mobile', formData.mobileNumber);
      console.log(combinedData);

      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json; charset=utf-8',
          },
          body: JSON.stringify(combinedData),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data);
        setFirstFormId(data.id);
        openModal2();
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  // const saveDetails = () => {
  //   closeModal2();
  // };
  const navigate = useNavigate();

  const validateForm = () => {
    const errors = {};
    let formIsValid = true;

    if (!formData.incomeSource || formData.incomeSource === '0') {
      formIsValid = false;
      errors.incomeSource = 'Please select a business category';
    }

    if (!formData.role || formData.role === '0') {
      formIsValid = false;
      errors.role = 'Please select your role';
    }

    setFormErrors(errors);
    return formIsValid;
  };

  const saveDetails = async () => {
    const isValid = validateForm();

    if (isValid) {
      const apiUrl = process.env.REACT_APP_FORM_ONE + '/' + firstFormId;
      const combinedData = {
        businessCategory: formData.incomeSource,
        role: formData.role,
      };
      console.log(combinedData);

      try {
        const response = await fetch(apiUrl, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(combinedData),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data);
        resetForms();
        closeModal2();
        navigate('/thankyou');
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const [footerMobile, setFooterMobile] = useState('');
  const footerMobileRef = useRef(null);
  const [hasFooterMobileError, setHasFooterMobileError] = useState(false);
  const [hasFooterMobileErrorText, setHasFooterMobileErrorText] = useState('');
  const handleChangeFooterForm = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    if (name === "mobileNumberfooter") {
      console.log(newValue.length);
      newValue = value.replace(/\D/g, "");

      if (newValue.length > 10) {
        newValue = newValue.slice(0, 10);
      }
    }
    setFooterMobile(newValue);
  };
  const validateFooterMobileForm = () => {
    let formIsValid = true;
    const numberRegex = /^\d+$/;
    if (!footerMobile || !footerMobile.trim()) {
      formIsValid = false;
      setHasFooterMobileErrorText('Mobile Number is required');
    } else if (!numberRegex.test(footerMobile)) {
      formIsValid = false;
      setHasFooterMobileErrorText('Invalid mobile number');
    } else if (footerMobile.trim().length !== 10) {
      formIsValid = false;
      setHasFooterMobileErrorText('Mobile number must be exactly 10 digits');
    } else {
      const firstDigit = footerMobile.trim().charAt(0);
      if (!['6', '7', '8', '9'].includes(firstDigit)) {
        formIsValid = false;
        setHasFooterMobileErrorText('Mobile number must start with 6, 7, 8, or 9');
      }
    }
    setHasFooterMobileError(!formIsValid);

    return formIsValid;
  };
  // const saveDetails = async () => {
  //   closeModal2();
  //   navigate("/thankyou");
  //   return;
  // };

  // const [selectedImage, setSelectedImage] = useState(
  //   require("../../images/home-webp/Never-miss-a beat.webp")
  // );

  // const handleCardClick = (imageSrc) => {
  //   setSelectedImage(imageSrc); // No need to use require here
  // };
  return (
    <div>
      <Helmet>
        <title>
          Rista Restaurant POS Software: Simplified Restaurant Billing &
          Management Software
        </title>
        <meta
          name="description"
          content="Restaurant POS Software for Small Restaurants, Cloud-Kitchen, QSRs, Large Chains, Bakery, Bar & more. Cloud-based Restaurant Billing Software, Trusted by 100k+ merchants across India."
        />
        <meta
          name="keywords"
          content="restaurant pos, restaurant software, food billing software, restaurant management system, restaurant billing software, restaurant billing, online menu ordering system, pos software for restaurant, restaurant inventory control software, restaurant payment system"
        />
        <meta name="author" content="Rista Apps" />
        <meta
          property="og:description"
          content="Restaurant POS Software for Small Restaurants, Cloud-Kitchen, QSRs, Large Chains, Bakery, Bar & more. Cloud-based Restaurant Billing Software, Trusted by 100k+ merchants across India."
        />
        <meta
          property="og:image"
          content={require("../../images/logo-w.png")}
        />
      </Helmet>

      <Header />
      <section className="banner banner_home banner_home_new">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
              <div className="banner-content">
                <h1 className="banner-heading">
                  <span className="banner-subheading home_banner_subheading">
                    All-in-one Restaurant Software
                  </span>
                  Next-level restaurant
                  <span>management</span>
                </h1>
                <div className="banner-content hm_banner_desk">
                  <h2 className="banner-para">
                    Seamlessly connect front and back-of-house operations, make
                    supply-chain efficient, optimize costs, and grow your
                    business with native direct-ordering and marketing. Rista
                    does it all, and makes your guest experience exceptional.
                  </h2>
                  <div className="ban-bt">
                    <NavLink className="hm_fillbtn" onClick={openModal1}>
                      Get a Demo
                    </NavLink>
                  </div>
                  <div className="banner-mobile">
                    <div className="ban-last">
                      <img
                        className="ban-last-img"
                        src={require("../../images/star.png")}
                      ></img>{" "}
                      Top 20 Technology Startups <span>LinkedIn, 2023</span>
                    </div>
                    <div className="ban-last ban-last-1">
                      <img
                        className="ban-last-img"
                        src={require("../../images/star.png")}
                      ></img>{" "}
                      India’s Best POS <span>ET Hospitality World, 2022</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
              <div className="banner-image hmbanner_img">
                <span>
                  {" "}
                  <img
                    className="ban-last-img hm_mainbandesktop"
                    src={require("../../images/home-webp/hm_banner1.webp")}
                    alt=" Rista - Restaurant POS Software"
                    title="Rista - Restaurant POS Software"
                  ></img>
                  <img
                    className="ban-last-img hm_mainbanmobile"
                    src={require("../../images/home-webp/hm_banner1_mob.webp")}
                    alt=" Rista - Restaurant POS Software"
                    title="Rista - Restaurant POS Software"
                  ></img>
                </span>
                <span className="hmbanner_img2">
                  {" "}
                  <img
                    className="ban-last-img"
                    src={require("../../images/home-webp/hm_banner1-1.webp")}
                    alt="rista restaurant billing software dashboard screen"
                    title="rista restaurant billing software dashboard screen"
                  ></img>
                </span>
              </div>

              <div className="banner-content banner_contt_mob">
                <p className="banner-para">
                  Seamlessly connect front and back-of-house operations, makes
                  supply-chain efficient, optimize costs, and grow your business
                  with native direct-ordering and marketing. Rista does it all,
                  and makes your guest experience exceptional.
                </p>
                <div className="ban-bt">
                  {/* <a href="#">Request a Demo</a> */}
                  <NavLink to="/" className="hm_fillbtn" onClick={openModal1}>
                    Request a Demo
                  </NavLink>
                </div>
                <div className="banner-mobile">
                  <div className="ban-last">
                    <img
                      className="ban-last-img"
                      src={require("../../images/star.png")}
                    ></img>{" "}
                    Top 20 Technology Startups <span>LinkedIn, 2023</span>
                  </div>
                  <div className="ban-last ban-last-1">
                    <img
                      className="ban-last-img"
                      src={require("../../images/star.png")}
                    ></img>{" "}
                    India’s Best POS <span>ET Hospitality World, 2022</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-2">
        <div className="container">
          <p className="para">
            Thousands <small>of restaurants</small> run and grow{" "}
            <img src={require("../../images/till-arrow.png")}></img>{" "}
            <small>on</small> Rista,
            <small> every day.</small>
          </p>

          <BrandLogo />
        </div>
      </section>

      <section className="sec3new sec_space">
        <div className="container">
          <div className="sec3new_inner">
            <div className="hm_heading">
              <h1>
                 Elevate every dining experience
                <span>
                  Happy guests, <br />
                  Thriving business.
                </span>
              </h1>
              <h2>
                {" "}
                From cozy cafes to bustling bars, Rista empowers restaurants of
                all sizes to create exceptional dining experience. By
                streamlining back-of-house operations, Rista frees staff to
                focus on what matters most - your guests. Your diners enjoy
                faster service, accurate orders, and a more attentive staff,
                leaving them happy and eager to return.
              </h2>
            </div>
            <div className="sec3_n_btns">
              <NavLink to="/" className="hm_fillbtn" onClick={openModal1}>
                Get a Demo
              </NavLink>
              {/* <NavLink to="/" className="hm_linkbtn">
                Read Success Stories
              </NavLink> */}
            </div>
          </div>
        </div>
      </section>

      <section className="sec4new sec_space">
        <div className="container">
          <div className="sec4new_inner">
            <div className="hm_heading hm_heading_big">
              <h3 className="new_style">
                WHY RISTA?
                <span>
                  Streamline, optimize, and <br /> grow with Rista.
                </span>
              </h3>
            </div>
            <div className="sec4newa_grid">
              <div className="row">
                <div className="col-md-4">
                  <div className="hm_whycards">
                    <div className="hm_whycard_icon">
                      <span>
                        <img src={whyicon1} alt="Icon" />
                      </span>
                    </div>
                    <div className="hm_why_infos">
                      <h4>Faster service</h4>
                      <p>
                        Reduce wait times and streamline operations with
                        lightning-fast order processing and digital ordering,
                        kitchen communication, and secure payment options. Keep
                        your customers happy and your staff efficient.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="hm_whycards">
                    <div className="hm_whycard_icon hm_whycard_icon2">
                      <span>
                        <img src={whyicon2} alt="Icon" />
                      </span>
                    </div>
                    <div className="hm_why_infos">
                      <h4>Eliminate stockouts </h4>
                      <p>
                        Gain complete inventory control with automated
                        reordering based on real-time usage. Ensure menu
                        availability and maximize customer satisfaction.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="hm_whycards">
                    <div className="hm_whycard_icon">
                      <span>
                        {" "}
                        <img src={whyicon3} alt="Icon" />
                      </span>
                    </div>
                    <div className="hm_why_infos">
                      <h4>Minimize food waste</h4>
                      <p>
                        Optimize recipe management and enhance profitability
                        with precise ingredient tracking. Rely on data-driven
                        insights to optimize purchasing based on actual
                        consumption.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="hm_whycards">
                    <div className="hm_whycard_icon">
                      <span>
                        {" "}
                        <img src={whyicon4} alt="Icon" />
                      </span>
                    </div>
                    <div className="hm_why_infos">
                      <h4>Drive revenue growth</h4>
                      <p>
                        Increase your average order value by 20%* with Rista's
                        upselling recommendations and targeted promotions with
                        native WhatsApp Marketing. Unlock improved top and
                        bottom line performance.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="hm_whycards">
                    <div className="hm_whycard_icon">
                      <span>
                        {" "}
                        <img src={whyicon5} alt="Icon" />
                      </span>
                    </div>
                    <div className="hm_why_infos">
                      <h4>Strengthen customer loyalty</h4>
                      <p>
                        Develop deeper customer relationships by personalising
                        your offerings. With advanced analytics, understand your
                        customers' preferences and buying habits, and cultivate
                        lasting loyalty.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="hm_whycards">
                    <div className="hm_whycard_icon">
                      <span>
                        {" "}
                        <img src={whyicon6} alt="Icon" />
                      </span>
                    </div>
                    <div className="hm_why_infos">
                      <h4>Effortless integrations</h4>
                      <p>
                        Achieve unparalled efficiency through seamless
                        integrations with industry-leading marketing, accounting
                        and payment tools. Experience a truly unified platform.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="hm_whycards">
                    <div className="hm_whycard_icon">
                      <span>
                        {" "}
                        <img src={whyicon7} alt="Icon" />
                      </span>
                    </div>
                    <div className="hm_why_infos">
                      <h4>Quickest setup and training</h4>
                      <p>
                        Get up and running quickly with our intuitive system.
                        Minimize training time for your staff and maximize guest
                        time, from day one.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="hm_whycards">
                    <div className="hm_whycard_icon">
                      <span>
                        {" "}
                        <img src={whyicon8} alt="Icon" />
                      </span>
                    </div>
                    <div className="hm_why_infos">
                      <h4>Unparalleled 24x7 support</h4>
                      <p>
                        Receive exceptional support anytime. Our team of experts
                        is passionate about your success, and help you get the
                        most out of Rista.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="hm_whycards">
                    <div className="hm_whycard_icon">
                      <span>
                        {" "}
                        <img src={whyicon9} alt="Icon" />
                      </span>
                    </div>
                    <div className="hm_why_infos">
                      <h4>Enterprise-grade security</h4>
                      <p>
                        Rest assured, your data is safe. Rista utilizes
                        industry-leading security protocols and data encryption
                        to protect your sensitive information.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="hm_whycards">
                    <div className="hm_whycard_icon">
                      <span>
                        {" "}
                        <img src={whyicon10} alt="Icon" />
                      </span>
                    </div>
                    <div className="hm_why_infos">
                      <h4>Scalability, built-in</h4>
                      <p>
                        Rista's robust architecture effortlessly grows with your
                        business. Manage your operations with confidence - from
                        a single location to a nationwide chain.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="hm_whycards">
                    <div className="hm_whycard_icon">
                      <span>
                        {" "}
                        <img src={whyicon11} alt="Icon" />
                      </span>
                    </div>
                    <div className="hm_why_infos">
                      <h4>Continuous innovation</h4>
                      <p>
                        Rista is constantly evolving. Expect exciting new
                        features and updates rolled out frequently, ensuring you
                        always have access to the latest.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="hm_whycards">
                    <div className="hm_whycard_icon">
                      <span>
                        {" "}
                        <img src={whyicon12} alt="Icon" />
                      </span>
                    </div>
                    <div className="hm_why_infos">
                      <h4>Accessible​ on-the-go</h4>
                      <p>
                        Manage your restaurant from anywhere, anytime. Access
                        Rista from any device, keeping you in control and
                        connected at all times.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="point_accordians fasttrack_accordians">
              <div
                className="accordion accordion_rista accordion_rista2"
                id="faqaccord4"
              >
                <div className="card">
                  <div className="card-header" id="faqhead1">
                    {" "}
                    <a
                      href="#"
                      className="btn btn-header-link"
                      data-toggle="collapse"
                      data-target="#faq1"
                      aria-expanded="true"
                      aria-controls="faq1"
                    >
                      {" "}
                      <span className="fast_img_shape">
                        <img src={whyicon1mob} alt="Icon" />
                      </span>{" "}
                      Faster Service{" "}
                    </a>{" "}
                  </div>
                  <div
                    id="faq1"
                    className="collapse show"
                    aria-labelledby="faqhead1"
                    data-parent="#faqaccord4"
                  >
                    <div className="card-body">
                      Reduce wait times and streamline operations with
                      lightning-fast order processing and digital ordering,
                      kitchen communication, and secure payment options. Keep
                      your customers happy and your staff efficient.{" "}
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqhead2">
                    {" "}
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq2"
                      aria-expanded="true"
                      aria-controls="faq2"
                    >
                      {" "}
                      <span className="fast_img_shape">
                        <img src={whyicon2mob} alt="Icon" />
                      </span>{" "}
                      Eliminate Stockouts{" "}
                    </a>{" "}
                  </div>
                  <div
                    id="faq2"
                    className="collapse"
                    aria-labelledby="faqhead2"
                    data-parent="#faqaccord4"
                  >
                    <div className="card-body">
                      Gain complete inventory control with automated reordering
                      based on real-time usage. Ensure menu availability and
                      maximize customer satisfaction.{" "}
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqhead3">
                    {" "}
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq3"
                      aria-expanded="true"
                      aria-controls="faq3"
                    >
                      {" "}
                      <span className="fast_img_shape">
                        <img src={whyicon3mob} alt="Icon" />
                      </span>{" "}
                      Minimize food waste{" "}
                    </a>{" "}
                  </div>
                  <div
                    id="faq3"
                    className="collapse"
                    aria-labelledby="faqhead3"
                    data-parent="#faqaccord4"
                  >
                    <div className="card-body">
                      Optimise recipe management and enhance profitability with
                      precise ingredient tracking. Rely on data-driven insights
                      to optimize purchasing based on actual consumption.
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="faqhead4">
                    {" "}
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq4"
                      aria-expanded="true"
                      aria-controls="faq4"
                    >
                      {" "}
                      <span className="fast_img_shape">
                        <img src={whyicon4mob} alt="Icon" />
                      </span>{" "}
                      Drive Revenue Growth{" "}
                    </a>{" "}
                  </div>
                  <div
                    id="faq4"
                    className="collapse"
                    aria-labelledby="faqhead4"
                    data-parent="#faqaccord4"
                  >
                    <div className="card-body">
                      Increase your average order value by 20%* with Rista's
                      upselling recommendations and targeted promotions with
                      native WhatsApp Marketing. Unlock improved top and bottom
                      line performance.
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="faqhead5">
                    {" "}
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq5"
                      aria-expanded="true"
                      aria-controls="faq5"
                    >
                      {" "}
                      <span className="fast_img_shape">
                        <img src={whyicon5mob} alt="Icon" />
                      </span>{" "}
                      Strengthen Customer Loyalty{" "}
                    </a>{" "}
                  </div>
                  <div
                    id="faq5"
                    className="collapse"
                    aria-labelledby="faqhead5"
                    data-parent="#faqaccord4"
                  >
                    <div className="card-body">
                      Develop deeper customer relationships by personalising
                      your offerings. With advanced analytics, understand your
                      customers' preferences and buying habits, and cultivate
                      lasting loyalty.
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="faqhead6">
                    {" "}
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq6"
                      aria-expanded="true"
                      aria-controls="faq6"
                    >
                      {" "}
                      <span className="fast_img_shape">
                        <img src={whyicon6mob} alt="Icon" />
                      </span>{" "}
                      Effortless Integrations{" "}
                    </a>{" "}
                  </div>
                  <div
                    id="faq6"
                    className="collapse"
                    aria-labelledby="faqhead6"
                    data-parent="#faqaccord4"
                  >
                    <div className="card-body">
                      Achieve unparalled efficiency through seamless
                      integrations with industry-leading marketing, accounting
                      and payment tools. Experience a truly unified platform.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec5new sec_space hm_hidesec">
        <div className="sec5new_top">
          <div className="container">
            <div className="sec5new_inner">
              <div className="sec5newa_grid">
                <div className="row">
                  <div className="col-md-4">
                    <div className="hm_srvccards">
                      <div className="hm_srvccard_icon">
                        <span>
                          <img src={hm_srvc_icon2} alt="Icon" />
                        </span>
                      </div>
                      <div className="hm_srvc_infos">
                        <h4>
                          Quickest Setup <br />
                          and Training
                        </h4>
                        <p>
                          Get up and running quickly with our intuitive system.
                          Minimize training time for your staff and maximize
                          guest time, from day one.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="hm_srvccards">
                      <div className="hm_srvccard_icon">
                        <span>
                          <img src={hm_srvc_icon1} alt="Icon" />
                        </span>
                      </div>
                      <div className="hm_srvc_infos">
                        <h4>
                          Unparalled <br />
                          24x7 Support
                        </h4>
                        <p>
                          Receive exceptional support anytime. Our team of
                          experts is passionate about your success, and help you
                          get the most out of Rista.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="hm_srvccards">
                      <div className="hm_srvccard_icon">
                        <span>
                          <img src={hm_srvc_icon4} alt="Icon" />
                        </span>
                      </div>
                      <div className="hm_srvc_infos">
                        <h4>
                          Enterprise-Grade <br />
                          Security
                        </h4>
                        <p>
                          Rest assured, your data is safe. Rista utilizes
                          industry-leading security protocols and data
                          encryption to protect your sensitive information.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="hm_srvccards">
                      <div className="hm_srvccard_icon">
                        <span>
                          <img src={hm_srvc_icon3} alt="Icon" />
                        </span>
                      </div>
                      <div className="hm_srvc_infos">
                        <h4>
                          Scalability,
                          <br /> built-in
                        </h4>
                        <p>
                          Rista's robust architecture effortlessly grows with
                          your business. Manage your operations with confidence
                          - from a single location to a nationwide chain.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="hm_srvccards">
                      <div className="hm_srvccard_icon">
                        <span>
                          <img src={hm_srvc_icon3} alt="Icon" />
                        </span>
                      </div>
                      <div className="hm_srvc_infos">
                        <h4>
                          Continuous
                          <br /> Innovation
                        </h4>
                        <p>
                          Rista is constantly evolving. Expect exciting new
                          features and updates rolled out frequently, ensuring
                          you always access to the latest.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="hm_srvccards">
                      <div className="hm_srvccard_icon">
                        <span>
                          <img src={hm_srvc_icon3} alt="Icon" />
                        </span>
                      </div>
                      <div className="hm_srvc_infos">
                        <h4>
                          Accessible
                          <br /> On-the-Go
                        </h4>
                        <p>
                          Manage your restaurant from anywhere, anytime. Access
                          Rista from any device, keeping you in control and
                          connected at all times.
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-md-2">
                    <div className="hm_srvccards">
                      <div className="hm_srvccard_icon">
                        <span>
                          <img src={hm_srvc_icon5} alt="Icon" />
                        </span>
                      </div>
                      <div className="hm_srvc_infos">
                        <h4>
                          99.99% Uptime <br />
                          Guaranteed
                        </h4>
                        <p>
                          Manage operations smoothly & keep them up & running,
                          always.
                        </p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sec5new_bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="sec5ins">
                  {/* Our Customers say <b>Excellent</b> */}
                  <span className="hm_staricon">
                    <img src={cestar} alt="Icon" />
                  </span>
                  <small>
                    <b>Top 20 Technology Startups </b>
                  </small>
                  <small className="et_customr">LinkedIn, 2023</small>
                  <span className="hm_starline">|</span>
                  <span className="hm_staricon">
                    <img src={cestar} alt="Icon" />
                  </span>
                  <small>
                    <b>India’s Best POS</b>
                  </small>{" "}
                  <small className="et_customr">
                    ET Hospitality World, 2022
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sec-3 sec3_home sec3_home_tabs">
        <div className="container">
          <h3 className="heading">Our Product Suite</h3>
          <h2 className="subheading">
            Fuelling success for every restaurant,
            <span>all types, every size.</span>
          </h2>
        </div>
        <div className="hm_tabsouter">
          <div className="hmtabs_mainbox hmtabs_mainbox_redesign">
            <div className="rista_tabs">
              <div className="container">
                <ul className="nav nav-pills">
                  <li className="nav-item">
                    <a
                      // className={`nav-link ${activeTab === "flamingo" && "active"
                      //   }`}
                      className={`nav-link ${
                        activeTab === "flamingo" && "active"
                      }`}
                      onClick={() => handleNavItemClick("flamingo")}
                      data-toggle="pill"
                      href="#flamingo"
                      role="tab"
                      aria-controls="pills-flamingo"
                      aria-selected={activeTab === "flamingo"}
                    >
                      Point of Sale System
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      // className={`nav-link ${activeTab === "cuckoo" && "active"}`}
                      className={`nav-link ${
                        activeTab === "cuckoo" && "active"
                      }`}
                      onClick={() => handleNavItemClick("cuckoo")}
                      data-toggle="pill"
                      href="#cuckoo"
                      role="tab"
                      aria-controls="pills-cuckoo"
                      aria-selected={activeTab === "cuckoo"}

                      // className="nav-link"
                      // data-toggle="pill"
                      // href="#cuckoo"
                      // role="tab"
                      // aria-controls="pills-cuckoo"
                      // aria-selected="false"
                    >
                      Inventory Management
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      // className={`nav-link ${activeTab === "ostrich" && "active"
                      //   }`}
                      className={`nav-link ${
                        activeTab === "ostrich" && "active"
                      }`}
                      onClick={() => handleNavItemClick("ostrich")}
                      data-toggle="pill"
                      href="#ostrich"
                      role="tab"
                      aria-controls="pills-ostrich"
                      aria-selected={activeTab === "ostrich"}
                      // className="nav-link"
                      // data-toggle="pill"
                      // href="#ostrich"
                      // role="tab"
                      // aria-controls="pills-ostrich"
                      // aria-selected="false"
                    >
                      Direct Ordering{" "}
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a
                     
                      className={`nav-link ${
                        activeTab === "tropicbird" && "active"
                      }`}
                      onClick={() => handleNavItemClick("tropicbird")}
                      data-toggle="pill"
                      href="#tropicbird"
                      role="tab"
                      aria-controls="pills-tropicbird"
                      aria-selected={activeTab === "tropicbird"}
                    >
                      Superior Analytics
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        activeTab === "tropicbird-1" && "active"
                      }`}
                      onClick={() => handleNavItemClick("tropicbird-1")}
                      data-toggle="pill"
                      href="#tropicbird-1"
                      role="tab"
                      aria-controls="pills-tropicbird-1"
                      aria-selected={activeTab === "tropicbird-1"}
                    >
                      Marketing
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="container">
              <div className="tab_blockinfo_main">
                <div className="tabs_infos_box">
                  <div className="tab-content mt-3">
                    <div
                      className={`tab-pane fade ${
                        activeTab === "flamingo" && "show active"
                      }`}
                      id="flamingo"
                      role="tabpanel"
                      aria-labelledby="flamingo-tab"
                    >
                      <div className="point_block_main">
                        <div className="point_row_grid">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="point_box">
                                <h5>POINT OF SALE </h5>
                                <h3>Efficiency beyond Billing</h3>
                                <p>
                                  Go beyond traditional billing software, with
                                  Rista's holistic solution that streamlines
                                  every aspect of your restaurant operation. -
                                
                                  Experience lightning-fast billing, an
                                  intuitive interface, and seamless integration
                                  with online ordering platforms. Actionable
                                  insights from comprehensive reporting help you
                                  make data-driven decisions for menu
                                  optimization, inventory control, and growth.
                                </p>
                                <div className="points_tick">
                                  <ul>
                                    <li>Lightning-fast billing</li>
                                    <li>Actionable Insights</li>
                                    <li>Seamless integrations</li>
                                  </ul>
                                </div>
                                <div className="point_btns">
                                  <NavLink
                                    to="/restaurant-point-of-sale-software"
                                    onClick={scrollToTop}
                                  >
                                    Learn More
                                  </NavLink>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="point_img">
                                <span>
                                  {" "}
                                  <img
                                    className="deskfuel"
                                    src={require("../../images/home-webp/Point-of-sale.webp")}
                                    alt="New dine-in food order with table no punched on Rista Restaurant Billing Software"
                                    title="New dine-in food order with table no punched on Rista Restaurant Billing Software"
                                  ></img>
                                  <img
                                    className="mobfuel"
                                    src={require("../../images/home-webp/Point-of-sale.webp")}
                                    alt="New dine-in food order with table no punched on Rista Restaurant Billing Software"
                                    title="New dine-in food order with table no punched on Rista Restaurant Billing Software"
                                  ></img>
                                </span>
                              </div>

                              <div className="point_btns point_btns_mob">
                                <NavLink
                                  to="/restaurant-point-of-sale-software"
                                  onClick={scrollToTop}
                                >
                                  Learn More{" "}
                                  <img src={hmleararrow} alt="Icon" />
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`tab-pane fade ${
                        activeTab === "cuckoo" && "show active"
                      }`}
                      id="cuckoo"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      <div className="point_block_main">
                        <div className="point_row_grid">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="point_box">
                                <h5>INVENTORY MANAGEMENT</h5>
                                <h3>Smart Inventory that Saves You Money</h3>
                                <p>
                                  Get peace of mind knowing your kitchen always
                                  has exactly what it needs. Rista's intelligent
                                  inventory management system takes the
                                  guesswork out of stocking. By accurately
                                  predicting your needs, it ensures you have the
                                  right amount of everything on hand, exactly
                                  when you need it. Minimize food waste,
                                  eliminate out-of-stocks that disrupt service
                                  and empower your team to focus on what matters
                                  most – creating delicious dishes for your
                                  guests.
                                </p>
                                <div className="points_tick">
                                  <ul>
                                    <li>Predictive Inventory</li>
                                    <li>Minimized Waste</li>
                                    <li>Automated Replenishment</li>
                                  </ul>
                                </div>
                                <div className="point_btns">
                                  <NavLink
                                    to="/restaurant-inventory-management"
                                    onClick={scrollToTop}
                                  >
                                    Learn More
                                  </NavLink>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="point_img">
                                <span>
                                  {" "}
                                  <img
                                    className="deskfuel"
                                    src={require("../../images/home-webp/Inventory-Management.webp")}
                                    alt="Items low stock alert view on Rista food billing software"
                                    title="Items low stock alert view on Rista food billing software"
                                  ></img>
                                  <img
                                    className="mobfuel"
                                    src={require("../../images/home-webp/Inventory-Management.webp")}
                                    alt="Items low stock alert view on Rista food billing software"
                                    title="Items low stock alert view on Rista food billing software"
                                  ></img>
                                </span>
                              </div>

                              <div className="point_btns point_btns_mob">
                                <NavLink
                                  to="/restaurant-inventory-management"
                                  onClick={scrollToTop}
                                >
                                  Learn More{" "}
                                  <img src={hmleararrow} alt="Icon" />
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`tab-pane fade ${
                        activeTab === "ostrich" && "show active"
                      }`}
                      id="ostrich"
                      role="tabpanel"
                      aria-labelledby="ostrich-tab"
                    >
                      <div className="point_block_main">
                        <div className="point_row_grid">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="point_box">
                                <h5>DIRECT ORDERING</h5>
                                <h3>
                                  Contactless Ordering & Zero-Commission
                                  Delivery
                                </h3>
                                <p>
                                  Swiftly digitize your dine-in, delivery or
                                  take-out orders. Use secure QR code solutions
                                  for a contactless and efficient ordering, and
                                  payment experience. Receive and deliver orders
                                  at zero commission, with best-in-class native
                                  delivery integrations. Empower your customers
                                  with increased control over their experience,
                                  and get up to <b>27% higher AOV</b> via
                                  digital ordering.
                                </p>
                                <div className="points_tick">
                                  <ul>
                                    <li>Safe and contactless ordering</li>
                                    <li>Zero-commission delivery</li>
                                    <li>Up to 27% higher AOV</li>
                                  </ul>
                                </div>
                                <div className="point_btns">
                                  <NavLink
                                    to="/restaurant-direct-ordering-system"
                                    onClick={scrollToTop}
                                  >
                                    Learn More
                                  </NavLink>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="point_img">
                                <span>
                                  {" "}
                                  <img
                                    className="deskfuel"
                                    src={require("../../images/home-webp/Direct-Ordering.webp")}
                                    alt="QR code ordering system for dine-in restaurants in Rista pos software for  restaurant"
                                    title="QR code ordering system for dine-in restaurants in Rista pos software for  restaurant"
                                  ></img>
                                  <img
                                    className="mobfuel"
                                    src={require("../../images/home-webp/fueling_img_mob3.webp")}
                                    alt="QR code ordering system for dine-in restaurants in Rista pos software for  restaurant"
                                    title="QR code ordering system for dine-in restaurants in Rista pos software for  restaurant"
                                  ></img>
                                </span>
                              </div>

                              <div className="point_btns point_btns_mob">
                                <NavLink
                                  onClick={scrollToTop}
                                  to="/restaurant-direct-ordering-system"
                                >
                                  Learn More{" "}
                                  <img src={hmleararrow} alt="Icon" />
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div
                      className={`tab-pane fade ${
                        activeTab === "tropicbird" && "show active"
                      }`}
                      id="tropicbird"
                      role="tabpanel"
                      aria-labelledby="tropicbird-tab"
                    >
                      <div className="point_block_main">
                        <div className="point_row_grid">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="point_box">
                                <h5>Superior Analytics</h5>
                                <h3>
                                  Data-driven insights for better customer
                                  experiences
                                </h3>
                                <p>
                                  Be in control of your business with actionable
                                  insights that are easier to understand. Our
                                  meticulously created dashboard and reports
                                  give you an eagle-eye view on your business
                                  down to the most-granular detail.
                                </p>
                               
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="point_img">
                                <span>
                                  {" "}
                                  <img
                                    className="deskfuel"
                                    src={require("../../images/home-webp/Superior-analytics.webp")}
                                    alt="Analytics and reports view of Rista restaurant management software"
                                    title="Analytics and reports view of Rista restaurant management software"
                                  ></img>
                                  <img
                                    className="mobfuel"
                                    src={require("../../images/home-webp/fueling_img_mob4.webp")}
                                    alt="Analytics and reports view of Rista restaurant management software"
                                    title="Analytics and reports view of Rista restaurant management software"
                                  ></img>
                                </span>
                              </div>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div
                      className={`tab-pane fade ${
                        activeTab === "tropicbird-1" && "show active"
                      }`}
                      id="tropicbird-1"
                      role="tabpanel"
                      aria-labelledby="tropicbird-1-tab"
                    >
                      <div className="point_block_main">
                        <div className="point_row_grid">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="point_box">
                                <h5>MARKETING</h5>
                                <h3>Build Loyalty, Drive Sales</h3>
                                <p>
                                  With native CRM, get access to actionable
                                  insights using details like activity, favorite
                                  items, feedback, and more. Effortlessly
                                  connect with your customers on their preferred
                                  platform, WhatsApp, to send targeted
                                  promotions and transactional messages that
                                  maximize engagement and drive repeat business.
                                </p>
                                <div className="points_tick">
                                  <ul>
                                    <li>Seamless WABA onboarding</li>
                                    <li>Native CRM</li>
                                    <li>Superior analytics and reporting</li>
                                  </ul>
                                </div>
                                {/* <div className="point_btns">
                                  <NavLink>Learn More</NavLink>
                                </div> */}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="point_img">
                                <span>
                                  {" "}
                                  <img
                                    src={require("../../images/home-webp/Marketing.webp")}
                                    alt="Rista restaurant software offers whatsapp integration allowing restaurant owners to send promotional & transactional whatsapp message to customers"
                                    title="Rista restaurant software offers whatsapp integration allowing restaurant owners to send promotional & transactional whatsapp message to customers"
                                  ></img>
                                </span>
                              </div>
                              {/* <div className="point_btns point_btns_mob">
                                <NavLink>
                                  Learn More{" "}
                                  <img src={hmleararrow} alt="Icon" />
                                </NavLink>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="hmtabs_arrows">
                  <div className="row">
                    <div className="col-auto col-auto_left">
                      <button
                        onClick={handlePrevTab}
                        disabled={activeTab === "flamingo"}
                        className={
                          activeTab === "flamingo"
                            ? "prev-button disabled"
                            : "prev-button"
                        }
                      >
                        <img src={hmleftarrow} alt="Icon" />
                      </button>
                    </div>
                    <div className="col-auto  col-auto_right">
                      <button
                        onClick={handleNextTab}
                        disabled={activeTab === "tropicbird-1"}
                        className={
                          activeTab === "tropicbird-1"
                            ? "next-button disabled"
                            : "next-button"
                        }
                      >
                        <img
                          src={hmrightarrow}
                          alt="Icon"
                          className="hmright_aro"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Navigation arrows */}
      </section>
      <section className="sec6new sec_space">
        <div className="container">
          <div className="sec6new_inner">
            <div className="hm_heading hm_heading_big hm_heading_blue">
              <h5> RESTAURANT TYPES</h5>
              <h3>
                Perfect for any <span>food business.</span>
              </h3>
              <p>
                Rista is designed to cater to the unique needs of all food
                businesses - deliver superior customer experiences and
                streamline your operations.
              </p>
            </div>

            <div class="perfect_grid_desk">
              <div className="sec6_perfect_grid">
                <div className="row row_perfectimg1">
                  <div className="col-md-4">
                    <div className="sec6_heading">Fine Dine & Bars</div>
                  </div>
                  <div className="col-md-4">
                    <div className="sec6_perfect_points">
                      <ul>
                        <li>Seamless reservations</li>
                        <li>Robust waiter application</li>
                        <li>Reduced table turn time by 20%</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="sec6_imgbox">
                      <span>
                        {" "}
                        <img
                          src={require("../../images/home-webp/Fine-Dine.webp")}
                          alt="Delicious Food Served in a dish in fine dine restaurant using Rista restaurant pos software"
                          title="Delicious Food Served in a dish in fine dine restaurant using Rista restaurant pos software"
                        ></img>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="sec6_perfect_grid">
                <div className="row  row_perfectimg2">
                  <div className="col-md-4">
                    <div className="sec6_heading">
                      Quick Service <br />
                      Restaurants
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="sec6_perfect_points">
                      <ul>
                        <li>3X Faster billing*</li>
                        <li>Menu scheduling</li>
                        <li>Automatic stock updates</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="sec6_imgbox">
                      <span>
                        {" "}
                        <img
                          src={require("../../images/home-webp/QSR.webp")}
                          alt="QSR Billing Desk using Rista Restaurant Billing Software"
                          title="QSR Billing Desk using Rista Restaurant Billing Software"
                        ></img>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="sec6_perfect_grid">
                <div className="row  row_perfectimg3">
                  <div className="col-md-4">
                    <div className="sec6_heading">Cloud Kitchens</div>
                  </div>
                  <div className="col-md-4">
                    <div className="sec6_perfect_points">
                      <ul>
                        <li>Multi-brand billing</li>
                        <li>Two-way integration with aggregators</li>
                        <li>Unified inventory for multi-brand items</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="sec6_imgbox">
                      <span>
                        {" "}
                        <img
                          src={require("../../images/home-webp/Cloud-Kitchen.webp")}
                          alt="Food preparation in a cloud kitchen accepting online order with Rista restaurant software"
                          title="Food preparation in a cloud kitchen accepting online order with Rista restaurant software"
                        ></img>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="sec6_perfect_grid">
                <div className="row  row_perfectimg4">
                  <div className="col-md-4">
                    <div className="sec6_heading">
                      Pubs, Bars, Clubs, <br />
                      and Lounges
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="sec6_perfect_points">
                      <ul>
                        <li>Multi-terminal billing </li>
                        <li>Liquor inventory management</li>
                        <li>Split bills based on various criteria</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="sec6_imgbox">
                      <span>
                        {" "}
                        <img
                          src={require("../../images/home-webp/Pubs-bar-and-Club.webp")}
                          alt="Counter of Pub, Bar using Rista restaurant software to efficiently manage peak hours"
                          title="Counter of Pub, Bar using Rista restaurant software to efficiently manage peak hours"
                        ></img>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="perfect_grid_mobile">
              <div className="owl-slider pos-testimonial-slider">
                <OwlCarousel
                  items={4}
                  className="owl-theme"
                  loop
                  nav={false}
                  dots={false}
                  stagePadding={50}
                  margin={25}
                  responsive={{
                    0: { items: 1 }, // Set 1 item for small screens (width < 600px)
                    600: { items: 1 }, // Set 2 items for medium screens (600px <= width < 960px)
                    1025: { items: 1 }, // Set 4 items for larger screens (width >= 960px)
                  }}
                >
                  <div className="item">
                    <div className="sec6_perfect_grid sec6_perfect_grid_blck">
                      <div className="row row_perfectimg1">
                        <div className="col-md-4">
                          <div className="sec6_heading">
                            Fine Dine <br />& Bars
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="sec6_perfect_points">
                            <ul>
                              <li>Seamless reservations</li>
                              <li>Robust waiter application</li>
                              <li>Reduced table turn time by 20%</li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="sec6_imgbox">
                            <span>
                              {" "}
                              <img
                                src={require("../../images/home-webp/Fine-Dine.webp")}
                                alt="Delicious Food Served in a dish in fine dine restaurant using Rista restaurant pos software"
                                title="Delicious Food Served in a dish in fine dine restaurant using Rista restaurant pos software"
                              ></img>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div className="sec6_perfect_grid">
                      <div className="row  row_perfectimg2">
                        <div className="col-md-4">
                          <div className="sec6_heading">
                            Quick Service <br />
                            Restaurants
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="sec6_perfect_points">
                            <ul>
                              <li>3X Faster billing*</li>
                              <li>Menu scheduling</li>
                              <li>Automatic stock updates</li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="sec6_imgbox">
                            <span>
                              {" "}
                              <img
                                src={require("../../images/home-webp/QSR.webp")}
                                alt="QSR Billing Desk using Rista Restaurant Billing Software"
                                title="QSR Billing Desk using Rista Restaurant Billing Software"
                              ></img>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div className="sec6_perfect_grid  sec6_perfect_grid_blck">
                      <div className="row  row_perfectimg3">
                        <div className="col-md-4">
                          <div className="sec6_heading">
                            Cloud <br />
                            Kitchens
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="sec6_perfect_points">
                            <ul>
                              <li>Multi-brand billing</li>
                              <li>Two-way integration with aggregators</li>
                              <li>Unified inventory for multi-brand items</li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="sec6_imgbox">
                            <span>
                              {" "}
                              <img
                                src={require("../../images/home-webp/Cloud-Kitchen.webp")}
                                alt="Food preparation in a cloud kitchen accepting online order with Rista restaurant software"
                                title="Food preparation in a cloud kitchen accepting online order with Rista restaurant software"
                              ></img>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div className="sec6_perfect_grid">
                      <div className="row  row_perfectimg4">
                        <div className="col-md-4">
                          <div className="sec6_heading">
                            Pubs, Bars, Clubs, <br />
                            and Lounges
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="sec6_perfect_points">
                            <ul>
                              <li>Multi-terminal billing </li>
                              <li>Liquor inventory management</li>
                              <li>Split bills based on various criteria</li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="sec6_imgbox">
                            <span>
                              {" "}
                              <img
                                src={require("../../images/home-webp/Pubs-bar-and-Club.webp")}
                                alt="Counter of Pub, Bar using Rista restaurant software to efficiently manage peak hours"
                                title="Counter of Pub, Bar using Rista restaurant software to efficiently manage peak hours"
                              ></img>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-5 hm_ristapos hm_hidesec">
        <div className="container">
          <div className="hm_heading hm_heading_big hm_heading_blue">
            <h5>Rista POS</h5>
            <h3>Discover the impact we bring to restaurants like yours</h3>
            <p>
              Serve up success on a silver platter with our all-in-one platform
              – Rista POS
            </p>
          </div>

          <div className="row mid naver_acorrds">
            <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
              <div className="accordion accordion_rista" id="faqaccord2">
                <div className="card">
                  <div className="card-header" id="faqhead51">
                    <a
                      href="#"
                      title="rista restaurant pos auto-accepting online order based on stock left from different online food ordering platforms"
                      alt="rista restaurant pos auto-accepting online order based on stock left from different online food ordering platforms"
                      className="btn btn-header-link"
                      data-toggle="collapse"
                      data-target="#faq51"
                      aria-expanded="true"
                      aria-controls="faq51"
                      onClick={() =>
                        handleCardClick(
                          require("../../images/home-webp/Never-miss-a beat.webp"),
                          "rista restaurant pos auto-accepting online order based on stock left from different online food ordering platforms",
                          "rista restaurant pos auto-accepting online order based on stock left from different online food ordering platforms"
                        )
                      }
                    >
                      Never miss a beat (or an order)!
                    </a>{" "}
                  </div>

                  <div
                    id="faq51"
                    className="collapse show"
                    aria-labelledby="faqhead51"
                    data-parent="#faqaccord2"
                  >
                    <div className="card-body">
                      Keep tabs on incoming orders from every channel with our
                      seamless monitoring system, ensuring no order slips
                      through the cracks. Our real-time alerts and notifications
                      ensure you're always on your game.
                      <div className="sec-5-img show-1">
                        {/* <img src={require("../../images/sec-5-img.png")}></img> */}
                        {selectedImage && (
                          <div className="selected-image ">
                            <img
                              src={selectedImage.src}
                              alt={selectedImage.alt}
                              title={selectedImage.title}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="faqhead52">
                    <a
                      href="#"
                      alt="Rista Reports Central Hub, Providing comprehensive overview of performance to restaurant owners."
                      title="Rista Reports Central Hub, Providing comprehensive overview of performance to restaurant owners."
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq52"
                      aria-expanded="true"
                      aria-controls="faq52"
                      onClick={() =>
                        handleCardClick(
                          require("../../images/home-webp/360-view.webp"),
                          "Rista Reports Central Hub, Providing comprehensive overview of performance to restaurant owners.",
                          "Rista Reports Central Hub, Providing comprehensive overview of performance to restaurant owners."
                        )
                      }
                    >
                      Get a 360° view
                    </a>
                  </div>

                  <div
                    id="faq52"
                    className="collapse"
                    aria-labelledby="faqhead52"
                    data-parent="#faqaccord2"
                  >
                    <div className="card-body">
                      <strong>
                        Access all business insights from one central hub
                      </strong>
                      , providing you with a comprehensive overview of your
                      restaurant operations. Monitor sales trends, track
                      inventory, and analyze customer preferences,{" "}
                      <strong>all in one place.</strong>
                      <div className="sec-5-img show-1">
                        {/* <img src={require("../../images/sec-5-img.png")}></img> */}
                        {selectedImage && (
                          <div className="selected-image ">
                            <img
                              src={selectedImage.src}
                              alt={selectedImage.alt}
                              title={selectedImage.title}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqhead53">
                    <a
                      href="#"
                      alt=""
                      title=""
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq53"
                      aria-expanded="true"
                      aria-controls="faq53"
                      onClick={() =>
                        handleCardClick(
                          require("../../images/home-webp/Take-full-control.webp"),
                          "Rista Restaurant Software showcasing order journey from order acceptance to processed in the dashboard",
                          "Rista Restaurant Software showcasing order journey from order acceptance to processed in the dashboard"
                        )
                      }
                    >
                      Take full control
                    </a>
                  </div>

                  <div
                    id="faq53"
                    className="collapse"
                    aria-labelledby="faqhead53"
                    data-parent="#faqaccord2"
                  >
                    <div className="card-body">
                      From the moment an order is accepted to its timely
                      dispatch,{" "}
                      <strong>
                        master the entire order lifecycle effortlessly.
                      </strong>{" "}
                      With RISTA POS, you're in complete control, ensuring a
                      smooth and efficient operation at every stage.{" "}
                      <strong>
                        Customize your menu, manage staff, and optimize your
                        workflow with ease.
                      </strong>
                      <div className="sec-5-img show-1">
                        {/* <img src={require("../../images/sec-5-img.png")}></img> */}
                        {selectedImage && (
                          <div className="selected-image ">
                            <img
                              src={selectedImage.src}
                              alt={selectedImage.alt}
                              title={selectedImage.title}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card card_last">
                  <div className="card-header" id="faqhead54">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq54"
                      aria-expanded="true"
                      aria-controls="faq54"
                      onClick={() =>
                        handleCardClick(
                          require("../../images/home-webp/Effortless-Integration.webp"),
                          "Logos of all the companies which can integrate with Rista Restaurant Billing Software",
                          "Logos of all the companies which can integrate with Rista Restaurant Billing Software"
                        )
                      }
                    >
                      Effortless integration
                    </a>
                  </div>

                  <div
                    id="faq54"
                    className="collapse"
                    aria-labelledby="faqhead54"
                    data-parent="#faqaccord2"
                  >
                    <div className="card-body">
                      Seamlessly integrate RISTA POS with online platforms
                      creating a cohesive and efficient ecosystem for your
                      restaurant.{" "}
                      <strong>
                        Simplify your processes, reduce manual errors, and
                        enhance overall productivity.
                      </strong>
                      <div className="sec-5-img show-1">
                        {/* <img src={require("../../images/sec-5-img.png")}></img> */}
                        {selectedImage && (
                          <div className="selected-image ">
                            <img
                              src={selectedImage.src}
                              alt={selectedImage.alt}
                              title={selectedImage.title}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <p className=" tab_lastline tab_lastline_center">
                  <strong>
                    Watch your restaurant not only run but thrive.
                  </strong>

                  <NavLink
                    to="/"
                    className="hm_fillbtn hmdemo_btn hmdemo_btn_next"
                    onClick={openModal1}
                  >
                    Get a Demo
                  </NavLink>
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12 hide-1">
              {selectedImage && (
                <div className="selected-image ">
                  <img
                    src={selectedImage.src}
                    alt={selectedImage.alt}
                    title={selectedImage.title}
                  />{" "}
                </div>
              )}
            </div>
          </div>

          <div className="hm_3boxes">
            <div className="point_block_main">
              <div className="point_row_grid">
                <div>
                  <div className="point_box">
                    <h5>Direct Ordering</h5>
                    <h3>Scan, dine, pay. Anytime, anywhere</h3>
                    <p>
                      Easily generate and manage QR codes for your restaurant
                      for contactless ordering and payments
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-5">
                    <div className="point_acordimg">
                      <span>
                        {" "}
                        <img
                          src={require("../../images/home-webp/increase-AOV.webp")}
                          alt="Rista pos software for restaurant can integrate with Dot Commerce which allows user to order online from their favourite restaurant."
                          title="Rista pos software for restaurant can integrate with Dot Commerce which allows user to order online from their favourite restaurant."
                        ></img>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="point_accordians">
                      <div
                        className="accordion accordion_rista accordion_rista2"
                        id="faqaccord3"
                      >
                        <div className="card">
                          <div className="card-header" id="faqhead1">
                            {" "}
                            <a
                              href="#"
                              className="btn btn-header-link"
                              data-toggle="collapse"
                              data-target="#faq1"
                              aria-expanded="true"
                              aria-controls="faq1"
                            >
                              {" "}
                              Increase your AOV, improve bottom line{" "}
                            </a>{" "}
                          </div>
                          <div
                            id="faq1"
                            className="collapse show"
                            aria-labelledby="faqhead1"
                            data-parent="#faqaccord3"
                          >
                            <div className="card-body">
                              {" "}
                              Excellent ordering platform for customers gives
                              them a superior dining experience with detailed
                              descriptions, suggesting popular items, and our
                              proprietary algorithms for recommendations leading
                              to a significantly higher AOV, and integrated
                              payments. All of this, while you embrace
                              sustainability and cut down on menu costs.
                              {/* <div className="sec-5-img show-1">
                                {" "}
                                <img
                                  src={require("../../images/sec-5-img.png")}
                                ></img>{" "}
                              </div> */}
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="faqhead2">
                            {" "}
                            <a
                              href="#"
                              className="btn btn-header-link collapsed"
                              data-toggle="collapse"
                              data-target="#faq2"
                              aria-expanded="true"
                              aria-controls="faq2"
                            >
                              {" "}
                              Own your customers{" "}
                            </a>{" "}
                          </div>
                          <div
                            id="faq2"
                            className="collapse"
                            aria-labelledby="faqhead2"
                            data-parent="#faqaccord3"
                          >
                            <div className="card-body">
                              {" "}
                              Every transaction is an opportunity to learn more
                              about your guests. Our QR code solution helps you
                              harvest invaluable guest insights with each
                              interaction, empowering you to fuel dynamic
                              marketing strategies and build effective loyalty
                              programs.
                              {/* <div className="sec-5-img show-1">
                                {" "}
                                <img
                                  src={require("../../images/sec-5-img.png")}
                                ></img>{" "}
                              </div> */}
                            </div>
                          </div>
                        </div>
                        <div className="card bx_cardlst">
                          <div className="card-header" id="faqhead3">
                            {" "}
                            <a
                              href="#"
                              className="btn btn-header-link collapsed"
                              data-toggle="collapse"
                              data-target="#faq3"
                              aria-expanded="true"
                              aria-controls="faq3"
                            >
                              {" "}
                              Outsmart staff shortages{" "}
                            </a>{" "}
                          </div>
                          <div
                            id="faq3"
                            className="collapse"
                            aria-labelledby="faqhead3"
                            data-parent="#faqaccord3"
                          >
                            <div className="card-body">
                              {" "}
                              Bid farewell to long lines and streamline your
                              checkout process. With our Direct Ordering
                              solution, you make ordering and payments
                              contactless and eliminate the need for excessive
                              staffing. Reduce waiting times, and make the
                              checkout experience a breeze for everyone
                              involved.
                              {/* <div className="sec-5-img show-1">
                                {" "}
                                <img
                                  src={require("../../images/sec-5-img.png")}
                                ></img>{" "}
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <p className=" tab_lastline">
                        <strong>
                          Efficiency, insights, and savings–all at your
                          fingertips!
                        </strong>

                        <NavLink
                          to="/"
                          className="hm_fillbtn hmdemo_btn hmdemo_btn_next"
                          onClick={openModal1}
                        >
                          Get a Demo
                        </NavLink>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="point_block_main point_block_main2">
              <div className="point_row_grid">
                <div>
                  <div className="point_box">
                    <h5>Marketing and More</h5>
                    <h3>Wider reach. Higher engagement</h3>
                    <p>
                      Your recipe for personalized connections and tailored
                      dining experiences
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-5">
                    <div className="point_acordimg">
                      <span>
                        {" "}
                        <img
                          src={require("../../images/home-webp/Wider-Reach.webp")}
                          alt="Restaurants can send promotional whatsapp messages and exclusive offers to their existing customers to increase their no of orders and revenue"
                          title="Restaurants can send promotional whatsapp messages and exclusive offers to their existing customers to increase their no of orders and revenue"
                        ></img>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="point_accordians">
                      <div
                        className="accordion  accordion_rista accordion_rista2"
                        id="faqaccord14"
                      >
                        <div className="card">
                          <div className="card-header" id="faqhead11">
                            {" "}
                            <a
                              href="#"
                              className="btn btn-header-link"
                              data-toggle="collapse"
                              data-target="#faq11"
                              aria-expanded="true"
                              aria-controls="faq11"
                            >
                              {" "}
                              Customer relation management (CRM){" "}
                            </a>{" "}
                          </div>
                          <div
                            id="faq11"
                            className="collapse show"
                            aria-labelledby="faqhead11"
                            data-parent="#faqaccord14"
                          >
                            <div className="card-body">
                              {" "}
                              The only Restaurant Management Software with
                              natively integrated CRM and WhatsApp marketing.
                              Slice and dice your customer data on order value,
                              number of orders, location, etc. to tailor
                              targeted communication that gives tangible
                              results.
                              {/* <div className="sec-5-img show-1">
                                {" "}
                                <img
                                  src={require("../../images/sec-5-img.png")}
                                ></img>{" "}
                              </div> */}
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="faqhead12">
                            {" "}
                            <a
                              href="#"
                              className="btn btn-header-link collapsed"
                              data-toggle="collapse"
                              data-target="#faq12"
                              aria-expanded="true"
                              aria-controls="faq12"
                            >
                              {" "}
                              Beyond text{" "}
                            </a>{" "}
                          </div>
                          <div
                            id="faq12"
                            className="collapse"
                            aria-labelledby="faqhead12"
                            data-parent="#faqaccord14"
                          >
                            <div className="card-body">
                              {" "}
                              Dive into dynamic conversations that go beyond
                              words. Drive engagement and build loyalty using
                              various multimedia options such as images, videos,
                              PDFs, stickers, and more. Craft interactive
                              experiences with buttons and pick lists, making
                              every interaction vibrant and personalized.
                              {/* <div className="sec-5-img show-1">
                                {" "}
                                <img
                                  src={require("../../images/sec-5-img.png")}
                                ></img>{" "}
                              </div> */}
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="faqhead13">
                            {" "}
                            <a
                              href="#"
                              className="btn btn-header-link collapsed"
                              data-toggle="collapse"
                              data-target="#faq13"
                              aria-expanded="true"
                              aria-controls="faq13"
                            >
                              {" "}
                              Next-level customer engagement{" "}
                            </a>{" "}
                          </div>
                          <div
                            id="faq13"
                            className="collapse"
                            aria-labelledby="faqhead13"
                            data-parent="#faqaccord14"
                          >
                            <div className="card-body">
                              {" "}
                              With a staggering 70% of users checking their
                              messages daily, leverage WhatsApp as the channel
                              for swift, responsive communication. Engage your
                              audience in real-time, creating a bond that
                              customers cherish.
                              {/* <div className="sec-5-img show-1">
                                {" "}
                                <img
                                  src={require("../../images/sec-5-img.png")}
                                ></img>{" "}
                              </div> */}
                            </div>
                          </div>
                        </div>

                        <div className="card  bx_cardlst">
                          <div className="card-header" id="faqhead14">
                            {" "}
                            <a
                              href="#"
                              className="btn btn-header-link collapsed"
                              data-toggle="collapse"
                              data-target="#faq14"
                              aria-expanded="true"
                              aria-controls="faq14"
                            >
                              {" "}
                              Seamless, two-way communication{" "}
                            </a>{" "}
                          </div>
                          <div
                            id="faq14"
                            className="collapse"
                            aria-labelledby="faqhead14"
                            data-parent="#faqaccord14"
                          >
                            <div className="card-body">
                              {" "}
                              Offer your customers more than just a chat.
                              Provide a seamless, flexible two-way channel for
                              effortless communication with your business. Make
                              every interaction count whether it's inquiries,
                              feedback, or personalized assistance.
                              {/* <div className="sec-5-img show-1">
                                {" "}
                                <img
                                  src={require("../../images/sec-5-img.png")}
                                ></img>{" "}
                              </div> */}
                            </div>
                          </div>
                        </div>

                        <p className=" tab_lastline">
                          <strong>
                            Expand your horizon. boost your interaction
                          </strong>

                          <NavLink
                            to="/"
                            className="hm_fillbtn hmdemo_btn hmdemo_btn_next"
                            onClick={openModal1}
                          >
                            Get a Demo
                          </NavLink>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="point_block_main point_block_main2 point_block_main3">
              <div className="point_row_grid">
                <div>
                  <div className="point_box">
                    <h5>Inventory Management </h5>
                    <h3>Manage. Track. Control.</h3>
                    <p>
                      Take 100% control of your inventory across branches and
                      locations
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-5">
                    <div className="point_acordimg">
                      <span>
                        {" "}
                        <img
                          src={require("../../images/home-webp/Manage-track-control.webp")}
                          alt="Item Stock view of Rista Restaurant Billing software giving low stock alert to restock the items."
                          title="Item Stock view of Rista Restaurant Billing software giving low stock alert to restock the items."
                        ></img>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="point_accordians">
                      <div
                        className="accordion accordion_rista accordion_rista2"
                        id="faqaccord5"
                      >
                        <div className="card">
                          <div className="card-header" id="faqhead21">
                            {" "}
                            <a
                              href="#"
                              className="btn btn-header-link"
                              data-toggle="collapse"
                              data-target="#faq21"
                              aria-expanded="true"
                              aria-controls="faq21"
                            >
                              {" "}
                              Limit stock shortages{" "}
                            </a>{" "}
                          </div>
                          <div
                            id="faq21"
                            className="collapse show"
                            aria-labelledby="faqhead21"
                            data-parent="#faqaccord5"
                          >
                            <div className="card-body">
                              {" "}
                              Eliminate inventory discrepancies and streamline
                              your stock control. With RISTA’s inventory
                              management, you not only ensure accurate tracking
                              and monitoring but also reduce the need for
                              excessive manual intervention. Minimize stockouts,
                              and run back-of-the-house operations smoothly.
                              {/* <div className="sec-5-img show-1">
                                {" "}
                                <img
                                  src={require("../../images/sec-5-img.png")}
                                ></img>{" "}
                              </div> */}
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="faqhead22">
                            {" "}
                            <a
                              href="#"
                              className="btn btn-header-link collapsed"
                              data-toggle="collapse"
                              data-target="#faq22"
                              aria-expanded="true"
                              aria-controls="faq22"
                            >
                              {" "}
                              Understand your inventory like never before{" "}
                            </a>{" "}
                          </div>
                          <div
                            id="faq22"
                            className="collapse"
                            aria-labelledby="faqhead22"
                            data-parent="#faqaccord5"
                          >
                            <div className="card-body">
                              {" "}
                              RISTA turns every sale into a peek at your
                              inventory. It's like having a special window to
                              see what's left in your store. Figure out the best
                              time to get more items, so you always have what
                              you need!
                              {/* <div className="sec-5-img show-1">
                                {" "}
                                <img
                                  src={require("../../images/sec-5-img.png")}
                                ></img>{" "}
                              </div> */}
                            </div>
                          </div>
                        </div>
                        <div className="card  bx_cardlst">
                          <div className="card-header" id="faqhead23">
                            {" "}
                            <a
                              href="#"
                              className="btn btn-header-link collapsed"
                              data-toggle="collapse"
                              data-target="#faq23"
                              aria-expanded="true"
                              aria-controls="faq23"
                            >
                              {" "}
                              Manage recipes for consistent quality{" "}
                            </a>{" "}
                          </div>
                          <div
                            id="faq23"
                            className="collapse"
                            aria-labelledby="faqhead23"
                            data-parent="#faqaccord5"
                          >
                            <div className="card-body">
                              {" "}
                              RISTA ensures consistent taste and quality across
                              all outlets with instant recipe updates. Take
                              control of food costs by adjusting recipes and
                              enforcing portion control during preparation.
                              Also, efficiently track stock consumption for
                              every dish preparation with RISTA.
                              {/* <div className="sec-5-img show-1">
                                {" "}
                                <img
                                  src={require("../../images/sec-5-img.png")}
                                ></img>{" "}
                              </div> */}
                            </div>
                          </div>
                        </div>
                        <p className=" tab_lastline">
                          <strong>
                            Effectiveness, insights, and savings–all at your
                            fingertips!
                          </strong>

                          <NavLink
                            to="/"
                            className="hm_fillbtn hmdemo_btn hmdemo_btn_next"
                            onClick={openModal1}
                          >
                            Get a Demo
                          </NavLink>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-6 ">
        <div className="container">
          <h3 className="heading">Industry Leaders Review</h3>
          <h2 className="subheading">
            Find out why customers love Rista{" "}
            <span>as the best POS system for their restaurants!</span>
          </h2>
          <div className="mobile">
            <div className="row mid">
              <div className="col-lg-9 col-md-12">
                <div className="owl-slider pos-testimonial-slider">
                  <OwlCarousel
                    items={4}
                    className="owl-theme"
                    loop
                    nav={true}
                    margin={8}
                    responsive={{
                      0: { items: 1 }, // Set 1 item for small screens (width < 600px)
                      600: { items: 1 }, // Set 2 items for medium screens (600px <= width < 960px)
                      1025: { items: 1 }, // Set 4 items for larger screens (width >= 960px)
                    }}
                  >
                    <div className="item">
                      <div className="sec-2-item sec-2-item-1">
                        <div className="test-1">
                          <img
                            className="client-img"
                            src={require("../../images/testimony-logos/T-Barista.webp")}
                          ></img>
                          <p>
                            We have been working with the DotPe team over the
                            last 3 years, our relationship started with digital
                            payment to now using Rista POS at Barista. Team has
                            been quite proactive with their responses &
                            engagement & is open to feedback & their quick
                            implementation.
                          </p>
                          <div className="name_profile">
                            Rajat <span>CEO, Barista</span>
                          </div>
                        </div>
                        <div className="test-2">
                          <img
                            src={require("../../images/testimony-people/Barista-Rajat.webp")}
                          ></img>
                        </div>
                        <img
                          className="test-img"
                          src={require("../../images/test.png")}
                        ></img>
                      </div>
                    </div>
                    <div className="item">
                      <div className="sec-2-item sec-2-item-1 sec-2-item-2">
                        <div className="test-1">
                          <img
                            className="client-img"
                            src={require("../../images/testimony-logos/T-Chai-Break.webp")}
                          ></img>
                          <p>
                            Rista POS has streamlined our operations, making
                            order management a breeze, & DotPe's integration
                            with WhatsApp Marketing has boosted our customer
                            engagement & sales. It's a winning combination that
                            I highly recommend for any food business.
                          </p>
                          <div className="name_profile">
                            Aditya Ladasaria <span>Co-Founder, Chai Break</span>
                          </div>
                        </div>
                        <div className="test-2">
                          <img
                            src={require("../../images/testimony-people/Chai-Break-Aditya-Ladasaria.webp")}
                          ></img>
                        </div>
                        <img
                          className="test-img"
                          src={require("../../images/test.png")}
                        ></img>
                      </div>
                    </div>
                    {/* <div className="item">
                      <div className="sec-2-item sec-2-item-1 sec-2-item-3">
                        <div className="test-1">
                          <img
                            className="client-img"
                            src={require("../../images/testimony-logos/T-Indigo.webp")}
                          ></img>
                          <p>
                            At Indigo, we've been using Rista for the past year
                            & a half, & our experience has been nothing short of
                            fabulous. Both their POS & MMS work beautifully, &
                            the reports are crisp. Their service support is
                            brilliant, & I have no hesitation in saying that
                            Rista is a great product for the F&B business.
                          </p>
                          <div className="name_profile">
                            Anurag Katriar <span>Founder, Indigo</span>
                          </div>
                        </div>
                        <div className="test-2">
                          <img
                            src={require("../../images/testimony-people/Indigo-Anurag-Katriar.webp")}
                          ></img>
                        </div>
                        <img
                          className="test-img"
                          src={require("../../images/test.png")}
                        ></img>
                      </div>
                    </div> */}
                    <div className="item">
                      <div className="sec-2-item sec-2-item-1 sec-2-item-4">
                        <div className="test-1">
                          <img
                            className="client-img"
                            src={require("../../images/testimony-logos/T-Burgrill.webp")}
                          ></img>
                          <p>
                            Rista POS has truly transformed our experience at
                            Burgrill, especially when it comes to billing and
                            aggregator integration. It's not just a tool; it's
                            become an invaluable part of our daily operations.
                          </p>
                          <div className="name_profile">
                            Shreh Madan <span>Founder, Burgrill</span>
                          </div>
                        </div>
                        <div className="test-2">
                          <img
                            src={require("../../images/testimony-people/Burgrill-Shreh-Madan.webp")}
                          ></img>
                        </div>
                        <img
                          className="test-img"
                          src={require("../../images/test.png")}
                        ></img>
                      </div>
                    </div>
                    <div className="item">
                      <div className="sec-2-item sec-2-item-1 sec-2-item-4">
                        <div className="test-1">
                          <img
                            className="client-img"
                            src={require("../../images/testimony-logos/T-Mod.webp")}
                          ></img>
                          <p>
                            I've always believed in digitization for efficiency,
                            & Rista POS has been the perfect partner in helping
                            us scale our business. They've been a true growth
                            enabler, making our journey smoother & more
                            successful.
                          </p>
                          <div className="name_profile">
                            Tarak Bhattacharya{" "}
                            <span>
                              Executive Director & CEO, Mad Over Donuts
                            </span>
                          </div>
                        </div>
                        <div className="test-2">
                          <img
                            src={require("../../images/testimony-people/MOD-Tarak-Bhattacharya.webp")}
                          ></img>
                        </div>
                        <img
                          className="test-img"
                          src={require("../../images/test.png")}
                        ></img>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="abs">
          {/* <Ticker>
            {({ index }) => (
              <>
                <h1>This is the Headline of element #{index}!</h1>
                <img src="www.my-image-source.com/" alt="" />
              </>
            )}
          </Ticker> */}
        </div>
      </section>

      <section className="sec-9">
        <div className="container">
          <h2 className="subheading">50+ Seamless Integrations</h2>
          <h3 className="heading">A unified setup for your win!</h3>
        </div>
        <div>
          <SeamlessLogos />
        </div>
       
      </section>

      <section className="footer-bar-up">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="fotr_barup_infos">
                <h4>
                  Own your tech toolkit. Skyrocket your restaurant capabilities.
                </h4>
                <h2 className="subheading subheading_top">
                  Have questions about how Rista can help your business?
                </h2>

                {/* <div className="ban-bt newsletter_btn">
                  <NavLink
                    to="/"
                    className="hm_fillbtn hmdemo_btn hmdemo_btn_next"
                    onClick={openModal1}
                  >
                    Schedule a Demo!
                  </NavLink>
                </div> */}
                {/* <p>
                  Whether you operate online, in-store, or both–RISTA is the
                  centralized command of your business. From instant reports to
                  customer contacts, get everything at your fingertips, make
                  informed decisions and confidently plan for the future.
                </p> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="right-side">
                {/* <h4>Schedule your free demo today</h4> */}

                <h5>
                  We will reach out within 24 hours to schedule your slot.
                </h5>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Mobile Number"
                    ref={footerMobileRef}
                    name="mobileNumberfooter"
                    value={footerMobile}
                    onChange={handleChangeFooterForm}
                    maxLength={10}
                  />
                  {hasFooterMobileErrorText && (
                    <span className="error" style={{ color: "red" }}>
                      {hasFooterMobileErrorText}
                    </span>
                  )}
                </div>
                <button
                  className="btn btn-primary bt-form"
                  onClick={() => {
                    if (validateFooterMobileForm()) {
                      setHasFooterMobileErrorText("");
                      openModal1();
                    }
                  }}
                >
                  Schedule a Demo!
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="personal_modal_new business_modal_new">
        <Modal
          isOpen={modalIsOpen1}
          onRequestClose={closeModal1}
          // onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          className="customModalContent"
        >
          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2> */}
          {/* <button onClick={closeModal}>close</button> */}
          <div className="dotpe_popouter dotpe_popouter_new">
            <span
              className="closebtn_new"
              onClick={() => {
                closeModal1();
              }}
            >
              <img src={require("../../images/close-cross.png")} alt="Logo" />
            </span>
            <div className="row dotpe_popup dotpe_popup2 dotpe_popup3">
              <div className="dotpe_left col-md-5">
                <div className="dotpe_left_infos">
                  <h3>You are taking a right step for your business.</h3>
                  <h4>Trusted by 75 lakh+ businesses in india</h4>
                  <div className="dotpe_poplogo">
                    <img src={require("../../images/logo-w.png")} alt="Logo" />
                  </div>
                </div>
              </div>
              <div className="dotpe_right  col-md-7">
                <div className="dotpe_right_infos">
                  <div className="busi_loan_form">
                    <form>
                      <div className="form_heading">
                        <h3>Setup Free Demo</h3>
                        <p>
                          Fill in the details & our product specialist will
                          reach out to you
                        </p>
                      </div>
                      <div className={formClassName}>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          ref={inputName}
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                        />
                        {formErrors.name && (
                          <span className="error">{formErrors.name}</span>
                        )}
                      </div>
                      <div className={formClassName}>
                        {/* <div>
                          <select
                            className="form-control"
                            // value={formData.countryCode}
                            // onChange={handleChange}
                            // name="countryCode"
                          >
                              <option >
                                +91
                              </option>
                          </select>
                        </div> */}
                        <div className="mobilenum">
                          <span>+91</span>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="Mobile Number"
                            ref={inputNumber}
                            name="mobileNumber"
                            value={
                              footerMobile != ""
                                ? (formData.mobileNumber = footerMobile)
                                : formData.mobileNumber
                            }
                            onChange={handleChange}
                            maxLength={10}
                          />
                          {formErrors.mobileNumber && (
                            <span className="error">
                              {formErrors.mobileNumber}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className={formClassName}>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email Address"
                          ref={inputEmail}
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                        {formErrors.email && (
                          <span className="error">{formErrors.email}</span>
                        )}
                      </div>

                      <div className="form_click">
                        By clicking on continue, I hereby provide my consent to
                        receive emails, phone calls, messages and other
                        communications from DotPe to understand about DotPe's
                        services, offerings, promotions and other related
                        information.
                      </div>

                      <div className="formlead_btn">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            if (validateSecondForm()) {
                              validateAndOpenModal2();
                            }
                          }}
                          // onClick={validateAndOpenModal2}
                        >
                          Request Demo
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>

      <div className="personal_modal_new business_modal_new">
        <Modal
          isOpen={modalIsOpen2}
          onRequestClose={closeModal2}
          style={customStyles}
          contentLabel="Example Modal"
          className="customModalContent"
        >
          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2> */}
          {/* <button onClick={closeModal}>close</button> */}
          <div className="dotpe_popouter dotpe_popouter_new">
            <span
              className="closebtn_new"
              onClick={() => {
                closeModal2();
              }}
            >
              <img src={require("../../images/close-cross.png")} alt="Logo" />
            </span>
            <div className="row dotpe_popup dotpe_popup2 dotpe_popup3">
              <div className="dotpe_left col-md-5 dotpe_left2_mode">
                <div className="dotpe_left_infos  dotpe_left2_mode_infos">
                  <div className="dotpe_popcheck">
                    <img src={successcheck2} alt="Icon" />
                  </div>
                  <h4>You have successfully scheduled a call with us.</h4>
                  <div className="dotpe_poplogo">
                    <img src={require("../../images/logo-w.png")} alt="Logo" />
                  </div>
                </div>
              </div>
              <div className="dotpe_right  col-md-7">
                <div className="dotpe_right_infos">
                  <div className="busi_loan_form">
                    <form>
                      <div className="form_heading">
                        <h3>
                          Our team will reach out to you in next few minutes
                        </h3>
                        <p>Meanwhile tell us more about your 1 business day.</p>
                      </div>

                      <div className="form-group form_group_nice">
                        <label>Select a business category</label>
                        <NiceSelect
                          id="a-select"
                          placeholder="Select..."
                          className="sampleClass"
                          name="incomeSource"
                          value={formData.incomeSource}
                          onChange={handleChange}
                        >
                          <option value="Food and beverage">
                            Food and beverage
                          </option>
                          <option value="Clothing and accessories">
                            Clothing and accessories
                          </option>
                          <option value="Bakery">Bakery</option>
                          <option value="Salon">Salon</option>
                          <option value="Home Decor and furnishing">
                            Home Decor and furnishing
                          </option>
                          <option value="eCommerce">eCommerce</option>
                          <option value="Services">Services</option>
                        </NiceSelect>
                        {formErrors.incomeSource && (
                          <span className="error">
                            {formErrors.incomeSource}
                          </span>
                        )}
                      </div>
                      <div className="form-group form_group_nice form_group_nice2">
                        <label>Select your role in this business.</label>
                        <NiceSelect
                          id="a-select1"
                          placeholder="Select..."
                          className="sampleClass"
                          name="role"
                          value={formData.role}
                          onChange={handleChange}
                        >
                          <option value="Owner">Owner</option>
                          <option value="Store Manager">Store Manager</option>
                          <option value="Accounts">Accounts</option>
                          <option value="Sales">Sales</option>
                        </NiceSelect>
                        {formErrors.role && (
                          <span className="error">{formErrors.role}</span>
                        )}
                      </div>
                      <div className="formlead_btn formlead_btn2">
                        <button
                          type="button"
                          className="btn btn-primary"
                          // onClick={() => {
                          //   if (validateSecondForm()) {
                          //     saveDetails();
                          //   }
                          // }}
                          onClick={saveDetails}
                        >
                          Done
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <Footer />
    </div>
  );
};

export default Home;





