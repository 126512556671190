import React, { useState, useEffect, useRef } from 'react';
import './common.css';
import { NavLink } from 'react-router-dom';
import top_background from '../../images/top-bar.png';
import logow from '../../images/logo-w.png';
import Modal from 'react-modal';

import successcheck2 from '../../images/success-check2.svg';
import { useNavigate, useLocation } from "react-router-dom";
import NiceSelect from '../../NiceSelect/NiceSelect';

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1,
  },
};

const HeaderDark = () => {
  const location = useLocation();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  };

  const [isToggled, setIsToggled] = useState(false);
  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  const [hasError, setHasError] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    countryCode: '+91',
    mobileNumber: '',
    pincode: '',
    incomeSource: '',
    buss_age: '',
    buss_turnover: '',
    email: '',
  });
  const [formErrors, setFormErrors] = useState({
    name: '',
    mobileNumber: '',
    pincode: '',
    incomeSource: '',
    buss_age: '',
    buss_turnover: '',
    email: '',
  });
  const inputName = useRef(null);
  const inputNumber = useRef(null);
  const inputEmail = useRef(null);
  //const countryCodes = ["+91", "+1", "+44", "+61", "+81", "+86"];
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [modalIsOpen2, setModalIsOpen2] = useState(false);

  const openModal1 = () => {
    setModalIsOpen1(true);
  };

  const openModal2 = () => {
    setModalIsOpen2(true);
    setModalIsOpen1(false);
  };

  const closeModal1 = () => {
    setModalIsOpen1(false);
  };

  const closeModal2 = () => {
    setModalIsOpen2(false);
  };

  const resetForms = () => {
    setFormData({
      name: '',
      mobileNumber: '',
      pincode: '',
      incomeSource: '',
      buss_age: '',
      buss_turnover: '',
      email: '',
    });
  };
  const [emailSuggestions, setEmailSuggestions] = useState([]);
  const domains = ['gmail.com', 'yahoo.com', 'hotmail.com'];
  const validateSecondForm = () => {
    const errors = {};
    let formIsValid = true;
    const nameRegex = /^[A-Za-z\s]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const numberRegex = /^\d+$/;

    if (!formData.name || !formData.name.trim()) {
      formIsValid = false;
      errors.name = 'Name is required';
    } else if (formData.name.trim().length < 3) {
      formIsValid = false;
      errors.name = 'Name should have at least 3 characters';
    } else if (formData.name.trim().length > 30) {
      formIsValid = false;
      errors.name = 'Name should have at max 30 characters';
    } else if (!nameRegex.test(formData.name.trim())) {
      formIsValid = false;
      errors.name = 'Name should contain only letters and spaces';
    }

    if (!formData.email || !formData.email.trim()) {
      formIsValid = false;
      errors.email = 'Email is required';
    } else if (!emailRegex.test(formData.email)) {
      formIsValid = false;
      errors.email = 'Invalid email address';
    }

    if (!formData.mobileNumber || !formData.mobileNumber.trim()) {
      formIsValid = false;
      errors.mobileNumber = 'Mobile Number is required';
    } else if (!numberRegex.test(formData.mobileNumber)) {
      formIsValid = false;
      errors.mobileNumber = 'Invalid mobile number';
    } else if (formData.mobileNumber.trim().length !== 10) {
      formIsValid = false;
      errors.mobileNumber = 'Mobile number must be exactly 10 digits';
    } else {
      // Ensure mobile number starts with 6, 7, 8, or 9
      const firstDigit = formData.mobileNumber.trim().charAt(0);
      if (!['6', '7', '8', '9'].includes(firstDigit)) {
        formIsValid = false;
        errors.mobileNumber = 'Mobile number must start with 6, 7, 8, or 9';
      }
    }

    setFormErrors(errors);
    setHasError(!formIsValid);

    return formIsValid;
  };

  const handleSuggestionClick = (suggestion) => {
    setFormData((prevData) => ({
      ...prevData,
      ['email']: suggestion,
    }));
    setEmailSuggestions([]);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    if (name === 'name') {
      if (value === '' || /^[^\s]/.test(value)) {
        newValue = value;
      } else {
        newValue = value.trimStart();
      }
    } else if (name === 'mobileNumber') {
      newValue = value.replace(/\D/g, '');

      if (newValue.length > 10) {
        newValue = newValue.slice(0, 10);
      }
    } else if (name === 'email') {
      if (value.includes('@')) {
        const [localPart, domainPart] = value.split('@');
        setEmailSuggestions(
          domains
            .filter((domain) => domain.startsWith(domainPart))
            .map((domain) => `${localPart}@${domain}`)
        );
      } else {
        setEmailSuggestions([]);
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };
  const [firstFormId, setFirstFormId] = useState('');
  const validateAndOpenModal2 = async () => {
    if (await validateSecondForm()) {
      const apiUrl = process.env.REACT_APP_FORM_ONE;
      const combinedData = {
        name: formData.name,
        phone: formData.mobileNumber,
        email: formData.email,
        source: window.location.href,
      };
      localStorage.setItem('mobile', formData.mobileNumber);
      console.log(combinedData);

      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json; charset=utf-8',
          },
          body: JSON.stringify(combinedData),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data);
        setFirstFormId(data.id);
        openModal2();
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  // const saveDetails = () => {
  //   closeModal2();
  // };
  const navigate = useNavigate();
  const validateForm = () => {
    const errors = {};
    let formIsValid = true;

    if (!formData.incomeSource || formData.incomeSource === '0') {
      formIsValid = false;
      errors.incomeSource = 'Please select a business category';
    }

    if (!formData.role || formData.role === '0') {
      formIsValid = false;
      errors.role = 'Please select your role';
    }

    setFormErrors(errors);
    return formIsValid;
  };

  const saveDetails = async () => {
    const isValid = validateForm();

    if (isValid) {
      const apiUrl = process.env.REACT_APP_FORM_ONE + '/' + firstFormId;
      const combinedData = {
        businessCategory: formData.incomeSource,
        role: formData.role,
      };
      console.log(combinedData);

      try {
        const response = await fetch(apiUrl, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(combinedData),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data);
        resetForms();
        closeModal2();
        navigate('/thankyou');
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    function handleScroll() {
      setIsScrolled(window.scrollY > 0);
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <div className="menudrop"></div>
      
    {/* <div className='topheader'>
      <div className='container'>
        <div className='topheader-1'>
          <div className='topheader-inner'>
            <p>Upcoming Event: How Custom Reports can transform your restaurant operations. Join us on 12th September’24 </p>
          </div>
          <div className='topheader-inner-a'>
            <NavLink to="/events/custom-reports-webinar-sep/">Register Now</NavLink>
          </div>
        </div>
      </div>
    </div> */}
      <div
        className={`header headermain headerdark ${
          isScrolled ? "headermain_scroll" : ""
        } ${isToggled ? "toggled" : ""}`}
        onClick={handleToggle}
      >
        <div className="container">
          <nav class="navbar navbar-expand-lg navbar-light">
            <div className="logo_toggle">
              <NavLink className="navbar-brand" to="/" onClick={scrollToTop}>
                <img src={logow} alt="Logo" />
              </NavLink>
              <button
                class={`navbar-toggler ${isToggled ? "toggless" : ""}`}
                type="button"
                data-toggle="collapse"
                data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={handleToggle}
              >
                <span
                  class="navbar-toggler-icon"
                  style={{ backgroundImage: `url(${top_background})` }}
                ></span>
              </button>
            </div>
            <div class="collapse navbar-collapse" id="navbarNavDropdown">
              <ul class="navbar-nav mx-auto">
                <li class="nav-item">
                  <NavLink
                    exact
                    to="/"
                    className="nav-link"
                    activeClassName="active"
                    onClick={scrollToTop}
                  >
                    Home
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink
                    exact
                    to="/restaurant-point-of-sale-software"
                    className="nav-link"
                    activeClassName="active"
                    onClick={scrollToTop}
                  >
                    Point of Sale
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink
                    exact
                    to="/restaurant-inventory-management"
                    className="nav-link"
                    activeClassName="active"
                    onClick={scrollToTop}
                  >
                    Inventory
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink
                    exact
                    to="/restaurant-direct-ordering-system"
                    className="nav-link"
                    activeClassName="active"
                    onClick={scrollToTop}
                  >
                    Direct Ordering
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink
                    exact
                    to="/whatsapp-business-api"
                    className="nav-link"
                    activeClassName="active"
                    onClick={scrollToTop}
                  >
                    WhatsApp for Business
                  </NavLink>
                </li>
                {/* <li class="nav-item">
                  <NavLink
                    exact
                    to="/restaurant-pos-software"
                    className="nav-link"
                    activeClassName="active"
                    onClick={scrollToTop}
                  >
                    Restaurant pos Software
                  </NavLink>
                </li> */}
                {/* <li class='nav-item'>
                <a class='nav-link' href='#'>
                  Blogs
                </a>
              </li> */}
                <li class="nav-item mobilenavlink">
                  <NavLink
                    exact
                    to="/contactus"
                    className="nav-link"
                    activeClassName="active"
                    onClick={scrollToTop}
                  >
                    Contact Us
                  </NavLink>
                </li>
                <li class="nav-item mobilenavlink">
                  <NavLink
                    exact
                    to="/aboutus"
                    className="nav-link"
                    activeClassName="active"
                    onClick={scrollToTop}
                  >
                    About Us
                  </NavLink>
                </li>
              </ul>

              <ul className="navbar-nav ml-auto right_menu">
                <li className="nav-item">
                  <NavLink className="nav-link" onClick={openModal1}>
                    Get a Demo
                  </NavLink>
                </li>
              </ul>
            </div>

            <div className="personal_modal_new business_modal_new">
              <Modal
                isOpen={modalIsOpen1}
                onRequestClose={closeModal1}
                // onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                className="customModalContent"
              >
                {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2> */}
                {/* <button onClick={closeModal}>close</button> */}
                <div className="dotpe_popouter dotpe_popouter_new">
                  <span
                    className="closebtn_new"
                    onClick={() => {
                      closeModal1();
                    }}
                  >
                    <img
                      src={require("../../images/close-cross.png")}
                      alt="Logo"
                    />
                  </span>
                  <div className="row dotpe_popup dotpe_popup2 dotpe_popup3">
                    <div className="dotpe_left col-md-5">
                      <div className="dotpe_left_infos">
                        <h3>You are taking a right step for your business.</h3>
                        <h4>Trusted by 75 lakh+ businesses in india</h4>
                        <div className="dotpe_poplogo">
                          <img
                            src={require("../../images/logo-w.png")}
                            alt="Logo"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dotpe_right">
                      <div className="dotpe_right_infos">
                        <div className="busi_loan_form">
                          <form>
                            <div className="form_heading">
                              <h3>Setup Free Demo</h3>
                              <p>
                                Fill in the details & our product specialist
                                will reach out to you
                              </p>
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Name"
                                ref={inputName}
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                              />
                              {formErrors.name && (
                                <span className="error">{formErrors.name}</span>
                              )}
                            </div>
                            <div className="form-group">
                              {/* <div>
                          <select
                            className="form-control"
                            // value={formData.countryCode}
                            // onChange={handleChange}
                            // name="countryCode"
                          >
                              <option >
                                +91
                              </option>
                          </select>
                        </div> */}
                              <div className="mobilenum">
                                <span>+91</span>

                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Mobile Number"
                                  ref={inputNumber}
                                  name="mobileNumber"
                                  value={formData.mobileNumber}
                                  onChange={handleChange}
                                  maxLength={10}
                                />
                                {formErrors.mobileNumber && (
                                  <span className="error">
                                    {formErrors.mobileNumber}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Email Address"
                                ref={inputEmail}
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                              />
                              {formErrors.email && (
                                <span className="error">
                                  {formErrors.email}
                                </span>
                              )}
                            </div>

                            <div className="form_click">
                              By clicking on continue, I hereby provide my
                              consent to receive emails, phone calls, messages
                              and other communications from DotPe to understand
                              about DotPe's services, offerings, promotions and
                              other related information.
                            </div>

                            <div className="formlead_btn">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={validateAndOpenModal2}
                                // onClick={validateAndOpenModal2}
                              >
                                Request Demo
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>

            <div className="personal_modal_new business_modal_new">
              <Modal
                isOpen={modalIsOpen2}
                onRequestClose={closeModal2}
                style={customStyles}
                contentLabel="Example Modal"
                className="customModalContent"
              >
                {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2> */}
                {/* <button onClick={closeModal}>close</button> */}
                <div className="dotpe_popouter dotpe_popouter_new">
                  <span
                    className="closebtn_new"
                    onClick={() => {
                      closeModal2();
                    }}
                  >
                    <img
                      src={require("../../images/close-cross.png")}
                      alt="Logo"
                    />
                  </span>
                  <div className="dotpe_popup dotpe_popup2 dotpe_popup3">
                    <div className="dotpe_left dotpe_left2_mode">
                      <div className="dotpe_left_infos  dotpe_left2_mode_infos">
                        <div className="dotpe_popcheck">
                          <img src={successcheck2} alt="Icon" />
                        </div>
                        <h4>You have successfully scheduled a call with us.</h4>
                        <div className="dotpe_poplogo">
                          <img
                            src={require("../../images/logo-w.png")}
                            alt="Logo"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dotpe_right">
                      <div className="dotpe_right_infos">
                        <div className="busi_loan_form">
                          <form>
                            <div className="form_heading">
                              <h3>
                                Our team will reach out to you in next few
                                minutes
                              </h3>
                              <p>
                                Meanwhile tell us more about your 1 business
                                day.
                              </p>
                            </div>

                            <div className="form-group form_group_nice">
                              <label>Select a business category</label>
                              <NiceSelect
                                id="a-select"
                                placeholder="Select..."
                                className="sampleClass"
                                name="incomeSource"
                                value={formData.incomeSource}
                                onChange={handleChange}
                              >
                                <option value="Food and beverage">
                                  Food and beverage
                                </option>
                                <option value="Clothing and accessories">
                                  Clothing and accessories
                                </option>
                                <option value="Bakery">Bakery</option>
                                <option value="Salon">Salon</option>
                                <option value="Home Decor and furnishing">
                                  Home Decor and furnishing
                                </option>
                                <option value="eCommerce">eCommerce</option>
                                <option value="Services">Services</option>
                              </NiceSelect>
                              {formErrors.incomeSource && (
                                <span className="error">
                                  {formErrors.incomeSource}
                                </span>
                              )}
                            </div>
                            <div className="form-group form_group_nice form_group_nice2">
                              <label>Select your role in this business.</label>
                              <NiceSelect
                                id="a-select1"
                                placeholder="Select..."
                                className="sampleClass"
                                name="role"
                                value={formData.role}
                                onChange={handleChange}
                              >
                                <option value="Owner">Owner</option>
                                <option value="Store Manager">
                                  Store Manager
                                </option>
                                <option value="Accounts">Accounts</option>
                                <option value="Sales">Sales</option>
                              </NiceSelect>
                              {formErrors.incomeSource && (
                                <span className="error">
                                  {formErrors.incomeSource}
                                </span>
                              )}
                            </div>
                            <div className="formlead_btn formlead_btn2">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={saveDetails}
                              >
                                Done
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default HeaderDark;
