import React, { useEffect, useRef, useState } from 'react';
import Footer from '../common/Footer';
import Header from '../common/Header';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Modal from 'react-modal';
import NiceSelect from '../../NiceSelect/NiceSelect';
import successcheck2 from '../../images/success-check2.svg';
import { Helmet } from 'react-helmet-async';
import BrandLogo from "../common/BrandLogo";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1,
  },
};
const RestaurantDirectOrderingSystem = () => {
  const location = useLocation();
  const resetForms = () => {
    setFormData({
      name: "",
      mobileNumber: "",
      pincode: "",
      incomeSource: "",
      buss_age: "",
      buss_turnover: "",
      email: "",
    });
  };

  const [firstFormId, setFirstFormId] = useState("");
  const [isSectionVisible, setIsSectionVisible] = useState(false);
  const [areTabsVisible, setAreTabsVisible] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector(".sec-3.sec3_home");
      const tabs = document.querySelector(".tab_blockinfo_main");

      if (section) {
        const sectionTop = section.getBoundingClientRect().top;
        const sectionBottom = section.getBoundingClientRect().bottom;
        const windowHeight = window.innerHeight;
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;

        if (scrollTop > lastScrollTop) {
          // Scrolling down
          if (sectionTop < windowHeight && sectionBottom > 0) {
            setIsSectionVisible(true);

            if (tabs) {
              const tabsTop = tabs.getBoundingClientRect().top;
              if (tabsTop < windowHeight && tabsTop >= 0) {
                setAreTabsVisible(true);
              }
            }
          } else {
            setIsSectionVisible(false);
            setAreTabsVisible(false);
          }
        } else {
          // Scrolling up
          setIsSectionVisible(false);
          setAreTabsVisible(false);
        }
        setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);

  // const [activeTab, setActiveTab] = useState('flamingo');

  const [activeTab, setActiveTab] = useState("flamingo");

  const handlePrevTab = () => {
    switch (activeTab) {
      case "cuckoo":
        setActiveTab("flamingo");
        break;
      case "ostrich":
        setActiveTab("cuckoo");
        break;
      case "tropicbird":
        setActiveTab("ostrich");
        break;
      case "tropicbird-1":
        setActiveTab("tropicbird");
        break;
      default:
        break;
    }
  };

  const handleNextTab = () => {
    switch (activeTab) {
      case "flamingo":
        setActiveTab("cuckoo");
        break;
      case "cuckoo":
        setActiveTab("ostrich");
        break;
      case "ostrich":
        setActiveTab("tropicbird");
        break;
      case "tropicbird":
        setActiveTab("tropicbird-1");
        break;
      default:
        break;
    }
  };

  const handleNavItemClick = (tab) => {
    setActiveTab(tab);
  };
  const [hasError, setHasError] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    countryCode: "+91",
    mobileNumber: "",
    pincode: "",
    incomeSource: "",
    buss_age: "",
    buss_turnover: "",
    email: "",
  });
  const [formErrors, setFormErrors] = useState({
    name: "",
    mobileNumber: "",
    pincode: "",
    incomeSource: "",
    buss_age: "",
    buss_turnover: "",
    email: "",
  });
  const inputName = useRef(null);
  const inputNumber = useRef(null);
  const inputEmail = useRef(null);
  //const countryCodes = ["+91", "+1", "+44", "+61", "+81", "+86"];
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [modalIsOpen2, setModalIsOpen2] = useState(false);

  const openModal1 = () => {
    setModalIsOpen1(true);
  };

  const openModal2 = () => {
    setModalIsOpen2(true);
    setModalIsOpen1(false);
  };

  const closeModal1 = () => {
    setModalIsOpen1(false);
  };

  const closeModal2 = () => {
    setModalIsOpen2(false);
  };

  const [emailSuggestions, setEmailSuggestions] = useState([]);
  const domains = ["gmail.com", "yahoo.com", "hotmail.com"];

  const validateSecondForm = () => {
    const errors = {};
    let formIsValid = true;
    const nameRegex = /^[A-Za-z\s]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const numberRegex = /^\d+$/;

    if (!formData.name || !formData.name.trim()) {
      formIsValid = false;
      errors.name = "Name is required";
    } else if (formData.name.trim().length < 3) {
      formIsValid = false;
      errors.name = "Name should have at least 3 characters";
    } else if (formData.name.trim().length > 30) {
      formIsValid = false;
      errors.name = "Name should have at max 30 characters";
    } else if (!nameRegex.test(formData.name.trim())) {
      formIsValid = false;
      errors.name = "Name should contain only letters and spaces";
    }

    if (!formData.email || !formData.email.trim()) {
      formIsValid = false;
      errors.email = "Email is required";
    } else if (!emailRegex.test(formData.email)) {
      formIsValid = false;
      errors.email = "Invalid email address";
    }

    if (!formData.mobileNumber || !formData.mobileNumber.trim()) {
      formIsValid = false;
      errors.mobileNumber = "Mobile Number is required";
    } else if (!numberRegex.test(formData.mobileNumber)) {
      formIsValid = false;
      errors.mobileNumber = "Invalid mobile number";
    } else if (formData.mobileNumber.trim().length !== 10) {
      formIsValid = false;
      errors.mobileNumber = "Mobile number must be exactly 10 digits";
    } else {
      // Ensure mobile number starts with 6, 7, 8, or 9
      const firstDigit = formData.mobileNumber.trim().charAt(0);
      if (!["6", "7", "8", "9"].includes(firstDigit)) {
        formIsValid = false;
        errors.mobileNumber = "Mobile number must start with 6, 7, 8, or 9";
      }
    }

    setFormErrors(errors);
    setHasError(!formIsValid);

    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    if (name === "name") {
      if (value === "" || /^[^\s]/.test(value)) {
        newValue = value;
      } else {
        newValue = value.trimStart();
      }
    } else if (name === "mobileNumber") {
      newValue = value.replace(/\D/g, "");

      if (newValue.length > 10) {
        newValue = newValue.slice(0, 10);
      }
    } else if (name === "email") {
      if (value.includes("@")) {
        const [localPart, domainPart] = value.split("@");
        setEmailSuggestions(
          domains
            .filter((domain) => domain.startsWith(domainPart))
            .map((domain) => `${localPart}@${domain}`)
        );
      } else {
        setEmailSuggestions([]);
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateSecondForm();
  };

  const formClassName = hasError ? "form-error form-group" : "form-group";
  const validateAndOpenModal2 = async () => {
    if (await validateSecondForm()) {
      const apiUrl = process.env.REACT_APP_FORM_ONE;
      const combinedData = {
        name: formData.name,
        phone: formData.mobileNumber,
        email: formData.email,
        source: window.location.href,
      };
      localStorage.setItem("mobile", formData.mobileNumber);
      console.log(combinedData);

      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json; charset=utf-8",
          },
          body: JSON.stringify(combinedData),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data);
        setFirstFormId(data.id);
        openModal2();
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  // const saveDetails = () => {
  //   closeModal2();
  // };
  const navigate = useNavigate();
  const validateForm = () => {
    const errors = {};
    let formIsValid = true;

    if (!formData.incomeSource || formData.incomeSource === "0") {
      formIsValid = false;
      errors.incomeSource = "Please select a business category";
    }

    if (!formData.role || formData.role === "0") {
      formIsValid = false;
      errors.role = "Please select your role";
    }

    setFormErrors(errors);
    return formIsValid;
  };

  const saveDetails = async () => {
    const isValid = validateForm();

    if (isValid) {
      const apiUrl = process.env.REACT_APP_FORM_ONE + "/" + firstFormId;
      const combinedData = {
        businessCategory: formData.incomeSource,
        role: formData.role,
      };
      console.log(combinedData);

      try {
        const response = await fetch(apiUrl, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(combinedData),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data);
        resetForms();
        closeModal2();
        navigate("/thankyou");
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
  const [footerMobile, setFooterMobile] = useState("");
  const footerMobileRef = useRef(null);
  const [hasFooterMobileError, setHasFooterMobileError] = useState(false);
  const [hasFooterMobileErrorText, setHasFooterMobileErrorText] = useState("");
  const handleChangeFooterForm = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    if (name === "mobileNumberfooter") {
      console.log(newValue.length);
      newValue = value.replace(/\D/g, "");

      if (newValue.length > 10) {
        newValue = newValue.slice(0, 10);
      }
    }
    setFooterMobile(newValue);
  };
  const validateFooterMobileForm = () => {
    let formIsValid = true;
    const numberRegex = /^\d+$/;
    if (!footerMobile || !footerMobile.trim()) {
      formIsValid = false;
      setHasFooterMobileErrorText("Mobile Number is required");
    } else if (!numberRegex.test(footerMobile)) {
      formIsValid = false;
      setHasFooterMobileErrorText("Invalid mobile number");
    } else if (footerMobile.trim().length !== 10) {
      formIsValid = false;
      setHasFooterMobileErrorText("Mobile number must be exactly 10 digits");
    } else {
      const firstDigit = footerMobile.trim().charAt(0);
      if (!["6", "7", "8", "9"].includes(firstDigit)) {
        formIsValid = false;
        setHasFooterMobileErrorText(
          "Mobile number must start with 6, 7, 8, or 9"
        );
      }
    }
    setHasFooterMobileError(!formIsValid);

    return formIsValid;
  };
  return (
    <div>
      <Helmet>
        <title>
          Online Food Ordering System for Restaurants integrated with Rista
          Restaurant POS.
        </title>
        <meta
          name="description"
          content="Restaurant online food ordering system with tracking integrated with Rista - Restaurant POS system"
        />
        <meta
          name="keywords"
          content="online food ordering system, online menu ordering system, food ordering system, online food ordering system for restaurants, restaurant online ordering system, online ordering for restaurants, restaurant order online system"
        />
        <meta name="author" content="Rista Apps" />
        <meta
          property="og:description"
          content="Restaurant online food ordering system with tracking integrated with Rista - Restaurant POS system "
        />
        <meta
          property="og:image"
          content={require("../../images/logo-w.png")}
        />
      </Helmet>

      <Header />
      <section className="banner banner_home banner_noimage banner_direct_ordering set_banner directnewmob banner_direct_ordering_new">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
              <div className="banner-content">
                <span className="banner-subheading home_banner_subheading">
                  Direct Ordering
                </span>
                <h1 className="banner-heading">
                  Own your customer experience and data
                  <span> with our direct ordering solution.</span>
                </h1>
                <div className="banner-content hm_banner_desk">
                  <h2 className="banner-para">
                    Take control of your earnings and enhance convenience.
                    Enable customers to order directly from your website, so you
                    retain <b>100%</b> of the profits—eliminating third-party
                    commissions.
                  </h2>
                  <div className="ban-bt">
                    <NavLink className="hm_fillbtn" onClick={openModal1}>
                      Get a Demo
                    </NavLink>
                    {/* <a class="ban-second-cta" href="#" onClick={openModal1}>
                      Know How/Learn More
                    </a> */}
                  </div>
                  <div className="banner-mobile">
                    <div className="ban-last">
                      <img
                        className="ban-last-img"
                        src={require("../../images/star.png")}
                      ></img>{" "}
                      Top 20 Technology Startups <span>LinkedIn, 2023</span>
                    </div>
                    <div className="ban-last ban-last-1">
                      <img
                        className="ban-last-img"
                        src={require("../../images/star.png")}
                      ></img>{" "}
                      India’s Best POS <span>ET Hospitality World, 2022</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
              <div className="banner-image hmbanner_img">
                {/* <span></span>
                <span className="hmbanner_img2"></span> */}
                <img
                  className="banr-img pc-imag"
                  alt="restaurant online menu for online food ordering with restaurant qr integrated with rista restaurant pos"
                  title="restaurant online menu for online food ordering with restaurant qr integrated with rista restaurant pos"
                  src={require("../../images/direct-ordering-webp/Direct_Ordering_Main.webp")}
                ></img>
                <img
                  alt="restaurant online menu for online food ordering with restaurant qr integrated with rista restaurant pos"
                  title="restaurant online menu for online food ordering with restaurant qr integrated with rista restaurant pos"
                  className="banr-img mobile-imag"
                  src={require("../../images/direct-ordering-webp/Direct_Ordering_Main.webp")}
                ></img>
              </div>

              <div className="banner-content banner_contt_mob">
                <p className="banner-para">
                  Take control of your earnings and enhance convenience. Enable
                  customers to order directly from your website, so you retain
                  <b>100%</b> of the profits—eliminating third-party
                  commissions.
                </p>
                <div className="ban-bt">
                  <a class="ban-cta" href="#" onClick={openModal1}>
                    Schedule a Demo
                  </a>
                  {/* <a class="ban-second-cta" href="#" onClick={openModal1}>
                    Know How/Learn More
                  </a> */}
                </div>
                <div className="banner-mobile">
                  <div className="ban-last">
                    <img
                      className="ban-last-img"
                      src={require("../../images/star.png")}
                    ></img>{" "}
                    Top 20 Technology Startups <span>LinkedIn, 2023</span>
                  </div>
                  <div className="ban-last ban-last-1">
                    <img
                      className="ban-last-img"
                      src={require("../../images/star.png")}
                    ></img>{" "}
                    India’s Best POS <span>ET Hospitality World, 2022</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section one */}

      <section className="sec-2 direct_sec2 set_logo_section">
        <div className="container">
          <p className="para">
            Thousands <small>of restaurants</small> run and grow{" "}
            <img src={require("../../images/till-arrow.png")}></img>{" "}
            <small>on</small> RISTA,
            <small> every day.</small>
          </p>
          <BrandLogo />
        </div>
      </section>

      {/* section two */}

      <section className="sec3new sec_space direct_ordr_most set_elevate-section">
        <div className="container">
          <div className="sec3new_inner">
            <div className="hm_heading hm_heading_big ">
              <h5>Direct Orders. Direct Profits.</h5>
              <h2>
                Create meaningful
                <br /> customer <br className="create_meanbr" />
                connections.
              </h2>
              <p>
                With our direct ordering platform, you gain invaluable insights
                and build direct relationships using accurate customer data.
                Offer personalized service that turns customers into loyal fans.
              </p>
            </div>
            <div className="sec3_n_btns">
              <NavLink to="" className="hm_fillbtn" onClick={openModal1}>
                Get a Demo
              </NavLink>
              {/* <NavLink to="" className="hm_linkbtn" onClick={openModal1}>
                Learn More
              </NavLink> */}
            </div>
          </div>
        </div>
      </section>

      <section className="sec3new_upsec sec_space set_big_slides_section hm_hidesec">
        <div className="container">
          <div className="sec-6 deordr_owl_slides">
            <div className="owl-slider">
              <OwlCarousel
                items={4}
                className="owl-theme"
                loop={true}
                autoplay={true}
                nav={true}
                dots={true}
                margin={8}
                responsive={{
                  0: { items: 1, nav: true, dots: true }, // Set 1 item for small screens (width < 600px)
                  600: { items: 1, nav: true, dots: true }, // Set 2 items for medium screens (600px <= width < 960px)
                  1025: { items: 1 }, // Set 4 items for larger screens (width >= 960px)
                }}
              >
                <div className="item">
                  <div className="sec-2-item sec-2-item-1">
                    <div className="test-1">
                      <img
                        className="client-img"
                        src={require("../../images/testimony-logos/T-TBC.webp")}
                      ></img>
                      <p>
                        Over the last three years, DotPe has been a game-changer
                        for us. It's brilliant Ul, robust technology platform, &
                        user-friendly kitchen dashboard have made our operations
                        smoother than ever. Plus, the control it gives us over
                        customer data is a game-changer for our business!
                      </p>
                    </div>
                    <div className="test-2">
                      <div className="client-name">
                        <h5>Aseem Grover</h5>
                        <p>Owner, Big Chill</p>
                      </div>
                      <img
                        src={require("../../images/testimony-people/Big-Chill-Aseem-Grover.webp")}
                      ></img>
                    </div>
                    <img
                      className="test-img"
                      src={require("../../images/test.png")}
                    ></img>
                  </div>
                </div>
                <div className="item">
                  <div className="sec-2-item sec-2-item-1">
                    <div className="test-1">
                      <img
                        className="client-img"
                        src={require("../../images/testimony-logos/T-Zing.webp")}
                      ></img>
                      <p>
                        DotPe's tech stack has truly transformed our operations.
                        We seamlessly use it for both dine-in and cloud
                        kitchens, & the automation of order acceptance has made
                        our processes highly accurate & efficient. Their
                        intuitive & customizable inventory management system has
                        been a game-changer.
                      </p>
                    </div>
                    <div className="test-2">
                      <div className="client-name">
                        <h5>Abhimanyu Maheshwari </h5>
                        <p>Co-Founder, Chai Break</p>
                      </div>
                      <img
                        src={require("../../images/testimony-people/Zing-Abhimanyu-Maheshwari.webp")}
                      ></img>
                    </div>
                    <img
                      className="test-img"
                      src={require("../../images/test.png")}
                    ></img>
                  </div>
                </div>
                <div className="item">
                  <div className="sec-2-item sec-2-item-1">
                    <div className="test-1">
                      <img
                        className="client-img"
                        src={require("../../images/testimony-logos/T-Eatfit.webp")}
                      ></img>
                      <p>
                        EatFit has partnered with DotPe for our cloud kitchen
                        business & we are extremely happy with what we have
                        seen. The team is extremely helpful & helped our us
                        scale up on various areas on the tool especially the
                        "whatsapp for business" which has helped us increase our
                        revenue significantly.
                      </p>
                    </div>
                    <div className="test-2">
                      <div className="client-name">
                        <h5>Ankit Nagori</h5>
                        <p>Founder, Eatfit</p>
                      </div>
                      <img
                        src={require("../../images/testimony-people/Eat-fit-Ankit-Nagori.webp")}
                      ></img>
                    </div>
                    <img
                      className="test-img"
                      src={require("../../images/test.png")}
                    ></img>
                  </div>
                </div>
                <div className="item">
                  <div className="sec-2-item sec-2-item-1">
                    <div className="test-1">
                      <img
                        className="client-img"
                        src={require("../../images/testimony-logos/T-Indigo.webp")}
                      ></img>
                      <p>
                        At Indigo, we've been using Rista for the past year & a
                        half, & our experience has been nothing short of
                        fabulous. Both their POS & MMS work beautifully, & the
                        reports are crisp. Their service support is brilliant, &
                        I have no hesitation in saying that Rista is a great
                        product for the F&B business.
                      </p>
                    </div>
                    <div className="test-2">
                      <div className="client-name">
                        <h5>Anurag Katriar</h5>
                        <p>Founder, Indigo</p>
                      </div>
                      <img
                        src={require("../../images/testimony-people/Indigo-Anurag-Katriar.webp")}
                      ></img>
                    </div>
                    <img
                      className="test-img"
                      src={require("../../images/test.png")}
                    ></img>
                  </div>
                </div>
                <div className="item">
                  <div className="sec-2-item sec-2-item-1">
                    <div className="test-1">
                      <img
                        className="client-img"
                        src={require("../../images/testimony-logos/T-Frozen-Bottle.webp")}
                      ></img>
                      <p>
                        DotPe's partnership has been a bridge between our brand
                        & our audience.Their user-friendly & effective system
                        has revolutionised our engagement strategies, helping us
                        connect with our customers like never before.
                      </p>
                    </div>
                    <div className="test-2">
                      <div className="client-name">
                        <h5>Pranshul Yadav</h5>
                        <p>Founder, Frozen Bottle</p>
                      </div>
                      <img
                        src={require("../../images/testimony-people/Frozen-Bottle-Pranshul-Yadav.webp")}
                      ></img>
                    </div>
                    <img
                      className="test-img"
                      src={require("../../images/test.png")}
                    ></img>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>

      {/* section three */}

      <section className="sec4new sec_space dordr_whysec set_why_section">
        <div className="container">
          <div className="sec4new_inner">
            <div className="hm_heading hm_heading_big">
              <h5> Why Rista?</h5>
              <h3>Create a unique online presence that reflects your brand.</h3>

              <div className="dordr_whysec_pics">
                {/* <span className="left_img"></span>
                <span className="right_img"></span> */}
                {/* <div className="whyrow">
                  <div className="wide-img">
                    <img className="why-imgg" src={require("../../images/brand-long.png")}></img>
                  </div>
                  <div className="long-img">
                    <img className="why-imgg" src={require("../../images/brand-small.png")}></img>
                  </div>
                </div> */}
                <div class="catalog-slider">
                  <div className="owl-slider">
                    <OwlCarousel
                      items={4}
                      className="owl-theme"
                      loop={true}
                      autoplay={true}
                      nav={false}
                      dots={false}
                      margin={32}
                      responsive={{
                        0: {
                          items: 2,
                          margin: 16,
                          stagePadding: 70,
                          loop: true,
                        },
                        480: {
                          items: 2,
                          margin: 16,
                          stagePadding: 100,
                          loop: true,
                        },
                        600: { items: 4 },
                        1025: { items: 4 },
                      }}
                    >
                      <div className="item">
                        <div className="catalog-pic">
                          <img
                            alt="restaurant online food ordering system showcasing top items ordered by customers"
                            title="restaurant online food ordering system showcasing top items ordered by customers"
                            src={require("../../images/direct-ordering-webp/1-Improve-your-brand.webp")}
                          ></img>
                        </div>
                      </div>
                      <div className="item">
                        <div className="catalog-pic">
                          <img
                            alt="showcasing food category tandoori platters on dot commerce online food ordering system"
                            title="showcasing food category tandoori platters on dot commerce online food ordering system"
                            src={require("../../images/direct-ordering-webp/2_Improve-your_brand.webp")}
                          ></img>
                        </div>
                      </div>
                      <div className="item">
                        <div className="catalog-pic">
                          <img
                            alt="Deals for customers in Online food ordering system for restaurants"
                            title="Deals for customers in Online food ordering system for restaurants"
                            src={require("../../images/direct-ordering-webp/3_Improve_your_brand.webp")}
                          ></img>
                        </div>
                      </div>
                      <div className="item">
                        <div className="catalog-pic">
                          <img
                            alt="4_Improve_your_brand.webp"
                            title="4_Improve_your_brand.webp"
                            src={require("../../images/direct-ordering-webp/4_Improve_your_brand.webp")}
                          ></img>
                        </div>
                      </div>
                    </OwlCarousel>
                  </div>
                </div>
              </div>
              <div className="dordr_whysec_infos">
                <p>
                  Unleash your vision! From casual cafes to fine dining
                  experiences, discover the perfect theme that ignites your
                  restaurant's unique story.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section four */}

      <section className="sec5new sec_space dordr_deliversec set_deliver_section">
        <div className="container">
          <div className="sec4new_inner">
            <div className="hm_heading hm_heading_big">
              <h3>Deliver customer experience that stands out</h3>
              <h4>
                {/* Instead make it -{" "} */}
                {/* <small>Deliver customer experience that stands out</small> */}
              </h4>
              <p>
                Restaurants saw a 70% increase{" "}
                <img src={require("../../images/till-arrow.png")}></img> in
                sales with our direct ordering platform.
              </p>
            </div>

            <div className="sec4newa_grid do_delivr_grid">
              <div className="row">
                <div className="col-md-4">
                  <div className="hm_whycards">
                    <div className="hm_whycard_icon">
                      {/* <span></span> */}
                      <img
                        className="square-imgg"
                        src={require("../../images/exp-1.png")}
                      ></img>
                    </div>
                    <div className="hm_why_infos">
                      <h4>Strong Brand Presence</h4>
                      <ul>
                        <li>
                          <strong>Branded Online Store:</strong> Create a unique
                          online presence that reflects your brand and provides
                          a seamless ordering experience.
                        </li>
                        <li>
                          <strong>Consistent Experience:</strong> Ensure a
                          cohesive, branded journey from order placement to
                          delivery.{" "}
                        </li>
                        <li>
                          <strong>WhatsApp Marketing:</strong> Utilize
                          personalized WhatsApp campaigns to engage customers
                          directly and reinforce brand identity.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="hm_whycards">
                    <div className="hm_whycard_icon">
                      {/* <span></span> */}
                      <img
                        className="square-imgg"
                        src={require("../../images/exp-2.png")}
                      ></img>
                    </div>
                    <div className="hm_why_infos">
                      <h4>Customer Relationships</h4>
                      <ul>
                        <li>
                          <strong>Personalized Service:</strong> Offer tailored
                          experiences that turn first-time buyers into repeat
                          customers.
                        </li>
                        <li>
                          <strong>Accurate Insights:</strong> Gather precise
                          customer data to understand preferences and behavior,
                          enabling targeted marketing efforts.
                        </li>
                        <li>
                          <strong>Comprehensive Marketing:</strong>{" "}
                          Strategically promote delivery options to in-store
                          patrons and suggest dine-in experiences to online
                          customers.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="hm_whycards">
                    <div className="hm_whycard_icon">
                      {/* <span></span> */}
                      <img
                        className="square-imgg"
                        src={require("../../images/exp-3.png")}
                      ></img>
                    </div>
                    <div className="hm_why_infos">
                      <h4>Improve Bottom Line</h4>
                      <ul>
                        <li>
                          <strong>Maximized Profits:</strong> Keep 100% of
                          revenue by eliminating third-party commission fees.
                        </li>
                        <li>
                          <strong>Operational Efficiency:</strong> Reduce
                          reliance on waitstaff, faster payment processing, and
                          efficient order handling.
                        </li>
                        <li>
                          <strong>Revenue Enhancement:</strong> Implement smart
                          recommendations for cross-selling and up-selling, and
                          utilize coupons to boost conversion rates
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="sec-5 set_whatsapptab_section deliver_accordians_mob">
              <div
                className="accordion accordion_rista"
                id="build-faq-accordian"
              >
                <div className="card">
                  <div className="card-header" id="faqhead2">
                    <a
                      href="#"
                      className="btn btn-header-link "
                      data-toggle="collapse"
                      data-target="#build-faq2"
                      aria-expanded="true"
                    >
                      Strong brand presence
                    </a>
                  </div>

                  <div
                    id="build-faq2"
                    className="collapse show"
                    data-parent="#build-faq-accordian"
                  >
                    <div className="card-body">
                      <p>
                        <strong>Branded online store:</strong> Delight customers
                        with personalised ordering experiences.
                      </p>
                      <p>
                        <strong>Consistent experience:</strong> Ensure a
                        cohesive, branded journey from order placement to
                        delivery.
                      </p>
                      <p>
                        <strong>WhatsApp marketing:</strong> Utilise
                        personalised WhatsApp campaigns to engage customers
                        directly and reinforce brand identity.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#build-faq3"
                      aria-expanded="false"
                    >
                      Customer Relationships
                    </a>
                  </div>

                  <div
                    id="build-faq3"
                    className="collapse"
                    data-parent="#build-faq-accordian"
                  >
                    <div className="card-body">
                      <p>
                        <strong>Personalized Service:</strong> Offer tailored
                        experiences that turn first-time buyers into repeat
                        customers.
                      </p>
                      <p>
                        <strong>Accurate Insights:</strong> Gather precise
                        customer data to understand preferences and behavior,
                        enabling targeted marketing efforts.
                      </p>
                      <p>
                        <strong>Comprehensive Marketing:</strong> Strategically
                        promote delivery options to in-store patrons and suggest
                        dine-in experiences to online customers.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#build-faq4"
                      aria-expanded="false"
                      aria-controls="faq4"
                    >
                      Improve Bottom Line
                    </a>
                  </div>

                  <div
                    id="build-faq4"
                    className="collapse"
                    data-parent="#build-faq-accordian"
                  >
                    <div className="card-body">
                      <p>
                        <strong>Maximized Profits:</strong> Keep 100% of revenue
                        by eliminating third-party commission fees.
                      </p>
                      <p>
                        <strong>Operational Efficiency:</strong> Reduce reliance
                        on waitstaff, faster payment processing, and efficient
                        order handling.
                      </p>
                      <p>
                        <strong>Revenue Enhancement:</strong> Implement smart
                        recommendations for cross-selling and up-selling, and
                        utilize coupons to boost conversion rates
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="doyunow">
              <div className="row">
                <div className="col-md-4">
                  <div className="dou_heading">Do you know?</div>
                </div>
                <div className="col-md-8">
                  <div className="douinfos">
                    <p>
                      <b>Customers spend an average of 27%* more</b> when
                      ordering directly from your website compared to offline
                      purchases.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="doyunow_starline">
              <p>
                <small>
                  *Internal reporting based on data shared by a large QSR
                  merchant.
                </small>
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* section five */}

      <section className="sec5new sec_space dordr_adjuestsec set_adjust_section">
        <div className="container">
          <div className="sec4new_inner">
            <div className="hm_heading hm_heading_big">
              <h5>
                Adjust your business to match how your customers prefer to
                engage.
              </h5>
              <p>
                59% of restaurant orders from millennials are takeout or
                delivery.
              </p>
            </div>

            <div className="adjust_grid">
              <div className="row">
                <div className="col-md-6">
                  <div className="dordr_adjust_left">
                    <div className="dordr_adjust_list">
                      <h4>Pick-Up</h4>
                      <p>
                        Give customers the convenience of in-person order
                        pickup, including curbside options, at no extra cost.
                      </p>
                      {/* <NavLink to="/">Learn More</NavLink> */}
                    </div>
                    <div className="dordr_adjust_list">
                      <h4>Delivery (DaaS)</h4>
                      <p>
                        Experience automatic dispatching when orders are placed
                        and eliminate the scope of manual errors. Say goodbye to
                        marketplace commissions, or opt for in-house delivery
                        for better control.
                      </p>
                      {/* <NavLink to="/">Learn More</NavLink> */}
                    </div>
                    <div className="dordr_adjust_list">
                      <h4>Dine-In</h4>
                      <p>
                        Enhance your service with QR code ordering, even with
                        limited staff. Generate personalized QR codes for every
                        table, enabling customers to effortlessly place multiple
                        orders, open tabs, and make payments directly from their
                        smartphones.
                      </p>
                      {/* <NavLink to="/">Learn More</NavLink> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="dordr_adjust_right">
                    <div className="dordr_img">
                      {/* <span></span> */}
                      <img
                        className="prefer-imgg"
                        alt="Track your order with in the Online food ordering system for restaurant"
                        title="Track your order with in the Online food ordering system for restaurant"
                        src={require("../../images/direct-ordering-webp/Adjust-your-mobile.webp")}
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section six */}

      <section className="sec-2 direct_sec2 set_second_logo_section">
        <div className="container">
          <div className="direct_brand2logos">
            <BrandLogo />
          </div>
          <p className="para hmpara_btm">
            {/* <small>Available integrations with all</small> delivery partners &
            payments avenues.  */}
            <small>Integrate with</small> trusted delivery partners and a
            variety of payment avenues.
          </p>
        </div>
      </section>

      {/* section six two */}

      <section className="footer-bar-up set_fbar_up_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <h2 className="subheading">
                Expand customer base, increase order volume.
              </h2>
              <h3 className="heading">
                We handle the small details, so you don't have to.
              </h3>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="right-side">
                {/* <h4>Schedule your free demo today</h4> */}
                <h5>
                  We will reach out within 24 hours to schedule your slot.
                </h5>
                {/* <form action="/action_page.php"> */}
                <form>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Mobile Number"
                      ref={footerMobileRef}
                      name="mobileNumberfooter"
                      value={footerMobile}
                      onChange={handleChangeFooterForm}
                      maxLength={10}
                    />
                    {hasFooterMobileErrorText && (
                      <span className="error" style={{ color: "red" }}>
                        {hasFooterMobileErrorText}
                      </span>
                    )}
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary bt-form"
                    onClick={() => {
                      if (validateFooterMobileForm()) {
                        setHasFooterMobileErrorText("");
                        openModal1();
                      }
                    }}
                  >
                    Get a Demo
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section seven */}

      <section className="sec-8 faq_btm set_faq_section">
        <div className="container">
          <h2 className="subheading">Frequently asked questions</h2>
          <div className="row mid">
            <div className="col-lg-10 col-md-12 col-sm-12">
              <div
                className="accordion accordion_rista accordion_rista2 accordion_rista3"
                id="faqaccord6"
              >
                <div className="card">
                  <div className="card-header" id="faqhead5">
                    <a
                      href="#"
                      className="btn btn-header-link"
                      data-toggle="collapse"
                      data-target="#faq4"
                      aria-expanded="true"
                      aria-controls="faq4"
                    >
                      What is a direct ordering platform?
                    </a>
                  </div>

                  <div
                    id="faq4"
                    className="collapse show"
                    aria-labelledby="faqhead4"
                    data-parent="#faqaccord6"
                  >
                    <div className="card-body">
                      <p>
                        A direct ordering platform is an online system that
                        allows customers to place orders directly with a
                        restaurant through its website or app, rather than using
                        third-party delivery services. This platform streamlines
                        the ordering process by enabling customers to view the
                        menu, customize their orders, and make payments
                        directly. It typically includes features for order
                        management and payment processing, and sometimes
                        integrates with the restaurant's existing point-of-sale
                        (POS) system.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqhead5">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq5"
                      aria-expanded="true"
                      aria-controls="faq5"
                    >
                      How to implement direct ordering at my restaurant?
                    </a>
                  </div>

                  <div
                    id="faq5"
                    className="collapse"
                    aria-labelledby="faqhead5"
                    data-parent="#faqaccord6"
                  >
                    <div className="card-body">
                      <p>
                        To implement direct ordering at your restaurant, follow
                        these steps:
                      </p>
                      <ul>
                        <li>
                          <strong>Choose a Direct Ordering Solution:</strong>{" "}
                          Select a direct ordering platform like that of RISTA,
                          that suits your restaurant's needs. Options include
                          standalone solutions or those offered by POS
                          providers.
                        </li>
                        <li>
                          <strong>Set Up the Platform:</strong> Work with the
                          provider to set up the platform, which includes
                          creating an online menu, configuring payment methods,
                          and integrating with your existing systems.
                        </li>
                        <li>
                          <strong>Integrate with POS:</strong> Ensure the
                          platform integrates with your POS system for seamless
                          order processing and inventory management.
                        </li>
                        <li>
                          <strong>Design and Customize:</strong> Customize the
                          interface to match your restaurant's branding,
                          including logos, colors, and images.
                        </li>
                        <li>
                          <strong>Promote the Service:</strong> Promote your new
                          direct ordering system to customers through marketing
                          campaigns, social media, and in-store signage.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqhead6">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq6"
                      aria-expanded="true"
                      aria-controls="faq6"
                    >
                      What are the advantages of using a direct ordering
                      platform?
                    </a>
                  </div>

                  <div
                    id="faq6"
                    className="collapse"
                    aria-labelledby="faqhead6"
                    data-parent="#faqaccord6"
                  >
                    <div className="card-body">
                      <ul>
                        <li>
                          <strong>Lower Costs:</strong> Eliminates high
                          commission fees associated with third-party delivery
                          services.
                        </li>
                        <li>
                          <strong>Increased Control:</strong> Gives restaurants
                          full control over their customer data, menus, pricing,
                          and promotions.
                        </li>
                        <li>
                          <strong>Enhanced Customer Experience:</strong>{" "}
                          Provides a seamless and branded ordering experience
                          for customers.
                        </li>
                        <li>
                          <strong>Improved Profit Margins:</strong> Allows
                          restaurants to retain more of their revenue by
                          avoiding third-party fees.
                        </li>
                        <li>
                          <strong>Data Insights:</strong> Access to customer
                          data and order history helps in creating targeted
                          marketing strategies and improving customer service.
                        </li>
                        <li>
                          <strong>Customization:</strong> Flexibility to offer
                          special promotions, discounts, and loyalty programs
                          directly to customers.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="faqhead7">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq7"
                      aria-expanded="true"
                      aria-controls="faq7"
                    >
                      Can the catalog and orders be integrated with my POS?
                    </a>
                  </div>

                  <div
                    id="faq7"
                    className="collapse"
                    aria-labelledby="faqhead7"
                    data-parent="#faqaccord6"
                  >
                    <div className="card-body">
                      <p>
                        Yes, most direct ordering platforms offer integration
                        with various POS systems. This integration allows for
                        automatic synchronization of your menu, prices, and
                        inventory, ensuring that orders placed online are
                        directly entered into your POS system. This helps
                        streamline operations, reduce manual entry errors, and
                        keep inventory levels accurate.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="faqhead8">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq8"
                      aria-expanded="true"
                      aria-controls="faq8"
                    >
                      What all payment modes are supported with QR ordering?
                    </a>
                  </div>

                  <div
                    id="faq8"
                    className="collapse"
                    aria-labelledby="faqhead8"
                    data-parent="#faqaccord6"
                  >
                    <div className="card-body">
                      <p>
                        QR ordering typically supports a variety of payment
                        modes, including:
                      </p>
                      <ul>
                        <li>
                          <strong>Credit/Debit Cards:</strong> Customers can pay
                          using their Visa, MasterCard, American Express, and
                          other major credit or debit cards.
                        </li>
                        <li>
                          <strong>Digital Wallets:</strong> Options like Apple
                          Pay, Google Wallet, and Samsung Pay are often
                          supported.
                        </li>
                        <li>
                          <strong>Mobile Payment Apps:</strong> Payments through
                          popular mobile apps like PayPal, Venmo, and others.
                        </li>
                        <li>
                          <strong>Bank Transfers:</strong> Direct bank transfers
                          or payments through banking apps.
                        </li>
                        <li>
                          <strong>Cash on Delivery:</strong> For customers who
                          prefer to pay in cash upon receiving their order
                          (though this is less common for QR ordering).
                        </li>
                        <li>
                          <strong>Other Payment Gateways:</strong> Integration
                          with various payment gateways that support multiple
                          payment methods.
                        </li>
                      </ul>
                      <p>
                        Supporting multiple payment modes enhances convenience
                        for customers and can lead to higher satisfaction and
                        increased sales.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ban-bt">
                <NavLink onClick={openModal1}>Try Now</NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />

      <div className="personal_modal_new business_modal_new">
        <Modal
          isOpen={modalIsOpen1}
          onRequestClose={closeModal1}
          // onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          className="customModalContent"
        >
          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2> */}
          {/* <button onClick={closeModal}>close</button> */}
          <div className="dotpe_popouter dotpe_popouter_new">
            <span
              className="closebtn_new"
              onClick={() => {
                closeModal1();
              }}
            >
              <img src={require("../../images/close-cross.png")} alt="Logo" />
            </span>
            <div className="row dotpe_popup dotpe_popup2 dotpe_popup3">
              <div className="dotpe_left col-md-5">
                <div className="dotpe_left_infos">
                  <h3>You are taking a right step for your business.</h3>
                  <h4>Trusted by 75 lakh+ businesses in india</h4>
                  <div className="dotpe_poplogo">
                    <img src={require("../../images/logo-w.png")} alt="Logo" />
                  </div>
                </div>
              </div>
              <div className="dotpe_right  col-md-7">
                <div className="dotpe_right_infos">
                  <div className="busi_loan_form">
                    <form>
                      <div className="form_heading">
                        <h3>Setup Free Demo</h3>
                        <p>
                          Fill in the details & our product specialist will
                          reach out to you
                        </p>
                      </div>
                      <div className={formClassName}>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          ref={inputName}
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                        />
                        {formErrors.name && (
                          <span className="error">{formErrors.name}</span>
                        )}
                      </div>
                      <div className={formClassName}>
                        {/* <div>
                          <select
                            className="form-control"
                            // value={formData.countryCode}
                            // onChange={handleChange}
                            // name="countryCode"
                          >
                              <option >
                                +91
                              </option>
                          </select>
                        </div> */}
                        <div className="mobilenum">
                          <span>+91</span>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="Mobile Number"
                            ref={inputNumber}
                            name="mobileNumber"
                            value={
                              footerMobile != ""
                                ? (formData.mobileNumber = footerMobile)
                                : formData.mobileNumber
                            }
                            onChange={handleChange}
                            maxLength={10}
                          />
                          {formErrors.mobileNumber && (
                            <span className="error">
                              {formErrors.mobileNumber}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className={formClassName}>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email Address"
                          ref={inputEmail}
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                        {formErrors.email && (
                          <span className="error">{formErrors.email}</span>
                        )}
                      </div>

                      <div className="form_click">
                        By clicking on continue, I hereby provide my consent to
                        receive emails, phone calls, messages and other
                        communications from DotPe to understand about DotPe's
                        services, offerings, promotions and other related
                        information.
                      </div>

                      <div className="formlead_btn">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            if (validateSecondForm()) {
                              validateAndOpenModal2();
                            }
                          }}
                          // onClick={validateAndOpenModal2}
                        >
                          Request Demo
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>

      <div className="personal_modal_new business_modal_new">
        <Modal
          isOpen={modalIsOpen2}
          onRequestClose={closeModal2}
          style={customStyles}
          contentLabel="Example Modal"
          className="customModalContent"
        >
          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2> */}
          {/* <button onClick={closeModal}>close</button> */}
          <div className="dotpe_popouter dotpe_popouter_new">
            <span
              className="closebtn_new"
              onClick={() => {
                closeModal2();
              }}
            >
              <img src={require("../../images/close-cross.png")} alt="Logo" />
            </span>
            <div className="row dotpe_popup dotpe_popup2 dotpe_popup3">
              <div className="dotpe_left col-md-5 dotpe_left2_mode">
                <div className="dotpe_left_infos  dotpe_left2_mode_infos">
                  <div className="dotpe_popcheck">
                    <img src={successcheck2} alt="Icon" />
                  </div>
                  <h4>You have successfully scheduled a call with us.</h4>
                  <div className="dotpe_poplogo">
                    <img src={require("../../images/logo-w.png")} alt="Logo" />
                  </div>
                </div>
              </div>
              <div className="dotpe_right  col-md-7">
                <div className="dotpe_right_infos">
                  <div className="busi_loan_form">
                    <form>
                      <div className="form_heading">
                        <h3>
                          Our team will reach out to you in next few minutes
                        </h3>
                        <p>Meanwhile tell us more about your 1 business day.</p>
                      </div>

                      <div className="form-group form_group_nice">
                        <label>Select a business category</label>
                        <NiceSelect
                          id="a-select"
                          placeholder="Select..."
                          className="sampleClass"
                          name="incomeSource"
                          value={formData.incomeSource}
                          onChange={handleChange}
                        >
                          <option value="Food and beverage">
                            Food and beverage
                          </option>
                          <option value="Clothing and accessories">
                            Clothing and accessories
                          </option>
                          <option value="Bakery">Bakery</option>
                          <option value="Salon">Salon</option>
                          <option value="Home Decor and furnishing">
                            Home Decor and furnishing
                          </option>
                          <option value="eCommerce">eCommerce</option>
                          <option value="Services">Services</option>
                        </NiceSelect>
                        {formErrors.incomeSource && (
                          <span className="error">
                            {formErrors.incomeSource}
                          </span>
                        )}
                      </div>
                      <div className="form-group form_group_nice form_group_nice2">
                        <label>Select your role in this business.</label>
                        <NiceSelect
                          id="a-select1"
                          placeholder="Select..."
                          className="sampleClass"
                          name="role"
                          value={formData.role}
                          onChange={handleChange}
                        >
                          <option value="Owner">Owner</option>
                          <option value="Store Manager">Store Manager</option>
                          <option value="Accounts">Accounts</option>
                          <option value="Sales">Sales</option>
                        </NiceSelect>
                        {formErrors.role && (
                          <span className="error">{formErrors.role}</span>
                        )}
                      </div>
                      <div className="formlead_btn formlead_btn2">
                        <button
                          type="button"
                          className="btn btn-primary"
                          // onClick={() => {
                          //   if (validateSecondForm()) {
                          //     saveDetails();
                          //   }
                          // }}
                          onClick={saveDetails}
                        >
                          Done
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default RestaurantDirectOrderingSystem;
