import React, { useState, useRef } from 'react';
import Footer from '../common/Footer';
import Header from '../common/Header';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import whatsicon from '../../images/whatsicon.svg';
import whatsstar from '../../images/Star1.svg';
// import { NavLink } from "react-router-dom";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Modal from 'react-modal';
import NiceSelect from '../../NiceSelect/NiceSelect';
import successcheck2 from '../../images/success-check2.svg';
import BrandLogo from "../common/BrandLogo";
import { Helmet } from 'react-helmet-async';


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1,
  },
};

const WhatsappBusinessApi = () => {
  const location = useLocation();
  const resetForms = () => {
    setFormData({
      name: "",
      mobileNumber: "",
      pincode: "",
      incomeSource: "",
      buss_age: "",
      buss_turnover: "",
      email: "",
    });
  };

  const [firstFormId, setFirstFormId] = useState("");
  //  const [selectedImage, setSelectedImage] = useState(
  //    require("../../images/whatsapp-buisness/Connect_directly_&_drive_sales.webp")
  //  );
  //  const handleCardClick = (imageSrc) => {
  //    setSelectedImage(imageSrc);
  //  };

  const [selectedImage, setSelectedImage] = useState({
    src: require("../../images/whatsapp-buisness/Connect_directly_&_drive_sales.webp"),
    alt: "Send Promotional & Transactional Messages with WhatsApp Marketing by Dotpe",
    title:
      "Send Promotional & Transactional Messages with WhatsApp Marketing by Dotpe",
  });

  const handleCardClick = (imageSrc, title, alt) => {
    setSelectedImage({
      src: imageSrc,
      title: title,
      alt: alt,
    });
  };
  const [selectedImages, setSelectedImages] = useState({
    src: require("../../images/whatsapp-buisness/Cohort_Creation.webp"),
    alt: "Send Promotional & Transactional Messages with WhatsApp Marketing by Dotpe",
    title:
      "Send Promotional & Transactional Messages with WhatsApp Marketing by Dotpe",
  });

  const handleCardClicks = (imageSrc, title, alt) => {
    setSelectedImages({
      src: imageSrc,
      title: title,
      alt: alt,
    });
  };

  const [hasError, setHasError] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    countryCode: "+91",
    mobileNumber: "",
    pincode: "",
    incomeSource: "",
    buss_age: "",
    buss_turnover: "",
    email: "",
  });
  const [formErrors, setFormErrors] = useState({
    name: "",
    mobileNumber: "",
    pincode: "",
    incomeSource: "",
    buss_age: "",
    buss_turnover: "",
    email: "",
  });
  const inputName = useRef(null);
  const inputNumber = useRef(null);
  const inputEmail = useRef(null);
  //const countryCodes = ["+91", "+1", "+44", "+61", "+81", "+86"];
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [modalIsOpen2, setModalIsOpen2] = useState(false);

  const openModal1 = () => {
    setModalIsOpen1(true);
  };

  const openModal2 = () => {
    setModalIsOpen2(true);
    setModalIsOpen1(false);
  };

  const closeModal1 = () => {
    setModalIsOpen1(false);
  };

  const closeModal2 = () => {
    setModalIsOpen2(false);
  };

  const [emailSuggestions, setEmailSuggestions] = useState([]);
  const domains = ["gmail.com", "yahoo.com", "hotmail.com"];
  const validateSecondForm = () => {
    const errors = {};
    let formIsValid = true;
    const nameRegex = /^[A-Za-z\s]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const numberRegex = /^\d+$/;

    if (!formData.name || !formData.name.trim()) {
      formIsValid = false;
      errors.name = "Name is required";
    } else if (formData.name.trim().length < 3) {
      formIsValid = false;
      errors.name = "Name should have at least 3 characters";
    } else if (formData.name.trim().length > 30) {
      formIsValid = false;
      errors.name = "Name should have at max 30 characters";
    } else if (!nameRegex.test(formData.name.trim())) {
      formIsValid = false;
      errors.name = "Name should contain only letters and spaces";
    }

    if (!formData.email || !formData.email.trim()) {
      formIsValid = false;
      errors.email = "Email is required";
    } else if (!emailRegex.test(formData.email)) {
      formIsValid = false;
      errors.email = "Invalid email address";
    }

    if (!formData.mobileNumber || !formData.mobileNumber.trim()) {
      formIsValid = false;
      errors.mobileNumber = "Mobile Number is required";
    } else if (!numberRegex.test(formData.mobileNumber)) {
      formIsValid = false;
      errors.mobileNumber = "Invalid mobile number";
    } else if (formData.mobileNumber.trim().length !== 10) {
      formIsValid = false;
      errors.mobileNumber = "Mobile number must be exactly 10 digits";
    } else {
      // Ensure mobile number starts with 6, 7, 8, or 9
      const firstDigit = formData.mobileNumber.trim().charAt(0);
      if (!["6", "7", "8", "9"].includes(firstDigit)) {
        formIsValid = false;
        errors.mobileNumber = "Mobile number must start with 6, 7, 8, or 9";
      }
    }

    setFormErrors(errors);
    setHasError(!formIsValid);

    return formIsValid;
  };

  const handleSuggestionClick = (suggestion) => {
    setFormData((prevData) => ({
      ...prevData,
      ["email"]: suggestion,
    }));
    setEmailSuggestions([]);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    if (name === "name") {
      if (value === "" || /^[^\s]/.test(value)) {
        newValue = value;
      } else {
        newValue = value.trimStart();
      }
    } else if (name === "mobileNumber") {
      newValue = value.replace(/\D/g, "");

      if (newValue.length > 10) {
        newValue = newValue.slice(0, 10);
      }
    } else if (name === "email") {
      if (value.includes("@")) {
        const [localPart, domainPart] = value.split("@");
        setEmailSuggestions(
          domains
            .filter((domain) => domain.startsWith(domainPart))
            .map((domain) => `${localPart}@${domain}`)
        );
      } else {
        setEmailSuggestions([]);
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };
  const formClassName = hasError ? "form-error form-group" : "form-group";
  const validateAndOpenModal2 = async () => {
    if (await validateSecondForm()) {
      const apiUrl = process.env.REACT_APP_FORM_ONE;
      const combinedData = {
        name: formData.name,
        phone: formData.mobileNumber,
        email: formData.email,
        source: window.location.href,
      };
      localStorage.setItem("mobile", formData.mobileNumber);
      console.log(combinedData);

      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json; charset=utf-8",
          },
          body: JSON.stringify(combinedData),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data);
        setFirstFormId(data.id);
        openModal2();
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  // const saveDetails = () => {
  //   closeModal2();
  // };
  const navigate = useNavigate();
  const validateForm = () => {
    const errors = {};
    let formIsValid = true;

    if (!formData.incomeSource || formData.incomeSource === "0") {
      formIsValid = false;
      errors.incomeSource = "Please select a business category";
    }

    if (!formData.role || formData.role === "0") {
      formIsValid = false;
      errors.role = "Please select your role";
    }

    setFormErrors(errors);
    return formIsValid;
  };
  const saveDetails = async () => {
    const isValid = validateForm();

    if (isValid) {
      const apiUrl = process.env.REACT_APP_FORM_ONE + "/" + firstFormId;
      const combinedData = {
        businessCategory: formData.incomeSource,
        role: formData.role,
      };
      console.log(combinedData);

      try {
        const response = await fetch(apiUrl, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(combinedData),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data);
        resetForms();
        closeModal2();
        navigate("/thankyou");
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
  const [footerMobile, setFooterMobile] = useState("");
  const footerMobileRef = useRef(null);
  const [hasFooterMobileError, setHasFooterMobileError] = useState(false);
  const [hasFooterMobileErrorText, setHasFooterMobileErrorText] = useState("");
  const handleChangeFooterForm = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    if (name === "mobileNumberfooter") {
      console.log(newValue.length);
      newValue = value.replace(/\D/g, "");

      if (newValue.length > 10) {
        newValue = newValue.slice(0, 10);
      }
    }
    setFooterMobile(newValue);
  };
  const validateFooterMobileForm = () => {
    let formIsValid = true;
    const numberRegex = /^\d+$/;
    if (!footerMobile || !footerMobile.trim()) {
      formIsValid = false;
      setHasFooterMobileErrorText("Mobile Number is required");
    } else if (!numberRegex.test(footerMobile)) {
      formIsValid = false;
      setHasFooterMobileErrorText("Invalid mobile number");
    } else if (footerMobile.trim().length !== 10) {
      formIsValid = false;
      setHasFooterMobileErrorText("Mobile number must be exactly 10 digits");
    } else {
      const firstDigit = footerMobile.trim().charAt(0);
      if (!["6", "7", "8", "9"].includes(firstDigit)) {
        formIsValid = false;
        setHasFooterMobileErrorText(
          "Mobile number must start with 6, 7, 8, or 9"
        );
      }
    }
    setHasFooterMobileError(!formIsValid);

    return formIsValid;
  };
  return (
    <div>
      <Helmet>
        <title>
        WhatsApp Business API | Elevate Restaurant Customer Engagement
        </title>
        <meta
          name="description"
          content="Boost your brand visibility with WhatsApp Business API, Connect meaningfully with your restaurant customers"
        />
        <meta
          name="keywords"
          content="whatsapp api, whatsapp bulk message sender, whatsapp marketing, whatsapp chatbot, green tick on whatsapp"
        />
        <meta name="author" content="Rista Apps" />
        <meta
          property="og:description"
          content="Boost your brand visibility with WhatsApp Business API, Connect meaningfully with your restaurant customers"
        />
        <meta
          property="og:image"
          content={require("../../images/rista_logo3.svg")}
        />
      </Helmet>
      <Header />

      <section className="banner banner_home banner_noimage banner_direct_ordering set_banner set_whatsapp_banner_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
              <div className="banner-content">
                <span className="banner-subheading home_banner_subheading">
                  Whatsapp for Business
                </span>
                <h1 className="banner-heading">
                  {/* Engage, Delight, and <i class="green-clr">Grow</i>
                  <span>with WhatsApp Marketing</span> */}
                  Engage, delight, <br />
                  and <span>grow.</span>
                </h1>
                <div className="banner-content hm_banner_desk">
                  <h2 className="banner-para">
                    Transform customer engagement with instant communication,
                    personalized service, and easy order management—all through
                    WhatsApp Business, making your restaurant more responsive
                    and connected than ever before.
                  </h2>
                  <div className="ban-bt">
                    <NavLink className="hm_fillbtn" onClick={openModal1}>
                      Get a Demo
                    </NavLink>
                    {/* <a class="ban-second-cta" href="#" >Know How/Learn More</a> */}
                  </div>
                  <div className="banner-mobile">
                    <div className="ban-last">
                      <img
                        className="ban-last-img"
                        src={require("../../images/star.png")}
                      ></img>{" "}
                      Top 20 Technology Startups <span>LinkedIn, 2023</span>
                    </div>
                    <div className="ban-last ban-last-1">
                      <img
                        className="ban-last-img"
                        src={require("../../images/star.png")}
                      ></img>{" "}
                      India’s Best POS <span>ET Hospitality World, 2022</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
              <div className="banner-image hmbanner_img">
                {/* <span></span>
                <span className="hmbanner_img2"></span> */}
                <img
                  className="banr-img pc-imag"
                  alt="Scale your marketing and send bulk messages with WhatsApp Marketing Solution by Dotpe"
                  title="Scale your marketing and send bulk messages with WhatsApp Marketing Solution by Dotpe"
                  src={require("../../images/whatsapp-buisness/Main_WABA.webp")}
                ></img>
                <img
                  className="banr-img mobile-imag"
                  alt="Scale your marketing and send bulk messages with WhatsApp Marketing Solution by Dotpe"
                  title="Scale your marketing and send bulk messages with WhatsApp Marketing Solution by Dotpe"
                  src={require("../../images/whatsapp-buisness/Main_WABA.webp")}
                ></img>
              </div>

              <div className="banner-content banner_contt_mob">
                <p className="banner-para">
                  Transform customer engagement with instant communication,
                  personalized service, and easy order management—all through
                  WhatsApp Business, making your restaurant more responsive and
                  connected than ever before.
                </p>
                <div className="ban-bt">
                  <a class="ban-cta" href="#">
                    Get Started
                  </a>
                  {/* <a class="ban-second-cta" href="#" >Know How/Learn More</a> */}
                </div>
                <div className="banner-mobile">
                  <div className="ban-last">
                    <img
                      className="ban-last-img"
                      src={require("../../images/star.png")}
                    ></img>{" "}
                    Top 20 Technology Startups <span>LinkedIn, 2023</span>
                  </div>
                  <div className="ban-last ban-last-1">
                    <img
                      className="ban-last-img"
                      src={require("../../images/star.png")}
                    ></img>{" "}
                    India’s Best POS <span>ET Hospitality World, 2022</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-2 direct_sec2 set_logo_section set_whatsapp_logo_section">
        <div className="container">
          <p className="para">
            Thousands <small>of restaurants</small> run and grow{" "}
            <img src={require("../../images/till-arrow.png")}></img>{" "}
            <small>on</small> RISTA,
            <small> every day.</small>
          </p>
          <BrandLogo />
        </div>
      </section>

      <section className="sec3new sec_space direct_ordr_most whats_customr set_customer_monial">
        <div className="container monial-container-p0">
          <div className="sec3new_inner">
            <div className="hm_heading hm_heading_big">
              <h3>What customers say about us?</h3>
            </div>
            <div className="sec-6 deordr_owl_slides">
              <div className="owl-slider">
                <OwlCarousel
                  items={4}
                  className="owl-theme"
                  autoplay={false}
                  nav={false}
                  // loop={true}
                  dots={false}
                  margin={24}
                  responsive={{
                    0: { items: 1, stagePadding: 45, margin: 16 }, // Set 1 item for small screens (width < 600px)
                    600: { items: 1, stagePadding: 45, margin: 16 }, // Set 2 items for medium screens (600px <= width < 960px)
                    800: { items: 2 }, // Set 4 items for larger screens (width >= 960px)
                    1025: { items: 2 }, // Set 4 items for larger screens (width >= 960px)
                  }}
                >
                  <div className="item">
                    <div className="customers_starbox">
                      <div className="whats_star">
                        <span>
                          {" "}
                          <img src={whatsstar} alt="Icon" />
                        </span>
                        <span>
                          {" "}
                          <img src={whatsstar} alt="Icon" />
                        </span>
                        <span>
                          {" "}
                          <img src={whatsstar} alt="Icon" />
                        </span>
                        <span>
                          {" "}
                          <img src={whatsstar} alt="Icon" />
                        </span>
                        <span>
                          {" "}
                          <img src={whatsstar} alt="Icon" />
                        </span>
                      </div>
                      <div class="whatsdegi-name">
                        <p>
                          EatFit has partnered with DotPe for our cloud kitchen
                          business & we are extremely happy with what we have
                          seen. The team is extremely helpful & helped our us
                          scale up on various areas on the tool especially the
                          "whatsapp for business" which has helped us increase
                          our revenue significantly.
                        </p>
                      </div>
                      <div class="degi-name">
                        <h5>Ankit Nagori</h5>
                        <p>Founder, Eatfit</p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="customers_starbox">
                      <div className="whats_star">
                        <span>
                          {" "}
                          <img src={whatsstar} alt="Icon" />
                        </span>
                        <span>
                          {" "}
                          <img src={whatsstar} alt="Icon" />
                        </span>
                        <span>
                          {" "}
                          <img src={whatsstar} alt="Icon" />
                        </span>
                        <span>
                          {" "}
                          <img src={whatsstar} alt="Icon" />
                        </span>
                        <span>
                          {" "}
                          <img src={whatsstar} alt="Icon" />
                        </span>
                      </div>
                      <div class="whatsdegi-name">
                        <p>
                          At Go Nirwana, DotPe’s integrated WhatsApp Business
                          Platform has been a game-changer in our mission to
                          make a real difference in society. Together, we're
                          creating a significant impact for our furry friends in
                          need.
                        </p>
                      </div>
                      <div class="degi-name">
                        <h5>Kumar Shantanu</h5>
                        <p>Founder, Go Nirvana Foundation</p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="customers_starbox">
                      <div className="whats_star">
                        <span>
                          {" "}
                          <img src={whatsstar} alt="Icon" />
                        </span>
                        <span>
                          {" "}
                          <img src={whatsstar} alt="Icon" />
                        </span>
                        <span>
                          {" "}
                          <img src={whatsstar} alt="Icon" />
                        </span>
                        <span>
                          {" "}
                          <img src={whatsstar} alt="Icon" />
                        </span>
                        <span>
                          {" "}
                          <img src={whatsstar} alt="Icon" />
                        </span>
                      </div>
                      <div class="whatsdegi-name">
                        <p>
                          DotPe's partnership has been a bridge between our
                          brand & our audience.Their user-friendly & effective
                          system has revolutionised our engagement strategies,
                          helping us connect with our customers like never
                          before.
                        </p>
                      </div>
                      <div class="degi-name">
                        <h5>Pranshul Yadav</h5>
                        <p>Founder, Frozen Bottle</p>
                      </div>
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section three */}

      <section className="sec-5 whatstabimg_sec set_whatsapptab_section">
        <div className="container">
          <div className="hm_heading hm_heading_big hm_heading_blue">
            <h3>
              Build Lasting Relationships. <br />
              Drive Repeat Business.
            </h3>
          </div>

          <div className="row mid">
            <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12 mobile-pad-none">
              <div
                className="accordion accordion_rista"
                id="build-faq-accordian"
              >
                <div className="card">
                  <div className="card-header" id="faqhead2">
                    <a
                      href="#"
                      className="btn btn-header-link "
                      data-toggle="collapse"
                      data-target="#build-faq2"
                      aria-expanded="true"
                      // onClick={() =>
                      //   handleCardClick(
                      //     require("../../images/whatsapp-buisness/Connect_directly_&_drive_sales.webp")
                      //   )
                      // }
                      onClick={() =>
                        handleCardClick(
                          require("../../images/whatsapp-buisness/Connect_directly_&_drive_sales.webp"),
                          "Send Promotional & Transactional Messages with WhatsApp Marketing by Dotpe",
                          "Send Promotional & Transactional Messages with WhatsApp Marketing by Dotpe"
                        )
                      }
                    >
                      Connect Directly
                    </a>
                  </div>

                  <div
                    id="build-faq2"
                    className="collapse show"
                    data-parent="#build-faq-accordian"
                  >
                    <div className="card-body">
                      <strong>Cut through the noise!</strong> Reach your
                      customers directly on their favorite platform – WhatsApp.
                      Share menu updates, special offers, and promotions to
                      build stronger relationships and boost sales.
                      <div className="sec-5-img show-1">
                        {/* <img src={require("../../images/sec-5-img.png")}></img> */}
                        {selectedImage && (
                          <div className="selected-image ">
                            {/* <img
                              src={selectedImage}
                              alt="Send Promotional & Transactional Messages with WhatsApp Marketing by Dotpe"
                              title="Send Promotional & Transactional Messages with WhatsApp Marketing by Dotpe"
                            /> */}
                            <img
                              src={selectedImage.src}
                              alt={selectedImage.alt}
                              title={selectedImage.title}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#build-faq3"
                      aria-expanded="false"
                      // onClick={() =>
                      //   handleCardClick(
                      //     require("../../images/whatsapp-buisness/Seamless_ordering_&_reservations.webp")
                      //   )
                      // }
                      onClick={() =>
                        handleCardClick(
                          require("../../images/whatsapp-buisness/Seamless_ordering_&_reservations.webp"),
                          "Get reservations for your fine dine restaurant with WhatsApp Business by Dotpe",
                          "Get reservations for your fine dine restaurant with WhatsApp Business by Dotpe1"
                        )
                      }
                    >
                      Engage and Interact Better
                    </a>
                  </div>

                  <div
                    id="build-faq3"
                    className="collapse"
                    data-parent="#build-faq-accordian"
                  >
                    <div className="card-body">
                      <strong>Go beyond text!</strong> Utilize the power of
                      images, videos, audio, documents, emojis, and stickers to
                      personalize your communication. Increase engagement with
                      buttons, picklists, and rich content, making your customer
                      experience truly unique.
                      <div className="sec-5-img show-1">
                        {/* <img src={require("../../images/sec-5-img.png")}></img> */}
                        {selectedImage && (
                          <div className="selected-image ">
                            {/* <img
                              src={selectedImage}
                              alt="Get reservations for your fine dine restaurant with WhatsApp Business by Dotpe"
                              title="Get reservations for your fine dine restaurant with WhatsApp Business by Dotpe"
                            /> */}
                            <img
                              src={selectedImage.src}
                              alt={selectedImage.alt}
                              title={selectedImage.title}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#build-faq4"
                      aria-expanded="false"
                      aria-controls="faq4"
                      // onClick={() =>
                      //   handleCardClick(
                      //     require("../../images/whatsapp-buisness/Capture_valuable_feedback_turn_it_into_gold.webp")
                      //   )
                      // }
                      onClick={() =>
                        handleCardClick(
                          require("../../images/whatsapp-buisness/Capture_valuable_feedback_turn_it_into_gold.webp"),
                          "Capture your customer feedback on WhatsApp",
                          "Capture your customer feedback on WhatsApp"
                        )
                      }
                    >
                      Laser-focused Targeting
                    </a>
                  </div>

                  <div
                    id="build-faq4"
                    className="collapse"
                    data-parent="#build-faq-accordian"
                  >
                    <div className="card-body">
                      <strong>Segmentation Magic!</strong> Don't blast generic
                      messages. Segment your audience based on preferences
                      (dietary restrictions, favorite dishes) and past orders.
                      Target customers within a specific delivery radius,
                      promoting special offers relevant to their location.
                      <div className="sec-5-img show-1">
                        {/* <img src={require("../../images/sec-5-img.png")}></img> */}
                        {selectedImage && (
                          <div className="selected-image ">
                            {/* <img
                              src={selectedImage}
                              alt="Capture your customer feedback on WhatsApp"
                              title="Capture your customer feedback on WhatsApp"
                            /> */}
                            <img
                              src={selectedImage.src}
                              alt={selectedImage.alt}
                              title={selectedImage.title}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#build-faq5"
                      aria-expanded="false"
                      aria-controls=""
                      // onClick={() =>
                      //   handleCardClick(
                      //     require("../../images/whatsapp-buisness/Create_engaging_&_interactive_conversations.webp")
                      //   )
                      // }
                      onClick={() =>
                        handleCardClick(
                          require("../../images/whatsapp-buisness/Marketing.webp"),
                          "Capture your customer feedback on WhatsApp",
                          "Capture your customer feedback on WhatsApp"
                        )
                      }
                    >
                      Ditch Data Overload
                    </a>
                  </div>

                  <div
                    id="build-faq5"
                    className="collapse"
                    data-parent="#build-faq-accordian"
                  >
                    <div className="card-body">
                      <strong>Go beyond basic reports!</strong> Our
                      best-in-class reports are visual and easy to understand.
                      Track campaign performance, identify top-selling items,
                      and see which promotions resonate most. Analyze customer
                      interactions to understand their preferences and tailor
                      future marketing efforts for maximum impact.
                      <div className="sec-5-img show-1">
                        {/* <img src={require("../../images/sec-5-img.png")}></img> */}
                        {selectedImage && (
                          <div className="selected-image ">
                            {/* <img
                              src={selectedImage}
                              alt="Capture your customer feedback on WhatsApp"
                              title="Capture your customer feedback on WhatsApp"
                            /> */}
                            <img
                              src={selectedImage.src}
                              alt={selectedImage.alt}
                              title={selectedImage.title}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#build-faq6"
                      aria-expanded="false"
                      aria-controls=""
                      // onClick={() =>
                      //   handleCardClick(
                      //     require("../../images/whatsapp-buisness/Create_engaging_&_interactive_conversations.webp")
                      //   )
                      // }

                      onClick={() =>
                        handleCardClick(
                          require("../../images/whatsapp-buisness/Create_engaging_&_interactive_conversations.webp"),
                          "Send Transactional & Utility messages with WhatsApp marketing by Dotpe",
                          "Send Transactional & Utility messages with WhatsApp marketing by Dotpe"
                        )
                      }
                    >
                      Maximize Customer Service to Drive Orders
                    </a>
                  </div>

                  <div
                    id="build-faq6"
                    className="collapse"
                    data-parent="#build-faq-accordian"
                  >
                    <div className="card-body">
                      <strong>Get real-time support!</strong> Offer exceptional
                      customer service through live chat within WhatsApp. Answer
                      questions, address concerns, and take orders directly
                      through the chat, creating a seamless dining experience.
                      <div className="sec-5-img show-1">
                        {/* <img src={require("../../images/sec-5-img.png")}></img> */}
                        {selectedImage && (
                          <div className="selected-image ">
                            {/* <img
                              src={selectedImage}
                              alt="Send Transactional & Utility messages with WhatsApp marketing by Dotpe"
                              title="Send Transactional & Utility messages with WhatsApp marketing by Dotpe"
                            /> */}
                            <img
                              src={selectedImage.src}
                              alt={selectedImage.alt}
                              title={selectedImage.title}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12 hide-1">
              {selectedImage && (
                <div className="selected-image ">
                  <img
                    src={selectedImage.src}
                    alt={selectedImage.alt}
                    title={selectedImage.title}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* section six two */}
      <section className="sec_space whats_connect set_whatsapp_brand_section">
        <div className="container">
          <div className="hm_heading">
            <h3>
              <b>Connect meaningfully with your customers</b> and ensure your
              dining experience exceeds expectations.
            </h3>
            <h5>
              Boost your brand visibility with{" "}
              <img src={whatsicon} alt="Icon" />
              <span className="wapbol">WhatsApp</span>{" "}
              <span className="wapbol wapbol2">Business Platform</span>
            </h5>
          </div>

          <div className="whatsapp_grid">
            <div className="row">
              <div className="col-md-4">
                <div className="whatsapp_box">
                  <h4>Reduce response times by 70%</h4>
                  <p>Automated responses to queries with WhatsApp chatbot</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="whatsapp_box whatsapp_box2">
                  <h4>Achieve a 9x* marketing ROI </h4>
                  <p>Multimedia bulk message campaigns</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="whatsapp_box  whatsapp_box3">
                  <h4>Green tick verified WhatsApp account</h4>
                  <p>Display your restaurant’s credibility & trustworthiness</p>
                </div>
              </div>
            </div>
            <div class="take_conbtn whatsapp">
              <NavLink className="hm_fillbtn" onClick={openModal1}>
                Get a Demo
              </NavLink>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-5 whats_tabimg set_whatsapptab_section set_whatsapptab_second_section">
        <div className="container">
          <div className="hm_heading hm_heading_big hm_heading_blue">
            <h3>Our holistic WhatsApp marketing solution.</h3>
            <p>
              From first order to repeat purchase, keep your customers engaged.
            </p>
          </div>

          <div className="row mid">
            <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12 mobile-pad-none">
              <div
                className="accordion accordion_rista"
                id="holistic-faq-accordian"
              >
                <div className="card">
                  <div className="card-header">
                    <a
                      href="#"
                      className="btn btn-header-link"
                      data-toggle="collapse"
                      data-target="#holistic-faq1"
                      aria-expanded="true"
                      // onClick={() =>
                      //   handleCardClick(
                      //     require("../../images/whatsapp-buisness/Cohort_Creation.webp")
                      //   )
                      // }
                      onClick={() =>
                        handleCardClicks(
                          require("../../images/whatsapp-buisness/Cohort_Creation.webp"),
                          "Capture your customer feedback on WhatsApp",
                          "Capture your customer feedback on WhatsApp"
                        )
                      }
                    >
                      Cohort Creation
                    </a>
                  </div>

                  <div
                    id="holistic-faq1"
                    className="collapse show"
                    data-parent="#holistic-faq-accordian"
                  >
                    <div className="card-body">
                      Effortlessly segment your target audience. Suggest add-ons
                      based on past orders (fries with that burger?). Re-engage
                      inactive customers with special offers to win them back.
                      <div className="sec-5-img show-1">
                        {/* <img src={require("../../images/sec-5-img.png")}></img> */}
                        {selectedImages && (
                          <div className="selected-image ">
                            <img
                              src={selectedImages.src}
                              alt={selectedImages.alt}
                              title={selectedImages.title}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#holistic-faq2"
                      aria-expanded="false"
                      // onClick={() =>
                      //   handleCardClick(
                      //     require("../../images/whatsapp-buisness/Create_a_message_template.webp")
                      //   )
                      // }
                      onClick={() =>
                        handleCardClicks(
                          require("../../images/whatsapp-buisness/Create_a_message_template.webp"),
                          "Capture your customer feedback on WhatsApp",
                          "Capture your customer feedback on WhatsApp"
                        )
                      }
                    >
                      Message Templates
                    </a>
                  </div>

                  <div
                    id="holistic-faq2"
                    className="collapse"
                    data-parent="#holistic-faq-accordian"
                  >
                    <div className="card-body">
                      Craft captivating message templates with an integrated
                      Meta-approval process for seamless messaging.
                      <div className="sec-5-img show-1">
                        {/* <img src={require("../../images/sec-5-img.png")}></img> */}
                        {selectedImages && (
                          <div className="selected-image ">
                            <img
                              src={selectedImages.src}
                              alt={selectedImages.alt}
                              title={selectedImages.title}
                            />{" "}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#holistic-faq3"
                      aria-expanded="false"
                      // onClick={() =>
                      //   handleCardClick(
                      //     require("../../images/whatsapp-buisness/Chatbots.webp")
                      //   )
                      // }
                      onClick={() =>
                        handleCardClicks(
                          require("../../images/whatsapp-buisness/Chatbots.webp"),
                          "Capture your customer feedback on WhatsApp",
                          "Capture your customer feedback on WhatsApp"
                        )
                      }
                    >
                      Chatbots
                    </a>
                  </div>

                  <div
                    id="holistic-faq3"
                    className="collapse"
                    data-parent="#holistic-faq-accordian"
                  >
                    <div className="card-body">
                      Develop impactful automated conversations that captivate
                      and engage your audience effectively.
                      <div className="sec-5-img show-1">
                        {/* <img src={require("../../images/sec-5-img.png")}></img> */}
                        {selectedImages && (
                          <div className="selected-image ">
                            <img
                              src={selectedImages.src}
                              alt={selectedImages.alt}
                              title={selectedImages.title}
                            />{" "}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#holistic-faq4"
                      aria-expanded="false"
                      // onClick={() =>
                      //   handleCardClick(
                      //     require("../../images/whatsapp-buisness/Inbox.webp")
                      //   )
                      // }
                      onClick={() =>
                        handleCardClicks(
                          require("../../images/whatsapp-buisness/Marketing.webp"),
                          "Capture your customer feedback on WhatsApp",
                          "Capture your customer feedback on WhatsApp"
                        )
                      }
                    >
                      Marketing & Analytics
                    </a>
                  </div>

                  <div
                    id="holistic-faq4"
                    className="collapse"
                    data-parent="#holistic-faq-accordian"
                  >
                    <div className="card-body">
                      Reach 2Bn+ people instantly with targeted messages, track
                      campaign success and areas of improvement.
                      <div className="sec-5-img show-1">
                        {/* <img src={require("../../images/sec-5-img.png")}></img> */}
                        {selectedImages && (
                          <div className="selected-image ">
                            <img
                              src={selectedImages.src}
                              alt={selectedImages.alt}
                              title={selectedImages.title}
                            />{" "}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#holistic-faq5"
                      aria-expanded="false"
                      // onClick={() =>
                      //   handleCardClick(
                      //     require("../../images/whatsapp-buisness/Marketing.webp")
                      //   )
                      // }
                      onClick={() =>
                        handleCardClicks(
                          require("../../images/whatsapp-buisness/Inbox.webp"),
                          "Capture your customer feedback on WhatsApp",
                          "Capture your customer feedback on WhatsApp"
                        )
                      }
                    >
                      Inbox
                    </a>
                  </div>

                  <div
                    id="holistic-faq5"
                    className="collapse"
                    data-parent="#holistic-faq-accordian"
                  >
                    <div className="card-body">
                      Manage all your conversations from one place. Also, set
                      auto-replies and rules.
                      <div className="sec-5-img show-1">
                        {/* <img src={require("../../images/sec-5-img.png")}></img> */}
                        {selectedImages && (
                          <div className="selected-image ">
                            <img
                              src={selectedImages.src}
                              alt={selectedImages.alt}
                              title={selectedImages.title}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12 hide-1">
              {selectedImages && (
                <div className="selected-image ">
                  <img
                    src={selectedImages.src}
                    alt={selectedImages.alt}
                    title={selectedImages.title}
                  />{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="footer-bar-up whatsapp_footer_bar">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <h2 className="subheading">
                Enjoy a complete marketing ecosystem with DotPe.
              </h2>
              <h5 class="order-txt order_textnewwp">
                <span>Segment Creation</span> <span>ROI Tracking</span>{" "}
                <span>Digital Bills</span> <span>Order Updates</span>
              </h5>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="right-side">
                {/* <h4>Schedule your free demo today</h4> */}
                <h5>
                  We will reach out within 24 hours to schedule your slot.
                </h5>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Mobile Number"
                    ref={footerMobileRef}
                    name="mobileNumberfooter"
                    value={footerMobile}
                    onChange={handleChangeFooterForm}
                    maxLength={10}
                  />
                  {hasFooterMobileErrorText && (
                    <span className="error" style={{ color: "red" }}>
                      {hasFooterMobileErrorText}
                    </span>
                  )}
                </div>
                <button
                  className="btn btn-primary bt-form"
                  onClick={() => {
                    if (validateFooterMobileForm()) {
                      setHasFooterMobileErrorText("");
                      openModal1();
                    }
                  }}
                >
                  Get a Demo
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section seven */}

      <section className="sec-8 faq_btm set_faq_section">
        <div className="container">
          <h2 className="subheading">Frequently asked questions</h2>
          <div className="row mid">
            <div className="col-lg-10 col-md-12 col-sm-12">
              <div
                className="accordion accordion_rista accordion_rista2 accordion_rista3"
                id="faqaccord6"
              >
                <div className="card">
                  <div className="card-header" id="faqhead5">
                    <a
                      href="#"
                      className="btn btn-header-link"
                      data-toggle="collapse"
                      data-target="#faq4"
                      aria-expanded="true"
                      aria-controls="faq4"
                    >
                      Why is WhatsApp marketing a good fit for restaurants?
                    </a>
                  </div>

                  <div
                    id="faq4"
                    className="collapse show"
                    aria-labelledby="faqhead4"
                    data-parent="#faqaccord6"
                  >
                    <div className="card-body">
                      <p>
                        WhatsApp boasts incredible open rates and engagement
                        compared to traditional marketing channels like email.
                        Customers are already using WhatsApp for communication,
                        making it a familiar and convenient platform to receive
                        restaurant updates, promotions, and order confirmations.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqhead5">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq5"
                      aria-expanded="true"
                      aria-controls="faq5"
                    >
                      How can I segment my audience for targeted campaigns?
                    </a>
                  </div>

                  <div
                    id="faq5"
                    className="collapse"
                    aria-labelledby="faqhead5"
                    data-parent="#faqaccord6"
                  >
                    <div className="card-body">
                      <p>
                        Our platform allows you to segment your audience based
                        on various factors like past orders, location, and
                        activity level. This lets you send targeted messages
                        with high relevance. Imagine sending "Weekend Family
                        Brunch Deals" only to customers in the suburbs, or
                        "Late-Night Taco Specials" to downtown office workers –
                        it increases engagement and drives sales.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqhead6">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq6"
                      aria-expanded="true"
                      aria-controls="faq6"
                    >
                      Can I use WhatsApp marketing to send digital bills?
                    </a>
                  </div>

                  <div
                    id="faq6"
                    className="collapse"
                    aria-labelledby="faqhead6"
                    data-parent="#faqaccord6"
                  >
                    <div className="card-body">
                      <p>
                        Absolutely! WhatsApp eliminates the need for paper
                        bills. You can send eco-friendly digital receipts
                        directly through the app, saving on printing costs and
                        reducing waste. Plus, it offers convenience for
                        customers who can easily store and access their bills.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="faqhead7">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq7"
                      aria-expanded="true"
                      aria-controls="faq7"
                    >
                      How much time does managing WhatsApp marketing take?
                    </a>
                  </div>

                  <div
                    id="faq7"
                    className="collapse"
                    aria-labelledby="faqhead7"
                    data-parent="#faqaccord6"
                  >
                    <div className="card-body">
                      <p>
                        Our platform streamlines the process. You can schedule
                        automated campaigns, utilize chatbots to answer FAQs,
                        and access valuable reports to analyze performance and
                        optimize future efforts. This minimizes the time
                        investment needed to manage your WhatsApp marketing
                        strategy.{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="faqhead8">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq8"
                      aria-expanded="true"
                      aria-controls="faq8"
                    >
                      Is WhatsApp marketing expensive?
                    </a>
                  </div>

                  <div
                    id="faq8"
                    className="collapse"
                    aria-labelledby="faqhead8"
                    data-parent="#faqaccord6"
                  >
                    <div className="card-body">
                      <p>
                        Compared to traditional marketing methods, WhatsApp
                        marketing offers a cost-effective solution. Many
                        platforms provide free trials and flexible pricing plans
                        based on your restaurant's specific needs. The high
                        engagement and potential for increased sales often lead
                        to a positive return on investment (ROI).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ban-bt">
                <NavLink onClick={openModal1}>Try Now</NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />

      <div className="personal_modal_new business_modal_new">
        <Modal
          isOpen={modalIsOpen1}
          onRequestClose={closeModal1}
          // onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          className="customModalContent"
        >
          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2> */}
          {/* <button onClick={closeModal}>close</button> */}
          <div className="dotpe_popouter dotpe_popouter_new">
            <span
              className="closebtn_new"
              onClick={() => {
                closeModal1();
              }}
            >
              <img src={require("../../images/close-cross.png")} alt="Logo" />
            </span>
            <div className="row dotpe_popup dotpe_popup2 dotpe_popup3">
              <div className="dotpe_left col-md-5">
                <div className="dotpe_left_infos">
                  <h3>You are taking a right step for your business.</h3>
                  <h4>Trusted by 75 lakh+ businesses in india</h4>
                  <div className="dotpe_poplogo">
                    <img src={require("../../images/logo-w.png")} alt="Logo" />
                  </div>
                </div>
              </div>
              <div className="dotpe_right  col-md-7">
                <div className="dotpe_right_infos">
                  <div className="busi_loan_form">
                    <form>
                      <div className="form_heading">
                        <h3>Setup Free Demo</h3>
                        <p>
                          Fill in the details & our product specialist will
                          reach out to you
                        </p>
                      </div>
                      <div className={formClassName}>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          ref={inputName}
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                        />
                        {formErrors.name && (
                          <span className="error">{formErrors.name}</span>
                        )}
                      </div>
                      <div className={formClassName}>
                        {/* <div>
                          <select
                            className="form-control"
                            // value={formData.countryCode}
                            // onChange={handleChange}
                            // name="countryCode"
                          >
                              <option >
                                +91
                              </option>
                          </select>
                        </div> */}
                        <div className="mobilenum">
                          <span>+91</span>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="Mobile Number"
                            ref={inputNumber}
                            name="mobileNumber"
                            value={
                              footerMobile != ""
                                ? (formData.mobileNumber = footerMobile)
                                : formData.mobileNumber
                            }
                            onChange={handleChange}
                            maxLength={10}
                          />
                          {formErrors.mobileNumber && (
                            <span className="error">
                              {formErrors.mobileNumber}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className={formClassName}>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email Address"
                          ref={inputEmail}
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                        {formErrors.email && (
                          <span className="error">{formErrors.email}</span>
                        )}
                      </div>

                      <div className="form_click">
                        By clicking on continue, I hereby provide my consent to
                        receive emails, phone calls, messages and other
                        communications from DotPe to understand about DotPe's
                        services, offerings, promotions and other related
                        information.
                      </div>

                      <div className="formlead_btn">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            if (validateSecondForm()) {
                              validateAndOpenModal2();
                            }
                          }}
                          // onClick={validateAndOpenModal2}
                        >
                          Request Demo
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>

      <div className="personal_modal_new business_modal_new">
        <Modal
          isOpen={modalIsOpen2}
          onRequestClose={closeModal2}
          style={customStyles}
          contentLabel="Example Modal"
          className="customModalContent"
        >
          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2> */}
          {/* <button onClick={closeModal}>close</button> */}
          <div className="dotpe_popouter dotpe_popouter_new">
            <span
              className="closebtn_new"
              onClick={() => {
                closeModal2();
              }}
            >
              <img src={require("../../images/close-cross.png")} alt="Logo" />
            </span>
            <div className="row dotpe_popup dotpe_popup2 dotpe_popup3">
              <div className="dotpe_left col-md-5 dotpe_left2_mode">
                <div className="dotpe_left_infos  dotpe_left2_mode_infos">
                  <div className="dotpe_popcheck">
                    <img src={successcheck2} alt="Icon" />
                  </div>
                  <h4>You have successfully scheduled a call with us.</h4>
                  <div className="dotpe_poplogo">
                    <img src={require("../../images/logo-w.png")} alt="Logo" />
                  </div>
                </div>
              </div>
              <div className="dotpe_right  col-md-7">
                <div className="dotpe_right_infos">
                  <div className="busi_loan_form">
                    <form>
                      <div className="form_heading">
                        <h3>
                          Our team will reach out to you in next few minutes
                        </h3>
                        <p>Meanwhile tell us more about your 1 business day.</p>
                      </div>

                      <div className="form-group form_group_nice">
                        <label>Select a business category</label>
                        <NiceSelect
                          id="a-select"
                          placeholder="Select..."
                          className="sampleClass"
                          name="incomeSource"
                          value={formData.incomeSource}
                          onChange={handleChange}
                        >
                          <option value="Food and beverage">
                            Food and beverage
                          </option>
                          <option value="Clothing and accessories">
                            Clothing and accessories
                          </option>
                          <option value="Bakery">Bakery</option>
                          <option value="Salon">Salon</option>
                          <option value="Home Decor and furnishing">
                            Home Decor and furnishing
                          </option>
                          <option value="eCommerce">eCommerce</option>
                          <option value="Services">Services</option>
                        </NiceSelect>
                        {formErrors.incomeSource && (
                          <span className="error">
                            {formErrors.incomeSource}
                          </span>
                        )}
                      </div>
                      <div className="form-group form_group_nice form_group_nice2">
                        <label>Select your role in this business.</label>
                        <NiceSelect
                          id="a-select1"
                          placeholder="Select..."
                          className="sampleClass"
                          name="role"
                          value={formData.role}
                          onChange={handleChange}
                        >
                          <option value="Owner">Owner</option>
                          <option value="Store Manager">Store Manager</option>
                          <option value="Accounts">Accounts</option>
                          <option value="Sales">Sales</option>
                        </NiceSelect>
                        {formErrors.role && (
                          <span className="error">{formErrors.role}</span>
                        )}
                      </div>
                      <div className="formlead_btn formlead_btn2">
                        <button
                          type="button"
                          className="btn btn-primary"
                          // onClick={() => {
                          //   if (validateSecondForm()) {
                          //     saveDetails();
                          //   }
                          // }}
                          onClick={saveDetails}
                        >
                          Done
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default WhatsappBusinessApi;
