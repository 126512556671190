
import React, { useState,useRef } from "react";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Modal from "react-modal";
import NiceSelect from "../../NiceSelect/NiceSelect";
import successcheck2 from "../../images/success-check2.svg";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1,
  },
};

const Faq = () => {
    const [hasError, setHasError] = useState(false);

    const [formData, setFormData] = useState({
      name: "",
      countryCode: "+91",
      mobileNumber: "",
      pincode: "",
      incomeSource: "",
      buss_age: "",
      buss_turnover: "",
      email: "",
    });

    const [formErrors, setFormErrors] = useState({
      name: "",
      mobileNumber: "",
      pincode: "",
      incomeSource: "",
      buss_age: "",
      buss_turnover: "",
      email: "",
    });
  const location = useLocation();
  const resetForms = () => {
    setFormData({
      name: "",
      mobileNumber: "",
      pincode: "",
      incomeSource: "",
      buss_age: "",
      buss_turnover: "",
      email: "",
    });
  };
  const [firstFormId, setFirstFormId] = useState("");

    const inputName = useRef(null);
    const inputNumber = useRef(null);
    const inputEmail = useRef(null);
    const [modalIsOpen1, setModalIsOpen1] = useState(false);
    const [modalIsOpen2, setModalIsOpen2] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => {
      setIsModalOpen(true);
    };

    const closeModal = () => {
      setIsModalOpen(false);
    };
    const openModal1 = () => {
      setModalIsOpen1(true);
    };

    const openModal2 = () => {
      setModalIsOpen2(true);
      setModalIsOpen1(false);
    };

    const closeModal1 = () => {
      setModalIsOpen1(false);
    };

    const closeModal2 = () => {
      setModalIsOpen2(false);
    };
     const [emailSuggestions, setEmailSuggestions] = useState([]);

     const domains = ["gmail.com", "yahoo.com", "hotmail.com"];

     const validateSecondForm = () => {
       const errors = {};
       let formIsValid = true;
       const nameRegex = /^[A-Za-z\s]+$/;
       const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
       const numberRegex = /^\d+$/;

       if (!formData.name || !formData.name.trim()) {
         formIsValid = false;
         errors.name = "Name is required";
       } else if (formData.name.trim().length < 3) {
         formIsValid = false;
         errors.name = "Name should have at least 3 characters";
       } else if (formData.name.trim().length > 30) {
         formIsValid = false;
         errors.name = "Name should have at max 30 characters";
       } else if (!nameRegex.test(formData.name.trim())) {
         formIsValid = false;
         errors.name = "Name should contain only letters and spaces";
       }

       if (!formData.email || !formData.email.trim()) {
         formIsValid = false;
         errors.email = "Email is required";
       } else if (!emailRegex.test(formData.email)) {
         formIsValid = false;
         errors.email = "Invalid email address";
       }

       if (!formData.mobileNumber || !formData.mobileNumber.trim()) {
         formIsValid = false;
         errors.mobileNumber = "Mobile Number is required";
       } else if (!numberRegex.test(formData.mobileNumber)) {
         formIsValid = false;
         errors.mobileNumber = "Invalid mobile number";
       } else if (formData.mobileNumber.trim().length !== 10) {
         formIsValid = false;
         errors.mobileNumber = "Mobile number must be exactly 10 digits";
       } else {
         // Ensure mobile number starts with 6, 7, 8, or 9
         const firstDigit = formData.mobileNumber.trim().charAt(0);
         if (!["6", "7", "8", "9"].includes(firstDigit)) {
           formIsValid = false;
           errors.mobileNumber = "Mobile number must start with 6, 7, 8, or 9";
         }
       }

       setFormErrors(errors);
       setHasError(!formIsValid);

       return formIsValid;
     };

     const handleChange = (e) => {
       const { name, value } = e.target;
       let newValue = value;
       if (name === "name") {
         if (value === "" || /^[^\s]/.test(value)) {
           newValue = value;
         } else {
           newValue = value.trimStart();
         }
       } else if (name === "mobileNumber") {
         console.log(newValue.length);
         newValue = value.replace(/\D/g, "");

         if (newValue.length > 10) {
           newValue = newValue.slice(0, 10);
         }
       } else if (name === "email") {
         if (value.includes("@")) {
           const [localPart, domainPart] = value.split("@");
           setEmailSuggestions(
             domains
               .filter((domain) => domain.startsWith(domainPart))
               .map((domain) => `${localPart}@${domain}`)
           );
         } else {
           setEmailSuggestions([]);
         }
       }

       setFormData((prevData) => ({
         ...prevData,
         [name]: newValue,
       }));
       setFormErrors((prevErrors) => ({
         ...prevErrors,
         [name]: "",
       }));
     };

     const handleSubmit = (e) => {
       e.preventDefault();
       validateSecondForm();
     };

     const formClassName = hasError ? "form-error form-group" : "form-group";

     const validateAndOpenModal2 = async () => {
       if (await validateSecondForm()) {
         const apiUrl = process.env.REACT_APP_FORM_ONE;
         const combinedData = {
           name: formData.name,
           phone: formData.mobileNumber,
           email: formData.email,
           source: window.location.href,
          };
         localStorage.setItem("mobile", formData.mobileNumber);
         console.log(combinedData);

         try {
           const response = await fetch(apiUrl, {
             method: "POST",
             headers: {
               "Content-Type": "application/json",
               Accept: "application/json; charset=utf-8",
             },
             body: JSON.stringify(combinedData),
           });

           if (!response.ok) {
             throw new Error(`HTTP error! status: ${response.status}`);
           }

           const data = await response.json();
           console.log(data);
           setFirstFormId(data.id);
           openModal2();
         } catch (error) {
           console.error("Error:", error);
         }
       }
     };

     // const saveDetails = () => {
     //   closeModal2();
     // };
     const navigate = useNavigate();

     const validateForm = () => {
       const errors = {};
       let formIsValid = true;

       if (!formData.incomeSource || formData.incomeSource === "0") {
         formIsValid = false;
         errors.incomeSource = "Please select a business category";
       }

       if (!formData.role || formData.role === "0") {
         formIsValid = false;
         errors.role = "Please select your role";
       }

       setFormErrors(errors);
       return formIsValid;
     };

     const saveDetails = async () => {
       const isValid = validateForm();

       if (isValid) {
         const apiUrl = process.env.REACT_APP_FORM_ONE + "/" + firstFormId;
         const combinedData = {
           businessCategory: formData.incomeSource,
           role: formData.role,
         };
         console.log(combinedData);

         try {
           const response = await fetch(apiUrl, {
             method: "PATCH",
             headers: {
               "Content-Type": "application/json",
             },
             body: JSON.stringify(combinedData),
           });

           if (!response.ok) {
             throw new Error(`HTTP error! status: ${response.status}`);
           }

           const data = await response.json();
           console.log(data);
           resetForms();
           closeModal2();
           navigate("/thankyou");
         } catch (error) {
           console.error("Error:", error);
         }
       }
     };

  const [footerMobile, setFooterMobile] = useState("");
  const footerMobileRef = useRef(null);
  const [hasFooterMobileError, setHasFooterMobileError] = useState(false);
  const [hasFooterMobileErrorText, setHasFooterMobileErrorText] = useState("");
  const handleChangeFooterForm = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    if (name === "mobileNumberfooter") {
      console.log(newValue.length);
      newValue = value.replace(/\D/g, "");

      if (newValue.length > 10) {
        newValue = newValue.slice(0, 10);
      }
    }
    setFooterMobile(newValue);
  };
  const validateFooterMobileForm = () => {
    let formIsValid = true;
    const numberRegex = /^\d+$/;
    if (!footerMobile || !footerMobile.trim()) {
      formIsValid = false;
      setHasFooterMobileErrorText("Mobile Number is required");
    } else if (!numberRegex.test(footerMobile)) {
      formIsValid = false;
      setHasFooterMobileErrorText("Invalid mobile number");
    } else if (footerMobile.trim().length !== 10) {
      formIsValid = false;
      setHasFooterMobileErrorText("Mobile number must be exactly 10 digits");
    } else {
      const firstDigit = footerMobile.trim().charAt(0);
      if (!["6", "7", "8", "9"].includes(firstDigit)) {
        formIsValid = false;
        setHasFooterMobileErrorText(
          "Mobile number must start with 6, 7, 8, or 9"
        );
      }
    }
    setHasFooterMobileError(!formIsValid);

    return formIsValid;
  };
  return (
    <div>
      <Header />
      <section>
        <div className="container">
          <div className="faq_row">
            <div className="sec-8 faq_btm faq_btm_pros faq_mainpage">
              <div className="container">
                <div className="rista_title_faq">
                  <h2 className="subheading">FAQs</h2>
                  <h4>You got questions, we got answers.</h4>
                </div>
                <div className="row mid">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div
                      className="accordion  accordion_rista accordion_rista2 accordion_rista3 accord_plusminus"
                      id="faqaccord6"
                    >
                      <div className="card">
                        <div className="card-header" id="faqhead5">
                          <a
                            href="#"
                            className="btn btn-header-link"
                            data-toggle="collapse"
                            data-target="#faq4"
                            aria-expanded="true"
                            aria-controls="faq4"
                          >
                            {" "}
                            <span className="plusminus"></span>
                            Getting Started
                          </a>
                        </div>

                        <div
                          id="faq4"
                          className="collapse show"
                          aria-labelledby="faqhead4"
                          data-parent="#faqaccord6"
                        >
                          <div className="card-body">
                            <div class="rista_faqcol">
                              <p>
                                <strong>What is Rista Sales?</strong>
                              </p>
                              <p>
                                Rista Sales is a mobile point-of-sale (POS)
                                solution for retail businesses to perform sales
                                transactions, print or send digital receipts to
                                customers, track product inventory, manage
                                business effectively with real-time analytics.
                                It currently runs on Android mobile devices. All
                                of the sales data is stored on our servers on
                                the cloud so that you can access it from
                                anywhere at anytime.
                              </p>
                            </div>

                            <div class="rista_faqcol">
                              <p>
                                <strong>
                                  What type of businesses can use Rista Sales?
                                </strong>
                              </p>
                              <p>
                                Rista Sales can be used by any retail product or
                                service business that generates sales receipts
                                or invoices for selling its products or
                                services. Typical business that can use Rista
                                Sales include retail stores, Food &amp; Beverage
                                shops, beauty and hair care salons, wholesalers
                                and distributors, dry-cleaner, doctors and other
                                professional services.
                              </p>
                            </div>

                            <div class="rista_faqcol">
                              <p>
                                <strong>
                                  Which countries and currencies can use Rista
                                  Sales?
                                </strong>
                              </p>
                              <p>
                                Rista Sales is a global retail sales solution,
                                but available in English language only. It can
                                be used in any country and record transactions
                                in local currency (displays local currency
                                symbol where available).
                              </p>
                            </div>

                            <div class="rista_faqcol">
                              <p>
                                <strong>
                                  I already have a POS system, does it make
                                  sense for me to switch to Rista Sales?
                                </strong>
                              </p>
                              <p>
                                Yes, absolutely, it makes all the sense. By
                                switching to Rista Sales, you enjoy our premium
                                product features at a low monthly fee. Rista
                                Sales has no upfront setup fee, no special
                                hardware requirement (can run on any android
                                mobile device), and with Rista Receipts you can
                                save money from printer and printing costs.
                              </p>
                            </div>

                            <div class="rista_faqcol">
                              <p>
                                <strong>
                                  How do I sign up for Rista Sales?
                                </strong>
                              </p>
                              <p>
                                From your mobile device (phone or tablet), go to
                                www.ristaapps.com, select Download Now button to
                                go to the app store. Download the Rista Sales to
                                your device, start the app and follow the
                                instructions to register your business.
                              </p>
                            </div>

                            <div class="rista_faqcol">
                              <p>
                                <strong>
                                  What information do I need to sign up?
                                </strong>
                              </p>
                              <p>
                                All you need is the full legal name of your
                                business, address of your business (no need to
                                type, just locate it on built-in Google Map),
                                and your contact information.
                              </p>
                            </div>

                            <div class="rista_faqcol">
                              <p>
                                <strong>
                                  Do you require a credit card to sign up?
                                </strong>
                              </p>
                              <p>
                                No, you can signup and start using our free
                                license without adding any credit or debit card
                                information. Once you are satisfied, you can
                                purchase a license from within Rista Sales and
                                pay with a major credit or debit card.
                              </p>
                            </div>

                            <div class="rista_faqcol">
                              <p>
                                <strong>
                                  How do I contact Rista for support?
                                </strong>
                              </p>
                              <p>
                                You can send us an email at
                                support@ristaapps.com for any technical or
                                commercial questions, and we will respond to
                                your questions via email.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="faqhead5">
                          <a
                            href="#"
                            className="btn btn-header-link collapsed"
                            data-toggle="collapse"
                            data-target="#faq5"
                            aria-expanded="true"
                            aria-controls="faq5"
                          >
                            <span className="plusminus"></span>
                            Setup
                          </a>
                        </div>

                        <div
                          id="faq5"
                          className="collapse"
                          aria-labelledby="faqhead5"
                          data-parent="#faqaccord6"
                        >
                          <div className="card-body">
                            <div class="rista_faqcol">
                              <p>
                                {" "}
                                <strong>
                                  {" "}
                                  What hardware is required to run Rista Sales?
                                </strong>{" "}
                              </p>
                              <p>
                                Rista Sales runs on Android (version 4.2 and
                                above) mobile devices, including on a
                                smartphone, tablet or phablet. Just download
                                Rista Sales from the Google Play store or by
                                going to www.ristaapps.com, register your
                                business, setup your product catalog and start
                                selling.
                              </p>
                            </div>

                            <div class="rista_faqcol">
                              <p>
                                {" "}
                                <strong>
                                  {" "}
                                  Does Rista Sales works on iOS (Apple) mobile
                                  devices?
                                </strong>{" "}
                              </p>
                              <p>
                                Currently, Rista Sales runs only on Android
                                based mobile devices, including on smartphones,
                                tablets or phablets. Rista Sales for the iOS
                                (Apple) devices will be released soon.
                              </p>
                            </div>

                            <div class="rista_faqcol">
                              <p>
                                {" "}
                                <strong>
                                  {" "}
                                  What size of tablet or phone do I need?
                                </strong>{" "}
                              </p>
                              <p>
                                We recommend using Rista Sales on mobile devices
                                with screen size of 4.5 inches or bigger.{" "}
                              </p>
                            </div>

                            <div class="rista_faqcol">
                              {" "}
                              <p>
                                {" "}
                                <strong>
                                  {" "}
                                  Would I need a mobile data plan?
                                </strong>{" "}
                              </p>
                              <p>
                                Data plan is only needed if and when you do not
                                have Wi-Fi access. Rista Sales works just fine
                                on 3G or 4G data connections.
                              </p>
                            </div>

                            <div class="rista_faqcol">
                              <p>
                                {" "}
                                <strong>
                                  {" "}
                                  What receipt printer do I need to print sales
                                  receipts from Rista Sales?
                                </strong>{" "}
                              </p>
                              <p>
                                Rista Sales allows you to send digital receipts
                                to your customers via email, SMS or through a
                                Rista account (customer will require the free
                                Rista app to access the receipts). However, if
                                you prefer to give paper receipts, Rista Sales
                                prints wirelessly on popular ethernet receipt
                                printers available in the market, including
                                printers from Epson, Star and TVS.{" "}
                              </p>
                            </div>

                            <div class="rista_faqcol">
                              <p>
                                {" "}
                                <strong>
                                  {" "}
                                  What method of payments can my customers use?
                                </strong>{" "}
                              </p>
                              <p>
                                Rista Sales currently does not have an
                                integrated payment system, so you can continue
                                to use your existing payment system for the
                                electronic payments from your customers and
                                record it in Rista Sales. You can record all
                                sorts of payment methods in Rista Sales,
                                including cash, credit, debit and check.{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="faqhead6">
                          <a
                            href="#"
                            className="btn btn-header-link collapsed"
                            data-toggle="collapse"
                            data-target="#faq6"
                            aria-expanded="true"
                            aria-controls="faq6"
                          >
                            <span className="plusminus"></span>
                            Licensing and Payment
                          </a>
                        </div>

                        <div
                          id="faq6"
                          className="collapse"
                          aria-labelledby="faqhead6"
                          data-parent="#faqaccord6"
                        >
                          <div className="card-body">
                            <div class="rista_faqcol">
                              <p>
                                <strong> Is there a setup fee?</strong>{" "}
                              </p>
                              <p>
                                No, there is no setup fee. All you will be
                                paying is for the licenses you purchase.
                              </p>
                            </div>{" "}
                            <div class="rista_faqcol">
                              <p>
                                <strong>
                                  {" "}
                                  Are there any additional fees besides license
                                  fee?
                                </strong>{" "}
                              </p>
                              <p>
                                No, there are no setup or any other hidden fees.
                                You only pay for the licenses you purchase.
                              </p>
                            </div>{" "}
                            <div class="rista_faqcol">
                              <p>
                                <strong>
                                  {" "}
                                  How do I decide which license type is best
                                  suited for my business?
                                </strong>{" "}
                              </p>
                              <p>
                                If your business has a single branch or outlet,
                                you can purchase Sales Small or Sales Medium
                                licenses. The Sales Small license is suitable
                                for a business that requires only one license.
                                The Sales Medium license allows you to purchase
                                multiple licenses. If your business has multiple
                                branches or outlets, you can purchase only Sales
                                Enterprise license.
                              </p>
                            </div>{" "}
                            <div class="rista_faqcol">
                              <p>
                                <strong>
                                  {" "}
                                  How do I decide how many licenses do I need
                                  for my business?
                                </strong>{" "}
                              </p>
                              <p>
                                A business can register unlimited devices and
                                users to Rista Sales irrespective of the number
                                of licenses the business has purchased. However,
                                number of users that can use Rista Sales at any
                                given time is determined by the number of
                                licenses you have. So if your business requires
                                you to have two users using the application at
                                the same time, you will need to purchase two
                                licenses and so on.
                              </p>
                            </div>{" "}
                            <div class="rista_faqcol">
                              <p>
                                <strong>
                                  {" "}
                                  Can I upgrade my licenses as my business
                                  grows?
                                </strong>{" "}
                              </p>
                              <p>
                                Yes, you can. We have designed multiple license
                                types to choose from depending on your current
                                and future business needs, for example, growing
                                from a single branch business to multi-branch
                                business. You can purchase new licenses from
                                within the Rista Sales account management
                                section.
                              </p>
                            </div>{" "}
                            <div class="rista_faqcol">
                              <p>
                                <strong>
                                  {" "}
                                  Why is annual payment plan cheaper than
                                  monthly payment plans?{" "}
                                </strong>{" "}
                              </p>
                              <p>
                                In consideration of your commitment to use our
                                services on annual basis and prepaying for these
                                services, we reward you by offering a
                                considerable discount under our annual plan.
                              </p>
                            </div>{" "}
                            <div class="rista_faqcol">
                              <p>
                                <strong>
                                  {" "}
                                  What are the various methods of payment for
                                  purchasing Rista Sales licenses?
                                </strong>{" "}
                              </p>
                              <p>
                                You can purchase or renew licenses within Rista
                                Sales and make payment using any of the major
                                credit or debit card. In India, payment by bank
                                transfer option is available. Please follow the
                                instructions provided at the time of purchasing
                                licenses.
                              </p>
                            </div>{" "}
                            <div class="rista_faqcol">
                              <p>
                                <strong>
                                  {" "}
                                  How do I purchase Rista Sales licenses for my
                                  business?
                                </strong>{" "}
                              </p>
                              <p>
                                All licenses for your business are purchased
                                from within Rista Sales right on your mobile
                                device. Under Rista Sales Account section, you
                                can manage all license related activities,
                                including see current licenses, purchase new
                                licenses, pay for purchase, get receipts and
                                receive renewal invoices.
                              </p>
                            </div>{" "}
                            <div class="rista_faqcol">
                              <p>
                                <strong>
                                  {" "}
                                  How long does it take to get licenses after
                                  purchase?
                                </strong>{" "}
                              </p>
                              <p>
                                Licenses are purchased within Rista Sales. Upon
                                payment is completed using a major credit or
                                debit card, the purchased licenses will be
                                automatically added to your account and
                                available immediately for your use.
                              </p>
                            </div>{" "}
                            <div class="rista_faqcol">
                              <p>
                                <strong>
                                  {" "}
                                  How do I renew my Rista Sales licenses?
                                </strong>{" "}
                              </p>
                              <p>
                                On the first day of a month you will receive a
                                renewal invoice in your account and an email
                                reminding you to renew the licenses that are up
                                for renewal. You can go to your account in Rista
                                Sales and pay for the licenses up for renewal on
                                your mobile device.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-header" id="faqhead7">
                          <a
                            href="#"
                            className="btn btn-header-link collapsed"
                            data-toggle="collapse"
                            data-target="#faq7"
                            aria-expanded="true"
                            aria-controls="faq7"
                          >
                            <span className="plusminus"></span>
                            Point of Order
                          </a>
                        </div>

                        <div
                          id="faq7"
                          className="collapse"
                          aria-labelledby="faqhead7"
                          data-parent="#faqaccord6"
                        >
                          <div className="card-body">
                            <div class="rista_faqcol">
                              <p>
                                {" "}
                                <strong> What is a Point of Order?</strong>{" "}
                              </p>
                              <p>
                                Point of Order (POO) is an ordering app used by
                                servers/waiters at a restaurant or a sales
                                representatives away from the business location
                                to take only orders for their customers.
                              </p>
                            </div>

                            <div class="rista_faqcol">
                              <p>
                                {" "}
                                <strong>
                                  {" "}
                                  Do I need one of the Sales licenses to be able
                                  to use POO?
                                </strong>{" "}
                              </p>
                              <p>
                                Yes, POO can only be purchased as an add-option
                                and requires the business to have at least one
                                license of any of the base Sales licenses.
                                Orders created using POO licenses can be further
                                processed for recording payments using any of
                                the Sales licenses.
                              </p>
                            </div>

                            <div class="rista_faqcol">
                              <p>
                                {" "}
                                <strong>
                                  {" "}
                                  Why is the license fee for POO is lower than
                                  the license fee for a Sales license?
                                </strong>{" "}
                              </p>
                              <p>
                                The Rista Sales license fees are priced with a
                                goal to minimize the cost of using our solution
                                and maximize the value created for businesses.
                                The features available in POO is intended for
                                order taking only and allows a business to
                                purchase multiple licenses of POO while
                                supported by a fewer number of Sales licenses.
                                For example, a small restaurant business can
                                purchase multiple POO licenses at a low price
                                for its waiters and supported by only one Sales
                                license. Similarly, a business with a large
                                number sales representatives on the field taking
                                orders at customer locations could buy a POO
                                license for each sales representative and
                                supported by a fewer number of Sales licenses
                                for further processing of orders at its back
                                office.{" "}
                              </p>
                            </div>

                            <div class="rista_faqcol">
                              <p>
                                {" "}
                                <strong>
                                  {" "}
                                  What businesses can benefit from POO?
                                </strong>{" "}
                              </p>
                              <p>
                                Point of Order is a great app to bring
                                efficiency to your sales process and can be used
                                by any business requiring orders to be created
                                away from the checkout counter or away from the
                                business location. The businesses that could
                                benefit from using POO, include but not limited
                                to:
                                <br />
                                <br />
                                Hospitality and service industry
                                <br />
                                Businesses with sales teams on the field <br />
                                Businesses sending price quotes with details
                                before finalizing a sale <br />
                                Businesses taking orders on the phone
                                <br />
                                Large retail and clothing stores{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-header" id="faqhead8">
                          <a
                            href="#"
                            className="btn btn-header-link collapsed"
                            data-toggle="collapse"
                            data-target="#faq8"
                            aria-expanded="true"
                            aria-controls="faq8"
                          >
                            <span className="plusminus"></span>
                            Sales Data
                          </a>
                        </div>

                        <div
                          id="faq8"
                          className="collapse"
                          aria-labelledby="faqhead8"
                          data-parent="#faqaccord6"
                        >
                          <div className="card-body">
                            <div class="rista_faqcol">
                              <p>
                                <strong>
                                  {" "}
                                  Who has access to my sales data?
                                </strong>{" "}
                              </p>
                              <p>
                                The sales data generated using Rista Sales will
                                be maintained on Rista servers, but accessed
                                only by the registered users on the registered
                                devices of your business. You keep full control
                                on who can access or modify your business data
                                by giving appropriate access to your users. For
                                example, you can restrict a sales clerk to only
                                use the product catalog and run sales
                                transactions, but not modify the catalog or see
                                sales reports. Similarly, allow only a select
                                individuals to have full control on modifying
                                the catalog, including changing prices,
                                discounts or tax rates. With Rista Sales, you
                                have the ultimate control on your business data.
                              </p>
                            </div>

                            <div class="rista_faqcol">
                              <p>
                                <strong>
                                  {" "}
                                  What if I lose my mobile device, can someone
                                  use it to access my business account?
                                </strong>{" "}
                              </p>
                              <p>
                                No, accessing your business account requires
                                your username and PIN. We strongly recommend
                                that you immediately deactivate the lost device
                                from the Access module of your Rista Sales.
                              </p>
                            </div>

                            <div class="rista_faqcol">
                              <p>
                                <strong>
                                  {" "}
                                  What happens after all my licenses to Rista
                                  Sales have expired?
                                </strong>{" "}
                              </p>
                              <p>
                                As long as you have a valid license to Rista
                                Sales, you will continue to have access to your
                                account and your data. We will maintain your
                                data for one month after all your licenses have
                                expired to allow you to renew or buy a license
                                to resume your access to your data. Thereafter,
                                we will deactivate your account and you will not
                                have access to your data.
                              </p>
                            </div>

                            <div class="rista_faqcol">
                              <p>
                                <strong>
                                  Is my sales data transmitted safely over the
                                  Internet?
                                </strong>{" "}
                              </p>
                              <p>
                                Yes, absolutely. All communication between the
                                Rista apps you use on your mobile devices and
                                our servers on the Cloud takes place on an
                                encrypted link over a 128-bit secured layer,
                                also referred to as HTTPS using SSL, and is
                                certified by DigiCert. This allows all your
                                sales data, including sensitive information such
                                as credit card numbers and login credentials, is
                                transmitted securely over the Internet to our
                                servers.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-header" id="faqhead9">
                          <a
                            href="#"
                            className="btn btn-header-link collapsed"
                            data-toggle="collapse"
                            data-target="#faq9"
                            aria-expanded="true"
                            aria-controls="faq9"
                          >
                            <span className="plusminus"></span>
                            Consumer App
                          </a>
                        </div>

                        <div
                          id="faq9"
                          className="collapse"
                          aria-labelledby="faqhead9"
                          data-parent="#faqaccord6"
                        >
                          <div className="card-body">
                            <div class="rista_faqcol">
                              <p>
                                <strong>
                                  {" "}
                                  What is Rista app for consumers?
                                </strong>{" "}
                              </p>
                              <p>
                                The Rista app for consumer is a free mobile app
                                for any buyer/shopper to maintain profile
                                information, access orders and receipts from all
                                businesses powered by Rista Sales, and much more
                                to bring a convenient shopping experience with
                                the retail businesses. The Rista app can be used
                                by consumers for their personal purchases or by
                                businesses for buying from their distributors
                                and suppliers. Soon, using the Rista app, a
                                consumer will be able to place orders and
                                schedule appointments with the local businesses
                                powered by Rista Sales.
                              </p>
                            </div>

                            <div class="rista_faqcol">
                              <p>
                                <strong>
                                  {" "}
                                  Do I have to pay or provide credit card
                                  information to sign up or use Rista app?
                                </strong>{" "}
                              </p>
                              <p>
                                No, you don’t have to pay anything or provide
                                credit card information to sign up and use.
                              </p>
                            </div>

                            <div class="rista_faqcol">
                              <p>
                                <strong>
                                  {" "}
                                  Can I see an order or invoice using my Rista
                                  app?
                                </strong>{" "}
                              </p>
                              <p>
                                Yes, you get to see an itemized order created by
                                the seller powered by Rista Sales for all the
                                products and services provided to you while it
                                is being created and the final bill after you
                                make a payment.{" "}
                              </p>
                            </div>

                            <div class="rista_faqcol">
                              <p>
                                <strong> Can I print my receipts?</strong>{" "}
                              </p>
                              <p>
                                Yes, from the Rista app you can forward a
                                receipt to your registered email account and
                                then you can open it to print or save as PDF
                                like any other webpage.
                              </p>
                            </div>

                            <div class="rista_faqcol">
                              <p>
                                <strong>
                                  {" "}
                                  What is Rista Receipts and how do I get it?
                                </strong>{" "}
                              </p>
                              <p>
                                Rista Receipts are digital receipts that you
                                receive when you make a purchase from a business
                                powered by Rista Sales. You can ask the business
                                to send you a Rista Receipt by email or SMS,
                                which can be viewed conveniently on your mobile
                                phone within seconds without requiring any
                                software to download. For a more convenient
                                shopping experience, you first download the
                                Rista app on your mobile (a link to the app
                                store is provided at the top of the Rista
                                Receipt or you can find it at
                                www.ristaapps.com), register the app to get your
                                own Rista account, and provide it (or your
                                registered mobile/phone number) to the business
                                at the time an order is created, so that you can
                                see all updates to the order in real-time and
                                before making payment. At every purchase you
                                make at businesses powered by Rista Sales, by
                                providing your Rista account or mobile number,
                                you have the convenience of accessing your
                                receipts forever via your Rista app.
                              </p>
                            </div>

                            <div class="rista_faqcol">
                              <p>
                                <strong>
                                  {" "}
                                  Can I link all Rista Receipts I received via
                                  email or SMS to my Rista account?{" "}
                                </strong>{" "}
                              </p>
                              <p>
                                Yes, certainly. From within your Rista app,
                                select the option to Upload Bills and follow the
                                directions to link all the bills you received
                                via email or SMS to your Rista account. All the
                                bills received to your registered email address
                                in your Rista account will be automatically
                                linked to your account. For the bills you
                                received via your mobile number, you will have
                                to provide a couple of information to verify
                                your mobile number and then we will do the rest
                                to link all your bills to your account.{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="ban-bt">
                      <NavLink onClick={openModal1}>Try Now</NavLink>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="personal_modal_new business_modal_new">
          <Modal
            isOpen={modalIsOpen1}
            onRequestClose={closeModal1}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            className="customModalContent"
          >
            {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2> */}
            {/* <button onClick={closeModal}>close</button> */}
            <div className="dotpe_popouter dotpe_popouter_new">
              <span
                className="closebtn_new"
                onClick={() => {
                  closeModal1();
                }}
              >
                <img src={require("../../images/close-cross.png")} alt="Logo" />
              </span>
              <div className="row dotpe_popup dotpe_popup2 dotpe_popup3">
                <div className="dotpe_left col-md-5">
                  <div className="dotpe_left_infos">
                    <h3>You are taking a right step for your business.</h3>
                    <h4>Trusted by 75 lakh+ businesses in india</h4>
                    <div className="dotpe_poplogo">
                      <img
                        src={require("../../images/logo-w.png")}
                        alt="Logo"
                      />
                    </div>
                  </div>
                </div>
                <div className="dotpe_right  col-md-7">
                  <div className="dotpe_right_infos">
                    <div className="busi_loan_form">
                      <form>
                        <div className="form_heading">
                          <h3>Setup Free Demo</h3>
                          <p>
                            Fill in the details & our product specialist will
                            reach out to you
                          </p>
                        </div>
                        <div className={formClassName}>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            ref={inputName}
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                          />
                          {formErrors.name && (
                            <span className="error">{formErrors.name}</span>
                          )}
                        </div>
                        <div className={formClassName}>
                          {/* <div>
                          <select
                            className="form-control"
                            // value={formData.countryCode}
                            // onChange={handleChange}
                            // name="countryCode"
                          >
                              <option >
                                +91
                              </option>
                          </select>
                        </div> */}
                          <div className="mobilenum">
                            <span>+91</span>

                            <input
                              type="text"
                              className="form-control"
                              placeholder="Mobile Number"
                              ref={inputNumber}
                              name="mobileNumber"
                              value={
                                footerMobile != ""
                                  ? (formData.mobileNumber = footerMobile)
                                  : formData.mobileNumber
                              }
                              onChange={handleChange}
                              maxLength={10}
                            />
                            {formErrors.mobileNumber && (
                              <span className="error">
                                {formErrors.mobileNumber}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className={formClassName}>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email Address"
                            ref={inputEmail}
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                          />
                          {formErrors.email && (
                            <span className="error">{formErrors.email}</span>
                          )}
                        </div>

                        <div className="form_click">
                          By clicking on continue, I hereby provide my consent
                          to receive emails, phone calls, messages and other
                          communications from DotPe to understand about DotPe's
                          services, offerings, promotions and other related
                          information.
                        </div>

                        <div className="formlead_btn">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                              if (validateSecondForm()) {
                                validateAndOpenModal2();
                              }
                            }}
                            // onClick={validateAndOpenModal2}
                          >
                            Request Demo
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>

        <div className="personal_modal_new business_modal_new">
          <Modal
            isOpen={modalIsOpen2}
            onRequestClose={closeModal2}
            style={customStyles}
            contentLabel="Example Modal"
            className="customModalContent"
          >
            {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2> */}
            {/* <button onClick={closeModal}>close</button> */}
            <div className="dotpe_popouter dotpe_popouter_new">
              <span
                className="closebtn_new"
                onClick={() => {
                  closeModal2();
                }}
              >
                <img src={require("../../images/close-cross.png")} alt="Logo" />
              </span>
              <div className="row dotpe_popup dotpe_popup2 dotpe_popup3">
                <div className="dotpe_left col-md-5 dotpe_left2_mode">
                  <div className="dotpe_left_infos  dotpe_left2_mode_infos">
                    <div className="dotpe_popcheck">
                      <img src={successcheck2} alt="Icon" />
                    </div>
                    <h4>You have successfully scheduled a call with us.</h4>
                    <div className="dotpe_poplogo">
                      <img
                        src={require("../../images/logo-w.png")}
                        alt="Logo"
                      />
                    </div>
                  </div>
                </div>
                <div className="dotpe_right  col-md-7">
                  <div className="dotpe_right_infos">
                    <div className="busi_loan_form">
                      <form>
                        <div className="form_heading">
                          <h3>
                            Our team will reach out to you in next few minutes
                          </h3>
                          <p>
                            Meanwhile tell us more about your 1 business day.
                          </p>
                        </div>

                        <div className="form-group form_group_nice">
                          <label>Select a business category</label>
                          <NiceSelect
                            id="a-select"
                            placeholder="Select..."
                            className="sampleClass"
                            name="incomeSource"
                            value={formData.incomeSource}
                            onChange={handleChange}
                          >
                            <option value="Food and beverage">
                              Food and beverage
                            </option>
                            <option value="Clothing and accessories">
                              Clothing and accessories
                            </option>
                            <option value="Bakery">Bakery</option>
                            <option value="Salon">Salon</option>
                            <option value="Home Decor and furnishing">
                              Home Decor and furnishing
                            </option>
                            <option value="eCommerce">eCommerce</option>
                            <option value="Services">Services</option>
                          </NiceSelect>
                          {formErrors.incomeSource && (
                            <span className="error">
                              {formErrors.incomeSource}
                            </span>
                          )}
                        </div>
                        <div className="form-group form_group_nice form_group_nice2">
                          <label>Select your role in this business.</label>
                          <NiceSelect
                            id="a-select1"
                            placeholder="Select..."
                            className="sampleClass"
                            name="role"
                            value={formData.role}
                            onChange={handleChange}
                          >
                            <option value="Owner">Owner</option>
                            <option value="Store Manager">Store Manager</option>
                            <option value="Accounts">Accounts</option>
                            <option value="Sales">Sales</option>
                          </NiceSelect>
                          {formErrors.role && (
                            <span className="error">{formErrors.role}</span>
                          )}
                        </div>
                        <div className="formlead_btn formlead_btn2">
                          <button
                            type="button"
                            className="btn btn-primary"
                            // onClick={() => {
                            //   if (validateSecondForm()) {
                            //     saveDetails();
                            //   }
                            // }}
                            onClick={saveDetails}
                          >
                            Done
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Faq;

