import React, { useState, useEffect, useRef } from 'react';
import Footer from '../common/Footer';
import Header from '../common/Header';
import { NavLink } from 'react-router-dom';
import success_right_tick from '../../images/thanku-check.svg';

const Thankyou = () => {
  const [mobile, setMobile] = useState('');
  // useEffect(() => {
  //   const mobile = localStorage.getItem('mobile');
  //   if (mobile) {
  //     setMobile(mobile);
  //     localStorage.removeItem('mobile');
  //   }
  // }, []);

  useEffect(() => {
    const mobile = localStorage.getItem("mobile");
    if (mobile) {
      setMobile(mobile);
      localStorage.removeItem("mobile");
    }

    // Scroll to top on component mount
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <Header />

      <div className='dotpe_popup sucess_popinfos'>
        <div className='sucess_right_tick'>
          <span>
            <img src={success_right_tick} alt='Icon' />
          </span>
        </div>
        <h3>We have recieved your information!</h3>
        <p className='succes_desk'>
          Our team will reach out to you on your mobile number +91 {mobile}
        </p>

        <div className='sucess_btn_expolor'>
          <NavLink to='/'>Return to Website</NavLink>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Thankyou;
