import Footer from '../common/Footer';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import mid_background from '../../images/Background.jpg';
import { useEffect, useState, useRef } from 'react';
import HeaderDark from '../common/HeaderDark';
import tab_arrow_right from '../../images/tab_arrow_right.png';
import fine_icon1 from '../../images/fine_icon1.svg';
import fine_icon2 from '../../images/fine_icon2.svg';
import fine_icon3 from '../../images/fine_icon3.svg';
import fine_icon4 from '../../images/fine_icon4.svg';
import quick_sicon1 from '../../images/quick_sicon1.svg';
import quick_sicon2 from '../../images/quick_sicon2.svg';
import quick_sicon3 from '../../images/quick_sicon3.svg';
import quick_sicon4 from '../../images/quick_sicon4.svg';
import cloud_kicon1 from '../../images/cloud_kicon1.svg';
import cloud_kicon2 from '../../images/cloud_kicon2.svg';
import cloud_kicon3 from '../../images/cloud_kicon3.svg';
import cloud_kicon4 from '../../images/cloud_kicon4.svg';
import fine_icon1_mob from '../../images/fine_icon1_mob.svg';
import fine_icon2_mob from '../../images/fine_icon2_mob.svg';
import fine_icon3_mob from '../../images/fine_icon3_mob.svg';
import fine_icon4_mob from '../../images/fine_icon4_mob.svg';
import quick_sicon1_mob from '../../images/quick_sicon1_mob.svg';
import quick_sicon2_mob from '../../images/quick_sicon2_mob.svg';
import quick_sicon3_mob from '../../images/quick_sicon3_mob.svg';
import quick_sicon4_mob from '../../images/quick_sicon4_mob.svg';
import cloud_kicon1_mob from '../../images/cloud_kicon1_mob.svg';
import cloud_kicon2_mob from '../../images/cloud_kicon2_mob.svg';
import cloud_kicon3_mob from '../../images/cloud_kicon3_mob.svg';
import cloud_kicon4_mob from '../../images/cloud_kicon4_mob.svg';
import whyicon7 from "../../images/why_rista_icon7.svg";
import whyicon8 from "../../images/why_rista_icon8.svg";
import whyicon9 from "../../images/why_rista_icon9.svg";
import whyicon10 from "../../images/why_rista_icon10.svg";
import whyicon11 from "../../images/why_rista_icon11.svg";
import whyicon12 from "../../images/why_rista_icon12.svg";
import whyicon14 from "../../images/why_rista_icon8.svg";
import whyicon15 from "../../images/why_rista_icon9.svg";
import whyicon10a from "../../images/why_rista_icon10.svg";
import whyicon11a from "../../images/why_rista_icon11.svg";
import whyicon12b from "../../images/why_rista_icon12.svg";
import hm_srvc_icon1 from '../../images/hm_srvc_icon1.svg';
import hm_srvc_icon2 from '../../images/hm_srvc_icon2.svg';
import hm_srvc_icon3 from '../../images/hm_srvc_icon3.svg';
import hm_srvc_icon4 from '../../images/hm_srvc_icon4.svg';
import cestar from '../../images/cestar.svg';
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Modal from 'react-modal';
import NiceSelect from '../../NiceSelect/NiceSelect';
import successcheck2 from '../../images/success-check2.svg';
import { Helmet } from 'react-helmet-async';
import BrandLogo from "../common/BrandLogo";
import SeamlessLogos from "../common/SeamlessLogos";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1,
  },
};

const RestaurantPointOfSaleSoftware = () => {
  const location = useLocation();
  const resetForms = () => {
    setFormData({
      name: "",
      mobileNumber: "",
      pincode: "",
      incomeSource: "",
      buss_age: "",
      buss_turnover: "",
      email: "",
    });
  };

  const [firstFormId, setFirstFormId] = useState("");
  // const [selectedImage, setSelectedImage] = useState(
  //   require("../../images/pos-webp/Amplify_Order_Value.webp")
  // );

  // const handleCardClick = (imageSrc) => {
  //   setSelectedImage(imageSrc);
  // };

  const [selectedImage, setSelectedImage] = useState({
    src: require("../../images/pos-webp/Amplify_Order_Value.webp"),
    alt: "Send Promotional & Transactional Messages with WhatsApp Marketing by Dotpe",
    title:
      "Send Promotional & Transactional Messages with WhatsApp Marketing by Dotpe",
  });

  const handleCardClick = (imageSrc, title, alt) => {
    setSelectedImage({
      src: imageSrc,
      title: title,
      alt: alt,
    });
  };
  const [scrolledUp, setScrolledUp] = useState(true); // State to track if user has scrolled up
  const [scrollPosition, setScrollPosition] = useState(0); // State to
  const [hasError, setHasError] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    countryCode: "+91",
    mobileNumber: "",
    pincode: "",
    incomeSource: "",
    buss_age: "",
    buss_turnover: "",
    email: "",
  });
  const [formErrors, setFormErrors] = useState({
    name: "",
    mobileNumber: "",
    pincode: "",
    incomeSource: "",
    buss_age: "",
    buss_turnover: "",
    email: "",
  });
  const inputName = useRef(null);
  const inputNumber = useRef(null);
  const inputEmail = useRef(null);
  //const countryCodes = ["+91", "+1", "+44", "+61", "+81", "+86"];
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [modalIsOpen2, setModalIsOpen2] = useState(false);

  const openModal1 = () => {
    setModalIsOpen1(true);
  };

  const openModal2 = () => {
    setModalIsOpen2(true);
    setModalIsOpen1(false);
  };

  const closeModal1 = () => {
    setModalIsOpen1(false);
  };

  const closeModal2 = () => {
    setModalIsOpen2(false);
  };

  const [emailSuggestions, setEmailSuggestions] = useState([]);
  const domains = ["gmail.com", "yahoo.com", "hotmail.com"];

  const validateSecondForm = () => {
    const errors = {};
    let formIsValid = true;
    const nameRegex = /^[A-Za-z\s]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const numberRegex = /^\d+$/;

    if (!formData.name || !formData.name.trim()) {
      formIsValid = false;
      errors.name = "Name is required";
    } else if (formData.name.trim().length < 3) {
      formIsValid = false;
      errors.name = "Name should have at least 3 characters";
    } else if (formData.name.trim().length > 30) {
      formIsValid = false;
      errors.name = "Name should have at max 30 characters";
    } else if (!nameRegex.test(formData.name.trim())) {
      formIsValid = false;
      errors.name = "Name should contain only letters and spaces";
    }

    if (!formData.email || !formData.email.trim()) {
      formIsValid = false;
      errors.email = "Email is required";
    } else if (!emailRegex.test(formData.email)) {
      formIsValid = false;
      errors.email = "Invalid email address";
    }

    if (!formData.mobileNumber || !formData.mobileNumber.trim()) {
      formIsValid = false;
      errors.mobileNumber = "Mobile Number is required";
    } else if (!numberRegex.test(formData.mobileNumber)) {
      formIsValid = false;
      errors.mobileNumber = "Invalid mobile number";
    } else if (formData.mobileNumber.trim().length !== 10) {
      formIsValid = false;
      errors.mobileNumber = "Mobile number must be exactly 10 digits";
    } else {
      // Ensure mobile number starts with 6, 7, 8, or 9
      const firstDigit = formData.mobileNumber.trim().charAt(0);
      if (!["6", "7", "8", "9"].includes(firstDigit)) {
        formIsValid = false;
        errors.mobileNumber = "Mobile number must start with 6, 7, 8, or 9";
      }
    }

    setFormErrors(errors);
    setHasError(!formIsValid);

    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    if (name === "name") {
      if (value === "" || /^[^\s]/.test(value)) {
        newValue = value;
      } else {
        newValue = value.trimStart();
      }
    } else if (name === "mobileNumber") {
      newValue = value.replace(/\D/g, "");

      if (newValue.length > 10) {
        newValue = newValue.slice(0, 10);
      }
    } else if (name === "email") {
      if (value.includes("@")) {
        const [localPart, domainPart] = value.split("@");
        setEmailSuggestions(
          domains
            .filter((domain) => domain.startsWith(domainPart))
            .map((domain) => `${localPart}@${domain}`)
        );
      } else {
        setEmailSuggestions([]);
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateSecondForm();
  };

  const formClassName = hasError ? "form-error form-group" : "form-group";
  const validateAndOpenModal2 = async () => {
    if (await validateSecondForm()) {
      const apiUrl = process.env.REACT_APP_FORM_ONE;
      const combinedData = {
        name: formData.name,
        phone: formData.mobileNumber,
        email: formData.email,
        source: window.location.href,
      };
      localStorage.setItem("mobile", formData.mobileNumber);
      console.log(combinedData);

      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json; charset=utf-8",
          },
          body: JSON.stringify(combinedData),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data);
        setFirstFormId(data.id);
        openModal2();
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  // const saveDetails = () => {
  //   closeModal2();
  // };
  const navigate = useNavigate();
  const validateForm = () => {
    const errors = {};
    let formIsValid = true;

    if (!formData.incomeSource || formData.incomeSource === "0") {
      formIsValid = false;
      errors.incomeSource = "Please select a business category";
    }

    if (!formData.role || formData.role === "0") {
      formIsValid = false;
      errors.role = "Please select your role";
    }

    setFormErrors(errors);
    return formIsValid;
  };

  const saveDetails = async () => {
    const isValid = validateForm();

    if (isValid) {
      const apiUrl = process.env.REACT_APP_FORM_ONE + "/" + firstFormId;
      const combinedData = {
        businessCategory: formData.incomeSource,
        role: formData.role,
      };
      console.log(combinedData);

      try {
        const response = await fetch(apiUrl, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(combinedData),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data);
        resetForms();
        closeModal2();
        navigate("/thankyou");
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const [currentTab, setCurrentTab] = useState("block-tab-1");

  const handlePrevClick = () => {
    switch (currentTab) {
      case "block-tab-1":
        setCurrentTab("block-tab-3");
        break;
      case "block-tab-2":
        setCurrentTab("block-tab-1");
        break;
      case "block-tab-3":
        setCurrentTab("block-tab-2");
        break;
      case "block-tab-4":
        setCurrentTab("block-tab-3");
        break;
      default:
        break;
    }
  };

  const handleNextClick = () => {
    switch (currentTab) {
      case "block-tab-1":
        setCurrentTab("block-tab-2");
        break;
      case "block-tab-2":
        setCurrentTab("block-tab-3");
        break;
      case "block-tab-3":
        setCurrentTab("block-tab-4");
        break;
      case "block-tab-4":
      default:
        break;
    }
  };

  const getClassNames = (tabName) => {
    switch (tabName) {
      case "block-tab-1":
        return "tab_left_single";
      case "block-tab-2":
        return "tab_middle_single";
      case "block-tab-3":
        return "tab_middle_single";
      case "block-tab-4":
        return "tab_right_single";

      default:
        return "";
    }
  };
  const [footerMobile, setFooterMobile] = useState("");
  const footerMobileRef = useRef(null);
  const [hasFooterMobileError, setHasFooterMobileError] = useState(false);
  const [hasFooterMobileErrorText, setHasFooterMobileErrorText] = useState("");
  const handleChangeFooterForm = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    if (name === "mobileNumberfooter") {
      console.log(newValue.length);
      newValue = value.replace(/\D/g, "");

      if (newValue.length > 10) {
        newValue = newValue.slice(0, 10);
      }
    }
    setFooterMobile(newValue);
  };
  const validateFooterMobileForm = () => {
    let formIsValid = true;
    const numberRegex = /^\d+$/;
    if (!footerMobile || !footerMobile.trim()) {
      formIsValid = false;
      setHasFooterMobileErrorText("Mobile Number is required");
    } else if (!numberRegex.test(footerMobile)) {
      formIsValid = false;
      setHasFooterMobileErrorText("Invalid mobile number");
    } else if (footerMobile.trim().length !== 10) {
      formIsValid = false;
      setHasFooterMobileErrorText("Mobile number must be exactly 10 digits");
    } else {
      const firstDigit = footerMobile.trim().charAt(0);
      if (!["6", "7", "8", "9"].includes(firstDigit)) {
        formIsValid = false;
        setHasFooterMobileErrorText(
          "Mobile number must start with 6, 7, 8, or 9"
        );
      }
    }
    setHasFooterMobileError(!formIsValid);

    return formIsValid;
  };
  return (
    <div>
      <Helmet>
        <title>
            Point of Sale Software | Rista Restaurant POS
        </title>
        <meta
          name="description"
          content="Restaurant POS Software offering billing, Online Order Integration, Inventory Management, Table Management, Restaurant Online Ordering system, WhatsApp Marketing & more."
        />
        <meta
          name="keywords"
          content="restaurant POS, restaurant software, food billing software, restaurant management system, restaurant billing software, restaurant inventory control software, restaurant payment system, restaurant QR, restaurant online ordering system"
        />
        <meta name="author" content="Rista Apps" />
        <meta
          property="og:description"
          content="Restaurant POS Software offering billing, Online Order Integration, Inventory Management, Table Management, Restaurant Online Ordering system, WhatsApp Marketing & more."
        />
        <meta
          property="og:image"
          content={require("../../images/logo-w.png")}
        />
      </Helmet>
      <HeaderDark />

      <section className="banner banner_renew_posprobg">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
              <div className="banner-content">
                {/* <span className="banner-subheading">RISTA - Point of Sale</span>
                <h1 className="banner-heading">
                  Optimize front-of-house efficiency with{" "}
                  <span>RISTA POS System.</span>
                </h1> */}

                <h1 className="banner-heading">
                  <span className="banner-subheading home_banner_subheading">
                    Front-of-the-house
                  </span>
                  Fast. Reliable. Secure.
                </h1>

                <div className="pospage_mobileimg">
                  <img
                    src={require("../../images/pos-webp/POS-Main.webp")}
                    alt="Rista pos Software for restaurant delivery view showcasing new orders, orders in process and dispatched orders"
                    title="Rista pos Software for restaurant delivery view showcasing new orders, orders in process and dispatched orders"
                  ></img>
                </div>
                <p className="banner-para">
                  Transform your restaurant's front-of-house with Rista POS.
                  Lightning-fast order processing, efficient table management,
                  and accurate sales tracking–Rista keeps operations smooth, so
                  you can focus on what matters–happy customers!
                </p>
                <div className="ban-bt">
                  <NavLink className="hm_fillbtn" onClick={openModal1}>
                    Get a Demo
                  </NavLink>
                </div>
                <div className="banner-mobile">
                  <div className="ban-last">
                    <img
                      className="ban-last-img"
                      src={require("../../images/star.png")}
                    ></img>{" "}
                    Top 20 Technology Startups <span>LinkedIn, 2023</span>
                  </div>
                  <div className="ban-last ban-last-1">
                    <img
                      className="ban-last-img"
                      src={require("../../images/star.png")}
                    ></img>{" "}
                    India’s Best POS <span>ET Hospitality World, 2022</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
              <div className="banner-image">
                <img
                  src={require("../../images/pos-webp/POS-Main.webp")}
                  alt="Rista pos Software for restaurant delivery view showcasing new orders, orders in process and dispatched orders"
                  title="Rista pos Software for restaurant delivery view showcasing new orders, orders in process and dispatched orders"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-2 sec2_pospro">
        <div className="container">
          <p className="para">
            Thousands <small>of restaurants</small> run and grow{" "}
            <img src={require("../../images/till-arrow.png")}></img>{" "}
            <small>on</small> Rista,
            <small> every day.</small>
          </p>
          <BrandLogo />
        </div>
      </section>

      <section className="sec-3 sec3_home sec3_pospro sec3_home_tabs">
        <div className="container">
          <h3 className="heading">
            <span>POS</span>sibilities at Play
          </h3>
          <h2 className="subheading">
            Everything your restaurant needs,
            <br />
            <span>from start to finish.</span>
          </h2>
        </div>

        <div className="pos_play_grid_outer">
          <div className="container">
            <div className="pos_playgrid">
              <div className="row">
                <div className="col-md-6">
                  <div className="pos_plygridinfos">
                    <h5>Billing Software</h5>
                    <h3>POS system that goes beyond billing</h3>
                    <ul>
                      <li>
                        Generate clear, itemized bills in seconds. Complete your
                        billing process in as few as 3 simple steps with our
                        fast, smooth, and efficient POS system.
                      </li>
                      <li>
                        Enjoy uninterrupted operations with offline
                        billing—smoothly punch orders without internet, and your
                        data will automatically sync once you're back online.
                      </li>
                      <li>
                        Send digital bills automatically via WhatsApp, analyze
                        customer data and run targeted marketing campaigns
                        through the same message thread.
                      </li>
                      <li>
                        Offer a variety of payment methods, including credit
                        cards, debit cards, contactless payments, and even split
                        payments.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pos_plygrid_img">
                    <span>
                      <img
                        src={require("../../images/pos-webp/Add-discounts-and-taxes.webp")}
                      ></img>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="pos_playgrid pos_playgrid_right">
              <div className="row">
                <div className="col-md-6">
                  <div className="pos_plygridinfos">
                    <h5>Order Management</h5>
                    <h3>Order HQ: One platform, All orders</h3>
                    <ul>
                      <li>
                        Simplify order management by handling incoming orders
                        from all sources on a single platform. Integrate with
                        Swiggy, Zomato, ONDC, and more to boost restaurant
                        sales.
                      </li>
                     
                      <li>
                        Automatically mark items out of stock, reducing
                        penalties and ensuring an up-to-date menu across
                        platforms with single-click stockout for multiple items
                        based on key ingredients.
                      </li>
                      <li>
                        Automatically mark food ready within a defined time
                        limit, allowing seamless, hands-free operation ideal for
                        cloud kitchens and high-volume online orders.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pos_plygrid_img">
                    <span>
                      <img
                        src={require("../../images/newimages-pos-page/posplay_img2.jpg")}
                      ></img>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="pos_playgrid">
              <div className="row">
                <div className="col-md-6">
                  <div className="pos_plygridinfos">
                    <h5>Catalog Management</h5>
                    <h3>Optimize menus for time and occasion</h3>
                    <ul>
                      <li>Create and manage multiple menus.</li>
                      <li>
                        Customize menu variations with images, prices, and
                        descriptions.
                      </li>
                      <li>
                        Effortlessly manage multiple dine-in areas with RISTA
                        POS.
                      </li>
                      <li>
                        Apply item-level and order-level taxes and charges for
                        accurate billing.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pos_plygrid_img">
                    <span>
                      <img
                        src={require("../../images/pos-webp/catalog-management_sec_pos_page.webp")}
                      ></img>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="pos_playgrid pos_playgrid_right">
              <div className="row">
                <div className="col-md-6">
                  <div className="pos_plygridinfos">
                    <h5>Inventory Management</h5>
                    <h3>Track. Stock. Rock. </h3>
                    <ul>
                      <li>Optimize inventory with centralized kitchens.</li>
                      <li>
                        Manage raw materials and never run out of stock with low
                        threshold stock alerts.
                      </li>
                      <li>
                        Raise purchase orders, and handle returns effortlessly.
                      </li>
                      <li>Track frequently ordered items across locations.</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pos_plygrid_img">
                    <span>
                      <img
                        src={require("../../images/pos-webp/Inventory_sec_pos_page.webp")}
                      ></img>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="pos_playgrid">
              <div className="row">
                <div className="col-md-6">
                  <div className="pos_plygridinfos">
                    <h5>Recipe Management</h5>
                    <h3>Standardize. Simplify.</h3>
                    <ul>
                      <li>
                        Track recipe-based consumption for semi-finished and
                        finished products with different recipes for various
                        branches and channels, enabling precise costing and
                        inventory management across multiple brands.
                      </li>
                      <li>
                        Clearly identify allergens within recipes, catering to
                        dietary restrictions and promoting customer confidence.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pos_plygrid_img">
                    <span>
                      <img
                        src={require("../../images/pos-webp/receipe_sec_pos_page.webp")}
                      ></img>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="pos_playgrid pos_playgrid_right pos_playgrid_right-aliyn">
              <div className="row">
                <div className="col-md-6">
                  <div className="pos_plygridinfos">
                    <h5>Superior Analytics</h5>
                    <h3> Data-driven insights </h3>
                    <ul>
                      <li>Dashboard and reports give you an eagle-eye view on your business down to the most-granular detail.</li>
                      <li>
                      Advanced filters - Apply advanced filters for deeper insights. Start with Sales reports now, with Inventory reports coming soon.
                      </li>
                      <li>
                      Custom Reports - Create tailored reports and make your analytics truly your own. Customize your reports by selecting, renaming, and rearranging columns to fit your needs. 
                      </li>
                    </ul>
                    {/* <div class="ban-bt"><NavLink onClick={openModal1}>Explore More</NavLink></div> */}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pos_plygrid_img">
                    <span>
                      <img
                        src={require("../../images/pos-webp/analytics-data-img.jpg")}
                      ></img>
                    </span>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="hm_tabsouter hm_hidesec">
          <div className="hmtabs_mainbox">
            <div className="rista_tabs">
              <div className="container">
                <ul className="nav nav-pills">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="pill"
                      href="#flamingo"
                      role="tab"
                      aria-controls="pills-flamingo"
                      aria-selected="true"
                    >
                      POS system that goes beyond billing.
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="pill"
                      href="#cuckoo"
                      role="tab"
                      aria-controls="pills-cuckoo"
                      aria-selected="false"
                    >
                      Order
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="pill"
                      href="#ostrich"
                      role="tab"
                      aria-controls="pills-ostrich"
                      aria-selected="false"
                    >
                      Menu & Cataglog{" "}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="pill"
                      href="#tropicbird"
                      role="tab"
                      aria-controls="pills-tropicbird"
                      aria-selected="false"
                    >
                      Inventory
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="pill"
                      href="#tropicbird-1"
                      role="tab"
                      aria-controls="pills-tropicbird-1"
                      aria-selected="false"
                    >
                      Recipe
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="container">
              <div className="tab_blockinfo_main">
                <div className="tabs_infos_box">
                  <div className="tab-content mt-3">
                    <div
                      className="tab-pane fade show active"
                      id="flamingo"
                      role="tabpanel"
                      aria-labelledby="flamingo-tab"
                    >
                      <div className="term">
                        <div className="term-1">
                          <span className="point_powerimg">
                            <img
                              alt="Restaurant's can send Digital Bill & promotional messages on WhatsApp with Rista POS marketing module"
                              title="Restaurant's can send Digital Bill & promotional messages on WhatsApp with Rista POS marketing module"
                              src={require("../../images/pos-webp/Send-digital-bills.webp")}
                            ></img>
                          </span>
                          {/* <h3>Butter smooth ordering experience</h3> */}
                          <p>
                            Generate clear, itemized bills in seconds. Complete
                            your billing process in as few as 3 simple steps
                            with our fast, smooth, and efficient POS system.
                          </p>
                        </div>
                        <div className="term-1">
                          <span className="point_powerimg">
                            <img
                              alt="Multiple brands option allows restaurant owners to run multiple brands for centralised billing management"
                              title="Multiple brands option allows restaurant owners to run multiple brands for centralised billing management"
                              src={require("../../images/pos-webp/Generate-multi-brand-invoices.webp")}
                            ></img>
                          </span>
                          {/* <h3>Support all aggregator integrations</h3> */}
                          <p>
                            Enjoy uninterrupted operations with offline
                            billing—smoothly punch orders without internet, and
                            your data will automatically sync once you're back
                            online.
                          </p>
                        </div>
                      </div>
                      <div className="term term-new">
                        <div className="term-1">
                          <span className="point_powerimg">
                            <img
                              alt="Showcasing Discount and Tax Feature of Rista Billing software for restaurant"
                              title="Showcasing Discount and Tax Feature of Rista Billing software for restaurant"
                              src={require("../../images/pos-webp/12.webp")}
                            ></img>
                          </span>
                          {/* <h3>Spend less time managing daily tasks</h3> */}
                          <p>
                            Send digital bills automatically via WhatsApp,
                            analyze customer data and run targeted marketing
                            campaigns through the same message thread.
                          </p>
                        </div>
                        <div className="term-1">
                          <span className="point_powerimg">
                            <img
                              alt="Integrate your favourite payment gateway with Rista restaurant billing software"
                              title="Integrate your favourite payment gateway with Rista restaurant billing software"
                              src={require("../../images/pos-webp/4Integrate-with-multiple-payment.webp")}
                            ></img>
                          </span>
                          {/* <h3>Customer Insights - Loyalty</h3> */}
                          <p>
                            Offer a variety of payment methods, including credit
                            cards, debit cards, contactless payments, and even
                            split payments.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="cuckoo"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      <div className="term">
                        <div className="term-1">
                          <span className="point_powerimg">
                            <img
                              alt=""
                              title=""
                              src={require("../../images/pos-webp/5Simplify-the-ordering.webp")}
                            ></img>
                          </span>
                          {/* <h3>Butter smooth ordering experience</h3> */}
                          <p>
                            Simplify order management by handling incoming
                            orders from all sources on a single platform.
                            Integrate with Swiggy, Zomato, ONDC, and more to
                            boost restaurant sales.
                          </p>
                        </div>
                        <div className="term-1">
                          <span className="point_powerimg">
                            <img
                              alt="My orders view of Rista Restaurant pos software"
                              title="My orders view of Rista Restaurant pos software"
                              src={require("../../images/pos-webp/6Integrate-with-Swiggy.webp")}
                            ></img>
                          </span>
                          {/* <h3>Support all aggregator integrations</h3> */}
                          <p>
                            Boost your brand's visibility and order acceptance
                            ratio with auto-acceptance of aggregator orders,
                            eliminating manual effort. (Second-point)
                          </p>
                        </div>
                      </div>
                      <div className="term term-new">
                        <div className="term-1">
                          <img
                            alt="Rista pos preference view allows restaurant owners to configure multiple integrations easily"
                            title="Rista pos preference view allows restaurant owners to configure multiple integrations easily"
                            src={require("../../images/pos-webp/7Effortlessly-manage.webp")}
                          ></img>
                          {/* <h3>Spend less time managing daily tasks</h3> */}
                          <p>
                            Automatically mark food ready within a defined time
                            limit, allowing seamless, hands-free operation ideal
                            for cloud kitchens and high-volume online orders.
                          </p>
                        </div>
                        <div className="term-1">
                          <img
                            alt="Analytics screen of Rista pos software with reports for Restaurant owners"
                            title="Analytics screen of Rista pos software with reports for Restaurant owners"
                            src={require("../../images/pos-webp/8Track-detailed-order.webp")}
                          ></img>
                          {/* <h3>Customer Insights - Loyalty</h3> */}
                          <p>
                            Track detailed order reports including commissions,
                            profit margins, taxes, and more for accurate
                            payouts.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="ostrich"
                      role="tabpanel"
                      aria-labelledby="ostrich-tab"
                    >
                      <div className="term">
                        <div className="term-1">
                          <img
                            alt="Rista Restaurant software with multiple brand menu option"
                            title="Rista Restaurant software with multiple brand menu option"
                            src={require("../../images/pos-webp/9Track-detailedorder.webp")}
                          ></img>
                          {/* <h3>Butter smooth ordering experience</h3> */}
                          <p>Create and manage multiple menus.</p>
                        </div>
                        <div className="term-1">
                          <img
                            alt="Menu management screen of Rista pos software for restaurant"
                            title="Menu management screen of Rista pos software for restaurant"
                            src={require("../../images/pos-webp/10.webp")}
                          ></img>
                          {/* <h3>Support all aggregator integrations</h3> */}
                          <p>
                            Customize menu variations with images, prices, and
                            descriptions.
                          </p>
                        </div>
                      </div>
                      <div className="term term-new">
                        <div className="term-1">
                          <img
                            alt="Table Management screen of Rista restaurant pos"
                            title="Table Management screen of Rista restaurant pos"
                            src={require("../../images/pos-webp/11.webp")}
                          ></img>
                          {/* <h3>Spend less time managing daily tasks</h3> */}
                          <p>
                            Effortlessly manage multiple dine-in areas with
                            RISTA POS.
                          </p>
                        </div>
                        <div className="term-1">
                          <img
                            alt="Billing screen of Rista POS software with tax and charges configuration"
                            title="Billing screen of Rista POS software with tax and charges configuration"
                            src={require("../../images/pos-webp/12.webp")}
                          ></img>
                          {/* <h3>Customer Insights - Loyalty</h3> */}
                          <p>
                            Apply item-level and order-level taxes and charges
                            for accurate billing.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="tropicbird"
                      role="tabpanel"
                      aria-labelledby="tropicbird-tab"
                    >
                      <div className="term">
                        <div className="term-1">
                          <img
                            src={require("../../images/pos-webp/13.webp")}
                          ></img>
                          <p>Optimize inventory with centralized kitchens.</p>
                        </div>
                        <div className="term-1">
                          <img
                            alt="Low Stock Alerts with Rista Restaurant billing software"
                            title="Low Stock Alerts with Rista Restaurant billing software"
                            src={require("../../images/pos-webp/14.webp")}
                          ></img>
                          <p>
                            Manage raw materials and never run out of stock with
                            low threshold stock alerts.
                          </p>
                        </div>
                      </div>
                      <div className="term term-new">
                        <div className="term-1">
                          <img
                            src={require("../../images/pos-webp/15.webp")}
                          ></img>

                          <p>
                            Raise purchase orders, and handle returns
                            effortlessly.
                          </p>
                        </div>
                        <div className="term-1">
                          <img
                            src={require("../../images/pos-webp/16.webp")}
                          ></img>
                          <p>
                            Track frequently ordered items across locations.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="tropicbird-1"
                      role="tabpanel"
                      aria-labelledby="tropicbird-1-tab"
                    >
                      <div className="term ">
                        <div className="term-1">
                          <img
                            src={require("../../images/pos-webp/17.webp")}
                          ></img>

                          <p>
                            Standardize recipes with precise measurements and
                            instructions for foolproof execution.
                          </p>
                        </div>
                        <div className="term-1">
                          <img
                            src={require("../../images/pos-webp/18.webp")}
                          ></img>

                          <p>
                            Analyze ingredient usage with recipe costing to
                            identify areas for optimization.
                          </p>
                        </div>
                      </div>
                      <div className="term term-new">
                        <div className="term-1">
                          <img
                            src={require("../../images/pos-webp/19.webp")}
                          ></img>

                          <p>
                            Categorize recipes by course, dietary needs, and
                            prep time for effortless menu planning.
                          </p>
                        </div>
                        <div className="term-1">
                          <img
                            src={require("../../images/pos-webp/20.webp")}
                          ></img>
                          <p>
                            Manage allergens and substitutions for informed
                            customer service.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-4 sec4_pospro">
        <div className="container">
          <h3 className="heading">Outlet Types</h3>
          <h2 className="subheading">
            We’ve got a solution for you,{" "}
            <span>Regardless of what and how you serve!</span>
          </h2>
          <div className="sec4_prostabs sec4_prostabs_newtabs">
            <div className="row">
              <div className="col-12">
                <ul className="nav nav-pills">
                  <li className="nav-item">
                    <button
                      className={`nav-link ${
                        currentTab === "block-tab-1" ? "active" : ""
                      }`}
                      onClick={() => setCurrentTab("block-tab-1")}
                    >
                      Fine Dining
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link ${
                        currentTab === "block-tab-2" ? "active" : ""
                      }`}
                      onClick={() => setCurrentTab("block-tab-2")}
                    >
                      Quick Service Restaurants
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link ${
                        currentTab === "block-tab-3" ? "active" : ""
                      }`}
                      onClick={() => setCurrentTab("block-tab-3")}
                    >
                      Cloud Kitchens
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link ${
                        currentTab === "block-tab-4" ? "active" : ""
                      }`}
                      onClick={() => setCurrentTab("block-tab-4")}
                    >
                      Pubs, Bars, Clubs and Lounges
                    </button>
                  </li>
                </ul>
                <div className="tab-content mt-3">
                  <div
                    className={`tab-pane fade ${
                      currentTab === "block-tab-1" ? "show active" : ""
                    }`}
                    id="block-tab-1"
                  >
                    <div className="bg-head">
                      <h4>Fine Dine Restaurant</h4>
                      <h5>Enhance your average order value by 20% to 40%</h5>
                    </div>
                    <div className="block blockone_maintop">
                      <div className="block-1 blockone_main">
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="block-3">
                              <span className="deskicon">
                                <img src={fine_icon1} alt="icon" />
                              </span>
                              <img
                                src={fine_icon1_mob}
                                alt="icon"
                                className="outletmob"
                              />
                              <h3>Seamless reservations</h3>
                              <p>
                                Get robust reservation APIs to integrate with
                                your website.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="block-3">
                              <span className="deskicon">
                                <img src={fine_icon2} alt="icon" />
                              </span>
                              <img
                                src={fine_icon2_mob}
                                alt="icon"
                                className="outletmob"
                              />
                              <h3>Kitchen display system</h3>
                              <p>
                                Make your kitchen operations smoother with a
                                dynamic recipe display for kitchen staff.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="block-3">
                              <span className="deskicon">
                                <img src={fine_icon3} alt="icon" />
                              </span>
                              <img
                                src={fine_icon3_mob}
                                alt="icon"
                                className="outletmob"
                              />
                              <h3>Waiter application</h3>
                              <p>
                                Empower your staff to take and manage orders
                                seamlessly, with real-time updates on order
                                status and customer information.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="block-3">
                              <span className="deskicon">
                                <img src={fine_icon4} alt="icon" />
                              </span>
                              <img
                                src={fine_icon4_mob}
                                alt="icon"
                                className="outletmob"
                              />
                              <h3>Reward Your Regulars</h3>
                              <p>
                                Incentivize return visits with exclusive offers
                                and personalized rewards with Rista Loyalty
                                Cards.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="block-2">
                        <div className="block2_tabimg">
                          {" "}
                          <img
                            src={require("../../images/pos-webp/Fine.webp")}
                            alt="Delicious Food Served in a dish in fine dine restaurant using Rista restaurant pos software"
                            title="Delicious Food Served in a dish in fine dine restaurant using Rista restaurant pos software"
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade ${
                      currentTab === "block-tab-2" ? "show active" : ""
                    }`}
                    id="block-tab-2"
                  >
                    <div className="bg-head">
                      <h4>Quick service restaurants</h4>
                      <h5>
                        Wave off extended lines & waits, no matter the rush
                        hour.
                      </h5>
                    </div>
                    <div className="block blocktwo_maintop">
                      <div className="block-1 mincontt">
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="block-3 block_2-topbox">
                              <span className="deskicon">
                                <img src={quick_sicon1} alt="icon" />
                              </span>
                              <img
                                src={quick_sicon1_mob}
                                alt="icon"
                                className="outletmob"
                              />
                              <h3>
                                Get 3X faster billing, even in offline mode
                              </h3>
                              <p>
                                Accelerate the billing process with simple
                                navigation to checkout
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="block-3  block_2-topbox">
                              <span className="deskicon">
                                <img src={quick_sicon2} alt="icon" />
                              </span>
                              <img
                                src={quick_sicon2_mob}
                                alt="icon"
                                className="outletmob"
                              />
                              <h3>
                                Optimize resource allocation with menu
                                scheduling
                              </h3>
                              <p>
                                Enhance customer experience by ensuring popular
                                items’ availability at peak hours
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="block-3">
                              <span className="deskicon">
                                <img src={quick_sicon3} alt="icon" />
                              </span>
                              <img
                                src={quick_sicon3_mob}
                                alt="icon"
                                className="outletmob"
                              />
                              <h3>
                                Efficient inventory management with real-time
                                stock updates
                              </h3>
                              <p>
                                Reduce stockouts, minimize wastage, improve
                                order accuracy, and streamline inventory
                                management.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="block-3">
                              <span className="deskicon">
                                <img src={quick_sicon4} alt="icon" />
                              </span>
                              <img
                                src={quick_sicon4_mob}
                                alt="icon"
                                className="outletmob"
                              />
                              <h3>
                                Manage queues with accurate order displaying
                                system
                              </h3>
                              <p>
                                Reduce wait times, and streamline kitchen
                                operations for restaurants.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="block-2">
                        <div className="block2_tabimg block2_tabimg2">
                          <img
                            src={require("../../images/pos-webp/QSR.webp")}
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade ${
                      currentTab === "block-tab-3" ? "show active" : ""
                    }`}
                    id="block-tab-3"
                  >
                    <div className="bg-head">
                      <h4>Cloud kitchens</h4>
                      <h5>Keep your guests on cloud nine</h5>
                    </div>
                    <div className="block blockthree_maintop">
                      <div className="block-1 mincontt2">
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="block-3">
                              <span className="deskicon">
                                <img src={cloud_kicon1} alt="icon" />
                              </span>
                              <img
                                src={cloud_kicon1_mob}
                                alt="icon"
                                className="outletmob"
                              />
                              <h3>Centralized kitchen control</h3>
                              <p>
                                Take orders at the bar or tables with handheld
                                devices, especially beneficial during rush
                                hours.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="block-3">
                              <span className="deskicon">
                                <img src={cloud_kicon2} alt="icon" />
                              </span>
                              <img
                                src={cloud_kicon2_mob}
                                alt="icon"
                                className="outletmob"
                              />
                              <h3>Manage multiple brands</h3>
                              <p>
                                Handle all orders across brands on a unified
                                dashboard.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="block-3">
                              <span className="deskicon">
                                <img src={cloud_kicon3} alt="icon" />
                              </span>
                              <img
                                src={cloud_kicon3_mob}
                                alt="icon"
                                className="outletmob"
                              />
                              <h3>Streamlined order management</h3>
                              <p>
                                Accept and manage all orders from multiple
                                aggregators on a single screen.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="block-3">
                              <span className="deskicon">
                                <img src={cloud_kicon4} alt="icon" />
                              </span>
                              <img
                                src={cloud_kicon4_mob}
                                alt="icon"
                                className="outletmob"
                              />
                              <h3>Lucrative deals & promotions</h3>
                              <p>
                                Craft tailored loyalty programs using precise
                                customer analytics.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="block-2">
                        <div className="block2_tabimg block2_tabimg3">
                          <img
                            src={require("../../images/pos-webp/Cloud.webp")}
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`tab-pane fade ${
                      currentTab === "block-tab-4" ? "show active" : ""
                    }`}
                    id="block-tab-4"
                  >
                    <div className="bg-head">
                      <h4>Pubs, Bars, Clubs, and Lounges</h4>
                      <h5>Push the bar on customer service.</h5>
                    </div>
                    <div className="block blockthree_maintop">
                      <div className="block-1 mincontt2">
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="block-3">
                              <span className="deskicon">
                                <img src={cloud_kicon1} alt="icon" />
                              </span>
                              <img
                                src={cloud_kicon1_mob}
                                alt="icon"
                                className="outletmob"
                              />{" "}
                              <h3>Multi-terminal billing</h3>
                              <p>
                                Manage inventory for all your outlets from a
                                central dashboard.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="block-3">
                              <span className="deskicon">
                                <img src={cloud_kicon2} alt="icon" />
                              </span>
                              <img
                                src={cloud_kicon2_mob}
                                alt="icon"
                                className="outletmob"
                              />
                              <h3>Liquor inventory management</h3>
                              <p>
                                Accurately track liquor usage to prevent
                                over-pouring and waste. Monitor consumption,
                                pilferage, and expenses effectively.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="block-3">
                              <span className="deskicon">
                                <img src={cloud_kicon3} alt="icon" />
                              </span>
                              <img
                                src={cloud_kicon3_mob}
                                alt="icon"
                                className="outletmob"
                              />
                              <h3>Tailored bill splits</h3>
                              <p>
                                Divide the bill evenly, by percentage, or even
                                by specific items for a truly customized
                                experience.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="block-3">
                              <span className="deskicon">
                                <img src={cloud_kicon4} alt="icon" />
                              </span>
                              <img
                                src={cloud_kicon4_mob}
                                alt="icon"
                                className="outletmob"
                              />
                              <h3>Promotions and discounts</h3>
                              <p>
                                Apply happy hour pricing, special promotions,
                                and discounts automatically, ensuring
                                consistency and accuracy.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="block-2">
                        <div className="block2_tabimg block2_tabimg3">
                          <img
                            src={require("../../images/pos-webp/Pubs_bar_and_Club.webp")}
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="block-tab-3"
                    role="tabpanel"
                    aria-labelledby="block-tab-3-tab"
                  >
                    <div className="bg-head">
                      <h4>Promotions and Discounts</h4>
                      <h5>
                        Apply happy hour pricing, special promotions, and
                        discounts automatically, ensuring consistency and
                        accuracy.
                      </h5>
                    </div>
                    <div className="block">
                      <div className="block-1">
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="block-3">
                              <img src={require("../../images/ear.png")}></img>
                              <h3>Seamless Reservations</h3>
                              <p>
                                Get robust reservation APIs to integrate with
                                your website.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="block-3">
                              <img src={require("../../images/ear.png")}></img>
                              <h3>Kitchen Display System</h3>
                              <p>
                                Make your kitchen operations smoother with a
                                dynamic recipe display for kitchen staff.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="block-3">
                              <img src={require("../../images/ear.png")}></img>
                              <h3>Waiter Application</h3>
                              <p>
                                Ensure your staff is always updated with
                                recent/ongoing order details
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="block-3">
                              <img src={require("../../images/ear.png")}></img>
                              <h3>Digital Bills on WhatsApp</h3>
                              <p>
                                Get details like customer preferences, ratings,
                                average order value etc, and craft personalized
                                loyalty programs
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="block-2">
                        <div className="block2_tabimg block2_tabimg3">
                          {/* <img src={require("../../images/sec-4-img.png")}></img> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="sec-4-bt">
                <div className="getquote">
                  <NavLink onClick={openModal1}>Get a Demo</NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid outlets_tabsmain">
          <div className="row">
            <div className="col-md-12">
              <div className="tabs_btns">
                {currentTab !== "block-tab-1" && (
                  <button
                    onClick={handlePrevClick}
                    className={getClassNames(currentTab)}
                  >
                    {/* {currentTab === "block-tab-2"
                      ? "Fine Dining"
                      : "Quick Service"}{" "} */}
                    {currentTab === "block-tab-2"
                      ? "Fine Dining"
                      : currentTab === "block-tab-3"
                      ? "Quick Service" // Label for tab 2
                      : currentTab === "block-tab-4"
                      ? "Cloud Kitchens" // Label for tab 3
                      : "PBCLs"}
                    <span>
                      <img src={tab_arrow_right} alt="Shape" />
                    </span>
                  </button>
                )}
                {currentTab !== "block-tab-4" && (
                  <button
                    onClick={handleNextClick}
                    className={getClassNames(currentTab)}
                  >
                    {/* {currentTab === "block-tab-1"
                      ? "Quick Service"
                      : currentTab === "block-tab-2"
                      ? "Cloud Kitchens"
                      : "Fine Dining"} */}
                    {currentTab === "block-tab-1"
                      ? "Quick Service" // Label for tab 2
                      : currentTab === "block-tab-2"
                      ? "Cloud Kitchens" // Label for tab 3
                      : currentTab === "block-tab-3"
                      ? "PBCLs" // Label for tab 4
                      : "Fine Dining"}
                    <span>
                      <img src={tab_arrow_right} alt="Shape" />
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-5 sec-5_pospage">
        <div className="container">
          {/* <h3 className="heading">Rista POS</h3> */}

          <div className="gobeyond_slide">
            <OwlCarousel
              items={4}
              className="owl-theme"
              loop={true}
              autoplay={true}
              nav={false}
              dots={false}
              margin={0}
              responsive={{
                0: { items: 1, nav: false, dots: false }, // Set 1 item for small screens (width < 600px)
                600: { items: 1, nav: false, dots: false }, // Set 2 items for medium screens (600px <= width < 960px)
                1025: { items: 1 }, // Set 4 items for larger screens (width >= 960px)
              }}
            >
              <div className="item">
                <h2 className="subheading">
                  Go beyond transactions{" "}
                  <span>Gain a 360° view of your customers.</span>
                </h2>

                <div className="rista_go_newinfos">
                  {/* <h3>Build Lasting Customer Relationships</h3> */}
                  <p>
                    Personalize your service with detailed customer profiles,
                    preferences, and purchase histories to offer tailored
                    promotions and rewards. Foster loyalty with targeted
                    marketing campaigns designed to keep patrons coming back.
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="sec5a_contt">
                  <div className="hm_heading hm_heading_big hm_heading_blue">
                    <h3>
                      Stay connected <br />
                      while being offline.
                    </h3>
                    <p>
                      Automatically switch to local server mode during internet
                      outages, ensuring uninterrupted service.
                    </p>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>

          {/* <div className="sec3_n_btns">
            <NavLink to="/" className="hm_fillbtn" onClick={openModal1}>
              Get a Demo
            </NavLink>
          
          </div> */}

          <div className="row mid hm_hidesec">
            <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
              <div className="accordion accordion_rista" id="faqaccord7">
                <div className="card">
                  <div className="card-header" id="faqhead1">
                    <a
                      href="#"
                      className="btn btn-header-link"
                      data-toggle="collapse"
                      data-target="#faq1"
                      aria-expanded="true"
                      aria-controls="faq1"
                      // onClick={() =>
                      //   handleCardClick(
                      //     require("../../images/pos-webp/Amplify_Order_Value.webp")
                      //   )
                      // }
                      onClick={() =>
                        handleCardClick(
                          require("../../images/pos-webp/Amplify_Order_Value.webp"),
                          "Capture your customer feedback on WhatsApp",
                          "Capture your customer feedback on WhatsApp"
                        )
                      }
                    >
                      Amplify order value to increase revenue
                    </a>
                  </div>

                  <div
                    id="faq1"
                    className="collapse show"
                    aria-labelledby="faqhead1"
                    data-parent="#faqaccord7"
                  >
                    <div className="card-body">
                      Use integrated marketing tools to increase average order
                      value, optimize table turnover, and highlight your most
                      profitable menu items.
                      {selectedImage && (
                        <div className="sec-5-img show-1">
                          <img
                            src={selectedImage.src}
                            alt={selectedImage.alt}
                            title={selectedImage.title}
                          />{" "}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqhead2">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq2"
                      aria-expanded="true"
                      aria-controls="faq2"
                      // onClick={() =>
                      //   handleCardClick(
                      //     require("../../images/pos-webp/Deliver_Superior_Guest_Experience.webp")
                      //   )
                      // }

                      onClick={() =>
                        handleCardClick(
                          require("../../images/pos-webp/Deliver_Superior_Guest_Experience.webp"),
                          "Capture your customer feedback on WhatsApp",
                          "Capture your customer feedback on WhatsApp"
                        )
                      }
                    >
                      Deliver superior guest experience
                    </a>
                  </div>

                  <div
                    id="faq2"
                    className="collapse"
                    aria-labelledby="faqhead2"
                    data-parent="#faqaccord7"
                  >
                    <div className="card-body">
                      Provide sophisticated service with a mobile POS system
                      that accelerates guest seating, reduces order errors,
                      keeps lines flowing smoothly, and ensures seamless payment
                      processing.
                      {selectedImage && (
                        <div className="sec-5-img show-1">
                          <img
                            src={selectedImage.src}
                            alt={selectedImage.alt}
                            title={selectedImage.title}
                          />{" "}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqhead3">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq3"
                      aria-expanded="true"
                      aria-controls="faq3"
                      // onClick={() =>
                      //   handleCardClick(
                      //     require("../../images/pos-webp/Improve_Bottom_Line_On_The_Go.webp")
                      //   )
                      // }
                      onClick={() =>
                        handleCardClick(
                          require("../../images/pos-webp/Improve_Bottom_Line_On_The_Go.webp"),
                          "Capture your customer feedback on WhatsApp",
                          "Capture your customer feedback on WhatsApp"
                        )
                      }
                    >
                      Build Lasting Customer Relationships
                    </a>
                  </div>

                  <div
                    id="faq3"
                    className="collapse"
                    aria-labelledby="faqhead3"
                    data-parent="#faqaccord7"
                  >
                    <div className="card-body">
                      Personalize your service with detailed customer profiles,
                      preferences, and purchase histories to offer tailored
                      promotions and rewards. Foster loyalty with targeted
                      marketing campaigns designed to keep patrons coming back.
                      {selectedImage && (
                        <div className="sec-5-img show-1">
                          <img
                            src={selectedImage.src}
                            alt={selectedImage.alt}
                            title={selectedImage.title}
                          />{" "}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12 hide-1">
              {selectedImage && (
                <div className="sec-5-img">
                  <img
                    src={selectedImage.src}
                    alt={selectedImage.alt}
                    title={selectedImage.title}
                  />{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* <section className="sec5a_posinfos">
        <div className="container">
          <div className="sec5a_contt">
            <div className="hm_heading hm_heading_big hm_heading_blue">
              <h3>Stay connected while being offline.</h3>
              <p>
                Automatically switch to local server mode during internet
                outages, ensuring uninterrupted service.
              </p>
            </div>
            <div className="sec3_n_btns">
              <NavLink to="/" className="hm_fillbtn" onClick={openModal1}>
                Get a Demo
              </NavLink>
             
            </div>
          </div>
        </div>
      </section> */}

      <section className="sec3new sec_space postestimonial direct_ordr_most inverntry_m_review set_invt_review_sectionn set_big_slides_section">
        <div className="container">
          <div className="sec3new_inner">
            <div className="hm_heading">
              <h5> Industry Leaders Review</h5>
              <h3>
                Why do leading restaurants choose Rista for efficient inventory
                management?
              </h3>
            </div>
          </div>

          <div className="inventery_mcrousel">
            <div className="sec-6 deordr_owl_slides">
              <div className="owl-slider">
                <OwlCarousel
                  items={4}
                  className="owl-theme"
                  loop={true}
                  autoplay={true}
                  nav={true}
                  dots={true}
                  margin={0}
                  responsive={{
                    0: { items: 1, nav: true, dots: true }, // Set 1 item for small screens (width < 600px)
                    600: { items: 1, nav: true, dots: true }, // Set 2 items for medium screens (600px <= width < 960px)
                    1025: { items: 1 }, // Set 4 items for larger screens (width >= 960px)
                  }}
                >
                  <div className="item">
                    <div className="sec-2-item sec-2-item-1">
                      <div className="test-1">
                        <img
                          className="client-img"
                          src={require("../../images/testimony-logos/T-Biriyani-Blues.webp")}
                        ></img>
                        <p>
                          By far the best inventory management system in the
                          market which made our operations smooth & hassle free.
                        </p>
                      </div>
                      <div className="test-2">
                        <div className="client-name">
                          <h5>Aparna Andrews</h5>
                          <p>Co-Founder, Biryani Blues</p>
                        </div>
                        <img
                          src={require("../../images/testimony-people/Niryani-Blues-Aparna-Andrews.webp")}
                        ></img>
                      </div>
                      <img
                        className="test-img"
                        src={require("../../images/test.png")}
                      ></img>
                    </div>
                  </div>
                  {/* <div className="item">
                    <div className="sec-2-item sec-2-item-1">
                      <div className="test-1">
                        <img
                          className="client-img"
                          src={require("../../images/testimony-logos/T-Meghana-Foods.webp")}
                        ></img>
                        <p>
                          Our 5-year association with Rista POS has been of
                          immense importance in our growth journey. They've not
                          only been our partner but also a problem-solving
                          force. They've been a key contributor to our success.
                        </p>
                      </div>
                      <div className="test-2">
                        <div className="client-name">
                          <h5>Vikash Prasad</h5>
                          <p>Founder, Meghana Biryani</p>
                        </div>
                        <img
                          src={require("../../images/testimony-people/Meghana-Foods-Vikash-Prasad.webp")}
                        ></img>
                      </div>
                      <img
                        className="test-img"
                        src={require("../../images/test.png")}
                      ></img>
                    </div>
                  </div> */}
                  <div className="item">
                    <div className="sec-2-item sec-2-item-1">
                      <div className="test-1">
                        <img
                          className="client-img"
                          src={require("../../images/testimony-logos/T-Truffles.webp")}
                        ></img>
                        <p>
                          We have been using Rista for the last 5 years, our
                          all-in-one tech partner. Rista's advanced inventory &
                          aggregator integrations have helped us improve our
                          operational efficiency. The real-time stock updates
                          have helped us reduce order cancellations.
                        </p>
                      </div>
                      <div className="test-2">
                        <div className="client-name">
                          <h5>Avinash Bajaj</h5>
                          <p>Managing Director, Truffles</p>
                        </div>
                        <img
                          src={require("../../images/testimony-people/Avinash-Avinash-Bajaj.webp")}
                        ></img>
                      </div>
                      <img
                        className="test-img"
                        src={require("../../images/test.png")}
                      ></img>
                    </div>
                  </div>
                  {/* <div className="item">
                    <div className="sec-2-item sec-2-item-1">
                      <div className="test-1">
                        <img
                          className="client-img"
                          src={require("../../images/testimony-logos/T-Indigo.webp")}
                        ></img>
                        <p>
                          At Indigo, we've been using Rista for the past year &
                          a half, & our experience has been nothing short of
                          fabulous. Both their POS & MMS work beautifully, & the
                          reports are crisp. Their service support is brilliant,
                          & I have no hesitation in saying that Rista is a great
                          product for the F&B business.
                        </p>
                      </div>
                      <div className="test-2">
                        <div className="client-name">
                          <h5>Anurag Katriar</h5>
                          <p>Founder, Indigo</p>
                        </div>
                        <img
                          src={require("../../images/testimony-people/Indigo-Anurag-Katriar.webp")}
                        ></img>
                      </div>
                      <img
                        className="test-img"
                        src={require("../../images/test.png")}
                      ></img>
                    </div>
                  </div> */}
                  <div className="item">
                    <div className="sec-2-item sec-2-item-1">
                      <div className="test-1">
                        <img
                          className="client-img"
                          src={require("../../images/testimony-logos/T-Mod.webp")}
                        ></img>
                        <p>
                          I've always believed in digitization for efficiency, &
                          Rista POS has been the perfect partner in helping us
                          scale our business. They've been a true growth
                          enabler, making our journey smoother & more
                          successful.
                        </p>
                      </div>
                      <div className="test-2">
                        <div className="client-name">
                          <h5>Tarak Bhattacharya</h5>
                          <p>Executive Director & CEO, Mad Over Donuts</p>
                        </div>
                        <img
                          src={require("../../images/testimony-people/MOD-Tarak-Bhattacharya.webp")}
                        ></img>
                      </div>
                      <img
                        className="test-img"
                        src={require("../../images/test.png")}
                      ></img>
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-9">
        <div className="container">
          <h2 className="subheading">50+ Seamless Integrations</h2>
          <h3 className="heading">A unified setup for your win!</h3>
        </div>
        <div>
          <SeamlessLogos />
        </div>
      </section>

      {/* <section className="sec-7">
        <div className="container">
          <div className="sec-7-block">
            <div className="sec-1-block">
              <div className="sec-7-img">
                <img src={require("../../images/icon-1.png")}></img>
                <h3>
                  Accessible <br></br>On-the-Go
                </h3>
                <p>Access our tools from anywhere, on any device.</p>
              </div>
            </div>
            <div className="sec-1-block">
              <div className="sec-7-img">
                <img src={require("../../images/icon-2.png")}></img>
                <h3>Effortless Installation</h3>
                <p>Maximize guest time, minimize training time</p>
              </div>
            </div>
            <div className="sec-1-block">
              <div className="sec-7-img">
                <img src={require("../../images/icon-3.png")}></img>
                <h3>4/7 Award Winning Support</h3>
                <p>Maximize guest time, minimize training time</p>
              </div>
            </div>
            <div className="sec-1-block">
              <div className="sec-7-img">
                <img src={require("../../images/icon-4.png")}></img>
                <h3>Frequent Enhancements</h3>
                <p>Exciting new features introduced every month.</p>
              </div>
            </div>
            <div className="sec-1-block sec-7-img-ch">
              <div className="sec-7-img">
                <img src={require("../../images/icon-5.png")}></img>
                <h3>99.99% Uptime Guaranteed</h3>
                <p>
                  Manage operations smoothly & keep them up & running, always.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="sec5new sec5new_propage sec_space sec5_posplaybg">
        <div className="sec5new_top">
          <div className="container">
            {/* <h3 className="heading"></h3> */}
            <div className="posblckhm_heading">
              {" "}
              <h2 className="subheading">
                Thinking of switching <span>to Rista POS?</span>
              </h2>
              <p>
                It is simple and easy. From data migration to payment setup and
                everything in between - our experts will guide you every step of
                the way.
              </p>
            </div>

            <div className="sec5new_inner poply_desk">
              <div className="sec5newa_grid">
                <div className="row">
                  <div className="col-md-4">
                    <div className="hm_srvccards">
                      <div className="hm_srvccard_icon">
                        <span>
                          <img src={hm_srvc_icon2} alt="Icon" />
                        </span>
                      </div>
                      <div className="hm_srvc_infos">
                        <h4>
                          Quickest Setup <br />
                          and Training
                        </h4>
                        <p>
                          Get up and running quickly with our intuitive system.
                          Minimise training time for your staff and maximize
                          guest time, from day one.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="hm_srvccards">
                      <div className="hm_srvccard_icon hm_srvccard_icon_white">
                        <span>
                          <img src={whyicon14} alt="Icon" />
                        </span>
                      </div>
                      <div className="hm_srvc_infos">
                        <h4>
                          Unparalled <br />
                          24x7 Support
                        </h4>
                        <p>
                          Receive exceptional support anytime. Our team of
                          experts is passionate about your success, and help you
                          get the most out of Rista.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="hm_srvccards">
                      <div className="hm_srvccard_icon hm_srvccard_icon_white">
                        <span>
                          <img src={whyicon15} alt="Icon" />
                        </span>
                      </div>
                      <div className="hm_srvc_infos">
                        <h4>
                          Enterprise-Grade <br />
                          Security
                        </h4>
                        <p>
                          Rest assured, your data is safe. Rista utilizes
                          industry-leading security protocols and data
                          encryption to protect your sensitive information.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="hm_srvccards hm_srvccards2">
                      <div className="hm_srvccard_icon hm_srvccard_icon_white">
                        <span>
                          <img src={whyicon10a} alt="Icon" />
                        </span>
                      </div>
                      <div className="hm_srvc_infos">
                        <h4>
                          Scalability,
                          <br /> built-in
                        </h4>
                        <p>
                          Rista's robust architecture effortlessly grows with
                          your business. Manage your operations with confidence
                          - from a single location to a nationwide chain.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="hm_srvccards hm_srvccards2">
                      <div className="hm_srvccard_icon  hm_srvccard_icon_white">
                        <span>
                          <img src={whyicon11a} alt="Icon" />
                        </span>
                      </div>
                      <div className="hm_srvc_infos">
                        <h4>
                          Continuous
                          <br /> Innovation
                        </h4>
                        <p>
                          Rista is constantly evolving. Expect exciting new
                          features and updates rolled out frequently, ensuring
                          you always access to the latest.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="hm_srvccards hm_srvccards2">
                      <div className="hm_srvccard_icon  hm_srvccard_icon_white">
                        <span>
                          <img src={whyicon12b} alt="Icon" />
                        </span>
                      </div>
                      <div className="hm_srvc_infos">
                        <h4>
                          Accessible​
                          <br /> On-the-Go
                        </h4>
                        <p>
                          Manage your restaurant from anywhere, anytime. Access
                          Rista from any device, keeping you in control and
                          connected at all times.
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-md-2">
                    <div className="hm_srvccards">
                      <div className="hm_srvccard_icon">
                        <span>
                          <img src={hm_srvc_icon5} alt="Icon" />
                        </span>
                      </div>
                      <div className="hm_srvc_infos">
                        <h4>
                          99.99% Uptime <br />
                          Guaranteed
                        </h4>
                        <p>
                          Manage operations smoothly & keep them up & running,
                          always.
                        </p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="sec4newa_grid sec4newa_grid_pospage">
              <div className="row">
                <div className="col-md-4">
                  <div className="hm_whycards">
                    <div className="hm_whycard_icon">
                      <span>
                        {" "}
                        <img src={whyicon7} alt="Icon" />
                      </span>
                    </div>
                    <div className="hm_why_infos">
                      <h4>Quickest setup and training</h4>
                      <p>
                        Get up and running quickly with our intuitive system.
                        Minimise training time for your staff and maximize guest
                        time, from day one.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="hm_whycards">
                    <div className="hm_whycard_icon">
                      <span>
                        {" "}
                        <img src={whyicon8} alt="Icon" />
                      </span>
                    </div>
                    <div className="hm_why_infos">
                      <h4>Unparalleled 24x7 support</h4>
                      <p>
                        Receive exceptional support anytime. Our team of experts
                        is passionate about your success, and help you get the
                        most out of Rista.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="hm_whycards">
                    <div className="hm_whycard_icon">
                      <span>
                        {" "}
                        <img src={whyicon9} alt="Icon" />
                      </span>
                    </div>
                    <div className="hm_why_infos">
                      <h4>Enterprise-grade security</h4>
                      <p>
                        Rest assured, your data is safe. Rista utilizes
                        industry-leading security protocols and data encryption
                        to protect your sensitive information.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="hm_whycards">
                    <div className="hm_whycard_icon">
                      <span>
                        {" "}
                        <img src={whyicon10} alt="Icon" />
                      </span>
                    </div>
                    <div className="hm_why_infos">
                      <h4>Scalability, built-in</h4>
                      <p>
                        Rista's robust architecture effortlessly grows with your
                        business. Manage your operations with confidence - from
                        a single location to a nationwide chain.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="hm_whycards">
                    <div className="hm_whycard_icon">
                      <span>
                        {" "}
                        <img src={whyicon11} alt="Icon" />
                      </span>
                    </div>
                    <div className="hm_why_infos">
                      <h4>Continuous innovation</h4>
                      <p>
                        Rista is constantly evolving. Expect exciting new
                        features and updates rolled out frequently, ensuring you
                        always access to the latest.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="hm_whycards">
                    <div className="hm_whycard_icon">
                      <span>
                        {" "}
                        <img src={whyicon12} alt="Icon" />
                      </span>
                    </div>
                    <div className="hm_why_infos">
                      <h4>Accessible​ on-the-go</h4>
                      <p>
                        Manage your restaurant from anywhere, anytime. Access
                        Rista from any device, keeping you in control and
                        connected at all times.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sec5new_bottom sec5_hiddepos">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="sec5ins">
                  {/* Our Customers say <b>Excellent</b> */}
                  <span className="hm_staricon">
                    <img src={cestar} alt="Icon" />
                  </span>
                  <small>
                    <b>Top 20 Technology Startups </b>
                  </small>
                  <small className="et_customr">LinkedIn, 2023</small>
                  <span className="hm_starline">|</span>
                  <span className="hm_staricon">
                    <img src={cestar} alt="Icon" />
                  </span>
                  <small>
                    <b>India’s Best POS</b>
                  </small>{" "}
                  <small className="et_customr">
                    ET Hospitality World, 2022
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="footer-bar-up pos_footer_page">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <h2 className="subheading">
                Focus on your guests while we take care of your restaurant.
              </h2>
              <h3 className="heading">
                We handle the details, providing exactly what you need—nothing
                more, nothing less.
              </h3>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="right-side">
                {/* <h4>Schedule your free demo today</h4> */}
                <h5>
                  We will reach out within 24 hours to schedule your slot.
                </h5>

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Mobile Number"
                    ref={footerMobileRef}
                    name="mobileNumberfooter"
                    value={footerMobile}
                    onChange={handleChangeFooterForm}
                    maxLength={10}
                  />
                  {hasFooterMobileErrorText && (
                    <span className="error" style={{ color: "red" }}>
                      {hasFooterMobileErrorText}
                    </span>
                  )}
                </div>
                <button
                  className="btn btn-primary bt-form"
                  onClick={() => {
                    if (validateFooterMobileForm()) {
                      setHasFooterMobileErrorText("");
                      openModal1();
                    }
                  }}
                >
                  Schedule a Demo
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-8 faq_btm faq_btm_pros">
        <div className="container">
          <h2 className="subheading">Frequently asked questions</h2>
          <div className="row mid">
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div
                className="accordion  accordion_rista accordion_rista2 accordion_rista3 accord_plusminus"
                id="faqaccord6"
              >
                <div className="card">
                  <div className="card-header" id="faqhead5">
                    <a
                      href="#"
                      className="btn btn-header-link"
                      data-toggle="collapse"
                      data-target="#faq4"
                      aria-expanded="true"
                      aria-controls="faq4"
                    >
                      {" "}
                      <span className="plusminus"></span>
                      What is a restaurant POS system?
                    </a>
                  </div>

                  <div
                    id="faq4"
                    className="collapse show"
                    aria-labelledby="faqhead4"
                    data-parent="#faqaccord6"
                  >
                    <div className="card-body">
                      <p>
                        Restaurant POS (Point of Sale) software is a specialized
                        tool created for managing transactions and overseeing
                        diverse operations within a restaurant setting. Its
                        functionalities include order placement, processing
                        payments, customizing menus, managing inventory,
                        generating reports, and much more.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqhead5">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq5"
                      aria-expanded="true"
                      aria-controls="faq5"
                    >
                      <span className="plusminus"></span>
                      What are the benefits of using restaurant management
                      software?
                    </a>
                  </div>

                  <div
                    id="faq5"
                    className="collapse"
                    aria-labelledby="faqhead5"
                    data-parent="#faqaccord6"
                  >
                    <div className="card-body">
                      <p>
                        <strong>
                          Using a restaurant management software offers several
                          benefits, including:
                        </strong>
                      </p>

                      <p>
                        <strong>Efficient Operations:</strong> It streamlines
                        various restaurant tasks such as order taking, inventory
                        management, and billing, leading to smoother operations
                        and reduced errors.
                      </p>

                      <p>
                        <strong>Improved Customer Service:</strong> With
                        features like quick order processing and accurate
                        billing, restaurant management software helps enhance
                        customer experience by reducing waiting times and
                        ensuring order accuracy.
                      </p>

                      <p>
                        <strong>Inventory Control:</strong> It also enables
                        better inventory management by tracking stock levels,
                        reducing wastage, and providing insights into popular
                        items for better stock planning.
                      </p>

                      <p>
                        <strong>Enhanced Reporting:</strong> The software
                        generates detailed reports on sales, expenses, and
                        customer preferences, helping restaurant owners make
                        informed decisions to improve profitability.
                      </p>

                      <p>
                        <strong>Menu Customization:</strong> Restaurant
                        management software allows for easy menu updates,
                        promotions, and customization based on customer
                        preferences and seasonal changes.
                      </p>

                      <p>
                        <strong>Cost Savings:</strong> By optimizing processes,
                        reducing errors, and improving inventory control,
                        restaurant management software can help restaurants save
                        money in the long run.
                      </p>

                      <p>
                        <strong>Integrations:</strong> Many restaurant
                        management solutions integrate with accounting, payroll,
                        and other business systems, streamlining overall
                        business management and reducing manual work.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqhead6">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq6"
                      aria-expanded="true"
                      aria-controls="faq6"
                    >
                      <span className="plusminus"></span>
                      What is RISTA for restaurants?
                    </a>
                  </div>

                  <div
                    id="faq6"
                    className="collapse"
                    aria-labelledby="faqhead6"
                    data-parent="#faqaccord6"
                  >
                    <div className="card-body">
                      <p dir="ltr">
                        RISTA is not just a Point of Sale (POS) System for
                        restaurants, it&rsquo;s so much more. RISTA is
                        restaurant tech designed for all types of restaurants,
                        be it fine dining, Quick Service Restaurants (QSRs),
                        Pubs, bars, cafes and lounges (PBCLs), cloud kitchens,
                        and food courts. It not only acts as a billing software
                        for restaurants but also handles all the
                        back-of-the-house operations like inventory management,
                        supply chain management, and whatnot. It&rsquo;s a
                        unified tool that works with all the tools you need for
                        your day-to-day operations, including{" "}
                        <a href="/directordering">Direct Ordering Platform</a>{" "}
                        (taking orders directly from your restaurant&rsquo;s
                        website or QR code with 0 commissions) and advanced
                        features such as table management, and a digital
                        catalog.&nbsp;
                      </p>

                      <p dir="ltr">
                        The main advantage of using a cloud-based POS system
                        instead of a traditional one is that you can control
                        your business operations from any place as long as you
                        have a stable internet connection. This includes
                        managing sales, inventory, menus, and other parts of
                        your business even if you're not physically there.
                        Cloud-based POS systems don't require you to buy costly
                        hardware upfront, such as servers, and you won't have
                        ongoing maintenance expenses like with traditional
                        systems. Additionally, with RISTA, you'll regularly get
                        updates and improvements to your restaurant POS system,
                        ensuring you always have the newest features and
                        security measures in place.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ban-bt">
                <NavLink onClick={openModal1}>Try Now</NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <div className="personal_modal_new business_modal_new">
        <Modal
          isOpen={modalIsOpen1}
          onRequestClose={closeModal1}
          // onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          className="customModalContent"
        >
          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2> */}
          {/* <button onClick={closeModal}>close</button> */}
          <div className="dotpe_popouter dotpe_popouter_new">
            <span
              className="closebtn_new"
              onClick={() => {
                closeModal1();
              }}
            >
              <img src={require("../../images/close-cross.png")} alt="Logo" />
            </span>
            <div className="row dotpe_popup dotpe_popup2 dotpe_popup3">
              <div className="dotpe_left col-md-5">
                <div className="dotpe_left_infos">
                  <h3>You are taking a right step for your business.</h3>
                  <h4>Trusted by 75 lakh+ businesses in india</h4>
                  <div className="dotpe_poplogo">
                    <img src={require("../../images/logo-w.png")} alt="Logo" />
                  </div>
                </div>
              </div>
              <div className="dotpe_right  col-md-7">
                <div className="dotpe_right_infos">
                  <div className="busi_loan_form">
                    <form>
                      <div className="form_heading">
                        <h3>Setup Free Demo</h3>
                        <p>
                          Fill in the details & our product specialist will
                          reach out to you
                        </p>
                      </div>
                      <div className={formClassName}>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          ref={inputName}
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                        />
                        {formErrors.name && (
                          <span className="error">{formErrors.name}</span>
                        )}
                      </div>
                      <div className={formClassName}>
                        {/* <div>
                          <select
                            className="form-control"
                            // value={formData.countryCode}
                            // onChange={handleChange}
                            // name="countryCode"
                          >
                              <option >
                                +91
                              </option>
                          </select>
                        </div> */}
                        <div className="mobilenum">
                          <span>+91</span>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="Mobile Number"
                            ref={inputNumber}
                            name="mobileNumber"
                            value={
                              footerMobile != ""
                                ? (formData.mobileNumber = footerMobile)
                                : formData.mobileNumber
                            }
                            onChange={handleChange}
                            maxLength={10}
                          />
                          {formErrors.mobileNumber && (
                            <span className="error">
                              {formErrors.mobileNumber}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className={formClassName}>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email Address"
                          ref={inputEmail}
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                        {formErrors.email && (
                          <span className="error">{formErrors.email}</span>
                        )}
                      </div>

                      <div className="form_click">
                        By clicking on continue, I hereby provide my consent to
                        receive emails, phone calls, messages and other
                        communications from DotPe to understand about DotPe's
                        services, offerings, promotions and other related
                        information.
                      </div>

                      <div className="formlead_btn">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            if (validateSecondForm()) {
                              validateAndOpenModal2();
                            }
                          }}
                          // onClick={validateAndOpenModal2}
                        >
                          Request Demo
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>

      <div className="personal_modal_new business_modal_new">
        <Modal
          isOpen={modalIsOpen2}
          onRequestClose={closeModal2}
          style={customStyles}
          contentLabel="Example Modal"
          className="customModalContent"
        >
          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2> */}
          {/* <button onClick={closeModal}>close</button> */}
          <div className="dotpe_popouter dotpe_popouter_new">
            <span
              className="closebtn_new"
              onClick={() => {
                closeModal2();
              }}
            >
              <img src={require("../../images/close-cross.png")} alt="Logo" />
            </span>
            <div className="row dotpe_popup dotpe_popup2 dotpe_popup3">
              <div className="dotpe_left col-md-5 dotpe_left2_mode">
                <div className="dotpe_left_infos  dotpe_left2_mode_infos">
                  <div className="dotpe_popcheck">
                    <img src={successcheck2} alt="Icon" />
                  </div>
                  <h4>You have successfully scheduled a call with us.</h4>
                  <div className="dotpe_poplogo">
                    <img src={require("../../images/logo-w.png")} alt="Logo" />
                  </div>
                </div>
              </div>
              <div className="dotpe_right  col-md-7">
                <div className="dotpe_right_infos">
                  <div className="busi_loan_form">
                    <form>
                      <div className="form_heading">
                        <h3>
                          Our team will reach out to you in next few minutes
                        </h3>
                        <p>Meanwhile tell us more about your 1 business day.</p>
                      </div>

                      <div className="form-group form_group_nice">
                        <label>Select a business category</label>
                        <NiceSelect
                          id="a-select"
                          placeholder="Select..."
                          className="sampleClass"
                          name="incomeSource"
                          value={formData.incomeSource}
                          onChange={handleChange}
                        >
                          <option value="Food and beverage">
                            Food and beverage
                          </option>
                          <option value="Clothing and accessories">
                            Clothing and accessories
                          </option>
                          <option value="Bakery">Bakery</option>
                          <option value="Salon">Salon</option>
                          <option value="Home Decor and furnishing">
                            Home Decor and furnishing
                          </option>
                          <option value="eCommerce">eCommerce</option>
                          <option value="Services">Services</option>
                        </NiceSelect>
                        {formErrors.incomeSource && (
                          <span className="error">
                            {formErrors.incomeSource}
                          </span>
                        )}
                      </div>
                      <div className="form-group form_group_nice form_group_nice2">
                        <label>Select your role in this business.</label>
                        <NiceSelect
                          id="a-select1"
                          placeholder="Select..."
                          className="sampleClass"
                          name="role"
                          value={formData.role}
                          onChange={handleChange}
                        >
                          <option value="Owner">Owner</option>
                          <option value="Store Manager">Store Manager</option>
                          <option value="Accounts">Accounts</option>
                          <option value="Sales">Sales</option>
                        </NiceSelect>
                        {formErrors.role && (
                          <span className="error">{formErrors.role}</span>
                        )}
                      </div>
                      <div className="formlead_btn formlead_btn2">
                        <button
                          type="button"
                          className="btn btn-primary"
                          // onClick={() => {
                          //   if (validateSecondForm()) {
                          //     saveDetails();
                          //   }
                          // }}
                          onClick={saveDetails}
                        >
                          Done
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>

      {/* <div className={`getdemo_float ${scrolledUp ? "" : "getdemo_show"}`}>
        <button type="submit" onClick={openModal1}>
          Get a Demo
        </button>
      </div> */}
    </div>
  );
};

export default RestaurantPointOfSaleSoftware;




