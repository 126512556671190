import React, { useRef, useState } from 'react';
import Footer from '../common/Footer';
import Header from '../common/Header';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import about_icon1 from '../../images/about/about_icon1.svg';
//import about_icon2 from "../../images/about/about_icon2.svg";
import about_icon3 from '../../images/about/about_icon3.svg';
import about_icon4 from '../../images/about/about_icon4.svg';
import { useNavigate, useLocation } from "react-router-dom";
import Modal from 'react-modal';
import successcheck2 from '../../images/success-check2.svg';
import NiceSelect from '../../NiceSelect/NiceSelect';
import BrandLogo from "../common/BrandLogo";
import { Helmet } from 'react-helmet-async';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1,
  },
};

const AboutUs = () => {
  const location = useLocation();
  const [firstFormId, setFirstFormId] = useState('');
  const resetForms = () => {
    setFormData({
      name: '',
      mobileNumber: '',
      pincode: '',
      incomeSource: '',
      buss_age: '',
      buss_turnover: '',
      email: '',
    });
  };
  const [hasError, setHasError] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    countryCode: '+91',
    mobileNumber: '',
    pincode: '',
    incomeSource: '',
    buss_age: '',
    buss_turnover: '',
    email: '',
  });
  const [formErrors, setFormErrors] = useState({
    name: '',
    mobileNumber: '',
    pincode: '',
    incomeSource: '',
    buss_age: '',
    buss_turnover: '',
    email: '',
  });
  const inputName = useRef(null);
  const inputNumber = useRef(null);
  const inputEmail = useRef(null);
  //const countryCodes = ["+91", "+1", "+44", "+61", "+81", "+86"];
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [modalIsOpen2, setModalIsOpen2] = useState(false);

  const openModal1 = () => {
    setModalIsOpen1(true);
  };

  const openModal2 = () => {
    setModalIsOpen2(true);
    setModalIsOpen1(false);
  };

  const closeModal1 = () => {
    setModalIsOpen1(false);
  };

  const closeModal2 = () => {
    setModalIsOpen2(false);
  };

  const [emailSuggestions, setEmailSuggestions] = useState([]);
  const domains = ['gmail.com', 'yahoo.com', 'hotmail.com'];

  const validateSecondForm = () => {
    const errors = {};
    let formIsValid = true;
    const nameRegex = /^[A-Za-z\s]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const numberRegex = /^\d+$/;

    if (!formData.name || !formData.name.trim()) {
      formIsValid = false;
      errors.name = 'Name is required';
    } else if (formData.name.trim().length < 3) {
      formIsValid = false;
      errors.name = 'Name should have at least 3 characters';
    } else if (formData.name.trim().length > 30) {
      formIsValid = false;
      errors.name = 'Name should have at max 30 characters';
    } else if (!nameRegex.test(formData.name.trim())) {
      formIsValid = false;
      errors.name = 'Name should contain only letters and spaces';
    }

    if (!formData.email || !formData.email.trim()) {
      formIsValid = false;
      errors.email = 'Email is required';
    } else if (!emailRegex.test(formData.email)) {
      formIsValid = false;
      errors.email = 'Invalid email address';
    }

    if (!formData.mobileNumber || !formData.mobileNumber.trim()) {
      formIsValid = false;
      errors.mobileNumber = 'Mobile Number is required';
    } else if (!numberRegex.test(formData.mobileNumber)) {
      formIsValid = false;
      errors.mobileNumber = 'Invalid mobile number';
    } else if (formData.mobileNumber.trim().length !== 10) {
      formIsValid = false;
      errors.mobileNumber = 'Mobile number must be exactly 10 digits';
    } else {
      // Ensure mobile number starts with 6, 7, 8, or 9
      const firstDigit = formData.mobileNumber.trim().charAt(0);
      if (!['6', '7', '8', '9'].includes(firstDigit)) {
        formIsValid = false;
        errors.mobileNumber = 'Mobile number must start with 6, 7, 8, or 9';
      }
    }

    setFormErrors(errors);
    setHasError(!formIsValid);

    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    if (name === 'name') {
      if (value === '' || /^[^\s]/.test(value)) {
        newValue = value;
      } else {
        newValue = value.trimStart();
      }
    } else if (name === 'mobileNumber') {
      newValue = value.replace(/\D/g, '');

      if (newValue.length > 10) {
        newValue = newValue.slice(0, 10);
      }
    } else if (name === 'email') {
      if (value.includes('@')) {
        const [localPart, domainPart] = value.split('@');
        setEmailSuggestions(
          domains
            .filter((domain) => domain.startsWith(domainPart))
            .map((domain) => `${localPart}@${domain}`)
        );
      } else {
        setEmailSuggestions([]);
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateSecondForm();
  };

  const formClassName = hasError ? 'form-error form-group' : 'form-group';
  const validateAndOpenModal2 = async () => {
    if (await validateSecondForm()) {
      const apiUrl = process.env.REACT_APP_FORM_ONE;
      const combinedData = {
        name: formData.name,
        phone: formData.mobileNumber,
        email: formData.email,
        source: window.location.href,
      };
      localStorage.setItem('mobile', formData.mobileNumber);
      console.log(combinedData);

      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json; charset=utf-8',
          },
          body: JSON.stringify(combinedData),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data);
        setFirstFormId(data.id);
        openModal2();
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  // const saveDetails = () => {
  //   closeModal2();
  // };
  const navigate = useNavigate();
  const validateForm = () => {
    const errors = {};
    let formIsValid = true;

    if (!formData.incomeSource || formData.incomeSource === '0') {
      formIsValid = false;
      errors.incomeSource = 'Please select a business category';
    }

    if (!formData.role || formData.role === '0') {
      formIsValid = false;
      errors.role = 'Please select your role';
    }

    setFormErrors(errors);
    return formIsValid;
  };

  const saveDetails = async () => {
    const isValid = validateForm();

    if (isValid) {
      const apiUrl = process.env.REACT_APP_FORM_ONE + '/' + firstFormId;
      const combinedData = {
        businessCategory: formData.incomeSource,
        role: formData.role,
      };
      console.log(combinedData);

      try {
        const response = await fetch(apiUrl, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(combinedData),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data);
        resetForms();
        closeModal2();
        navigate('/thankyou');
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };
  const [footerMobile, setFooterMobile] = useState("");
  const footerMobileRef = useRef(null);
  const [hasFooterMobileError, setHasFooterMobileError] = useState(false);
  const [hasFooterMobileErrorText, setHasFooterMobileErrorText] = useState("");
  const handleChangeFooterForm = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    if (name === "mobileNumberfooter") {
      console.log(newValue.length);
      newValue = value.replace(/\D/g, "");

      if (newValue.length > 10) {
        newValue = newValue.slice(0, 10);
      }
    }
    setFooterMobile(newValue);
  };
  const validateFooterMobileForm = () => {
    let formIsValid = true;
    const numberRegex = /^\d+$/;
    if (!footerMobile || !footerMobile.trim()) {
      formIsValid = false;
      setHasFooterMobileErrorText("Mobile Number is required");
    } else if (!numberRegex.test(footerMobile)) {
      formIsValid = false;
      setHasFooterMobileErrorText("Invalid mobile number");
    } else if (footerMobile.trim().length !== 10) {
      formIsValid = false;
      setHasFooterMobileErrorText("Mobile number must be exactly 10 digits");
    } else {
      const firstDigit = footerMobile.trim().charAt(0);
      if (!["6", "7", "8", "9"].includes(firstDigit)) {
        formIsValid = false;
        setHasFooterMobileErrorText(
          "Mobile number must start with 6, 7, 8, or 9"
        );
      }
    }
    setHasFooterMobileError(!formIsValid);

    return formIsValid;
  };
  return (
    <div>
      <Helmet>
        <title>
        About Us | Rista POS
        </title>
        <meta
          name="description"
          content="Best restaurant POS system to manage your restaurant billing, Inventory, Online Orders, KOT, Menu, Table Reservation & much more."
        />
        <meta
          name="keywords"
          content="Restaurant POS Software, Restaurant Billing Software, Restaurant Management Software, Restaurant Billing"
        />
        <meta name="author" content="Rista Apps" />
        <meta
          property="og:description"
          content="Best restaurant POS system to manage your restaurant billing, Inventory, Online Orders, KOT, Menu, Table Reservation & much more."
        />
        <meta
          property="og:image"
          content={require("../../images/logo-w.png")}
        />
      </Helmet>
      <Header />
      <section className="aboutmain">
        <div className="container">
          <div className="about_head">
            <h5>About Us</h5>
            <h2>Empowering Every Restaurant Journey</h2>
            <p>
              RISTA's cloud-based platform empowers cafes, bars, and kitchens to
              streamline operations, boost profits, and delight guests.
            </p>
          </div>
        </div>

        <div className="aboutteam_slides">
          <div className="owl-slider">
            <OwlCarousel
              items={20}
              className="owl-theme"
              // loop
              nav={false}
              dots={false}
              stagePadding={5}
              drag={false}
              margin={16}
              responsive={{
                0: { items: 2, stagePadding: 35, margin: 10 }, // Set 1 item for small screens (width < 600px)
                600: { items: 2 }, // Set 2 items for medium screens (600px <= width < 960px)
                1025: { items: 4 },
                1299: { items: 5 }, // Set 4 items for larger screens (width >= 960px)
              }}
            >
              <div className="item">
                <div className="about_teambox">
                  <div className="about_teamimg">
                    {" "}
                    <img
                      src={require("../../images/about/M1.jpg")}
                      alt=""
                      title=""
                    ></img>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="about_teambox">
                  <div className="about_teamimg">
                    <img
                      src={require("../../images/about/M2.jpg")}
                      alt=""
                      title=""
                    ></img>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="about_teambox">
                  <div className="about_teamimg">
                    <img
                      src={require("../../images/about/M3.jpg")}
                      alt=""
                      title=""
                    ></img>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="about_teambox">
                  <div className="about_teamimg">
                    <img
                      src={require("../../images/about/M4.jpg")}
                      alt=""
                      title=""
                    ></img>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="about_teambox">
                  <div className="about_teamimg">
                    <img
                      src={require("../../images/about/M5.jpg")}
                      alt=""
                      title=""
                    ></img>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section>

      {/* about1 */}

      <section className="sec-2 aboutsec2">
        <div className="container">
          <BrandLogo />

          <p className="para hmpara_btm">
            Thousands <small>of restaurants</small> run and grow{" "}
            <img src={require("../../images/till-arrow.png")}></img>{" "}
            <small>on</small> RISTA,
            <small> every day.</small>
          </p>
        </div>
      </section>

      {/* about 2 */}

      <section className="abouticons_main">
        <div className="container">
          <div className="abouticons_grid">
            <div className="row">
              <div className="col-md-3">
                <div className="aboutcards">
                  <div className="aboutcard_icon">
                    <span>
                      <img src={about_icon1} alt="Icon" />
                    </span>
                  </div>
                  <div className="about_infos">
                    <h4>All-in one Solution</h4>
                    <p>POS, inventory, online ordering, marketing, and more.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="aboutcards">
                  <div className="aboutcard_icon">
                    <span>
                      <img
                        src={require("../../images/about/about_icon2.png")}
                      ></img>
                    </span>
                  </div>
                  <div className="about_infos">
                    <h4>Actionable Insights</h4>
                    <p>Gain control with insightful reporting and analytics.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="aboutcards">
                  <div className="aboutcard_icon aboutcard_icon3">
                    <span>
                      <img src={about_icon3} alt="Icon" />
                    </span>
                  </div>
                  <div className="about_infos">
                    <h4>Seamless Integrations</h4>
                    <p>Connect effortlessly with your existing tools.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="aboutcards">
                  <div className="aboutcard_icon aboutcard_icon4">
                    <span>
                      <img src={about_icon4} alt="Icon" />
                    </span>
                  </div>
                  <div className="about_infos">
                    <h4>Personalized Support</h4>
                    <p>We help you get the most out of RISTA.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* about3 */}

      <section className="aboutsec4">
        <div className="container">
          <div className="about4main">
            <div className="about4infos">
              <h3>
                From Dot to Delight - empowering restaurants with dedication
              </h3>

              <div className="aboutpg_images aboutpg_images_mobile">
                <div className="about_pgimgone">
                  {" "}
                  <img
                    src={require("../../images/about-us-webp/about_secrightone.webp")}
                  ></img>
                </div>
                <div className="about_pgimgone about_pgimgtwo">
                  <img
                    src={require("../../images/about-us-webp/hm_banner1-1.webp")}
                  ></img>
                </div>
              </div>

              <p>
                <b>RISTA POS began as a spark</b> – a vision to simplify the
                chaos of restaurant operations. In the early days, it was a
                small team tackling a big challenge: building software that
                could streamline everything from billing to inventory. Fueled by
                a dedication to restaurants' success, RISTA POS grew
                organically. Every satisfied customer, every glowing review,
                became a stepping stone. We listened to the needs of cafes,
                bars, and kitchens of all sizes, constantly refining our
                features to address their unique challenges.
              </p>

              <p>
                <b>
                  Today, RISTA POS is a full-fledged restaurant management
                  suite.
                </b>
                We're no longer just processing orders. Our cloud-based platform
                offers everything from marketing tools to real-time analytics
                and inventory management, helping restaurants not just survive,
                but flourish. But our journey isn't over. We're constantly
                innovating, and adding new features and integrations to stay
                ahead of the curve.
              </p>
            </div>

            <div className="aboutpg_images aboutpg_images_desk">
              <div className="about_pgimgone">
                {" "}
                <img
                  src={require("../../images/about-us-webp/about_secrightone.webp")}
                ></img>
              </div>
              <div className="about_pgimgone about_pgimgtwo">
                <img
                  src={require("../../images/about-us-webp/hm_banner1-1.webp")}
                ></img>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* about4 */}

      <section className="sec-2 aboutsec2 aboutseclast">
        <div className="container">
          <div className="about_head about_head_last">
            <h2>Our commitment to your success</h2>
            <p>
              We understand that every restaurant is unique. That's why we offer
              flexible solutions and personalized support to meet your specific
              needs. Our team of experts is dedicated to helping you get the
              most out of RISTA and achieve your business goals.
            </p>
          </div>

          <div className="about2_brands_logo">
            <BrandLogo />
          </div>
          <p className="para hmpara_btm ab-crousel-text">
            Thousands of restaurants worldwide trust RISTA to power their
            success.  <img src={require("../../images/till-arrow.png")}></img>
          </p>
        </div>
      </section>

      {/* about5 */}

      <section className="footer-bar-up whatsapp_footer_bar">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <h2 className="subheading subheading_aboutfoot">
                See how we can help you take your restaurant to the next level
              </h2>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="right-side">
                <h4>Schedule your free demo today</h4>
                <h5>
                  We will reach out within 24 hours to schedule your slot.
                </h5>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Mobile Number"
                    ref={footerMobileRef}
                    name="mobileNumberfooter"
                    value={footerMobile}
                    onChange={handleChangeFooterForm}
                    maxLength={10}
                  />
                  {hasFooterMobileErrorText && (
                    <span className="error" style={{ color: "red" }}>
                      {hasFooterMobileErrorText}
                    </span>
                  )}
                </div>
                <button
                  className="btn btn-primary bt-form"
                  onClick={() => {
                    if (validateFooterMobileForm()) {
                      setHasFooterMobileErrorText("");
                      openModal1();
                    }
                  }}
                >
                  Get a Demo
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="personal_modal_new business_modal_new">
        <Modal
          isOpen={modalIsOpen1}
          onRequestClose={closeModal1}
          // onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          className="customModalContent"
        >
          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2> */}
          {/* <button onClick={closeModal}>close</button> */}
          <div className="dotpe_popouter dotpe_popouter_new">
            <span
              className="closebtn_new"
              onClick={() => {
                closeModal1();
              }}
            >
              <img src={require("../../images/close-cross.png")} alt="Logo" />
            </span>
            <div className="row dotpe_popup dotpe_popup2 dotpe_popup3">
              <div className="dotpe_left col-md-5">
                <div className="dotpe_left_infos">
                  <h3>You are taking a right step for your business.</h3>
                  <h4>Trusted by 75 lakh+ businesses in india</h4>
                  <div className="dotpe_poplogo">
                    <img src={require("../../images/logo-w.png")} alt="Logo" />
                  </div>
                </div>
              </div>
              <div className="dotpe_right  col-md-7">
                <div className="dotpe_right_infos">
                  <div className="busi_loan_form">
                    <form>
                      <div className="form_heading">
                        <h3>Setup Free Demo</h3>
                        <p>
                          Fill in the details & our product specialist will
                          reach out to you
                        </p>
                      </div>
                      <div className={formClassName}>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          ref={inputName}
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                        />
                        {formErrors.name && (
                          <span className="error">{formErrors.name}</span>
                        )}
                      </div>
                      <div className={formClassName}>
                        {/* <div>
                          <select
                            className="form-control"
                            // value={formData.countryCode}
                            // onChange={handleChange}
                            // name="countryCode"
                          >
                              <option >
                                +91
                              </option>
                          </select>
                        </div> */}
                        <div className="mobilenum">
                          <span>+91</span>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="Mobile Number"
                            ref={inputNumber}
                            name="mobileNumber"
                            value={
                              footerMobile != ""
                                ? (formData.mobileNumber = footerMobile)
                                : formData.mobileNumber
                            }
                            onChange={handleChange}
                            maxLength={10}
                          />
                          {formErrors.mobileNumber && (
                            <span className="error">
                              {formErrors.mobileNumber}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className={formClassName}>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email Address"
                          ref={inputEmail}
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                        {formErrors.email && (
                          <span className="error">{formErrors.email}</span>
                        )}
                      </div>

                      <div className="form_click">
                        By clicking on continue, I hereby provide my consent to
                        receive emails, phone calls, messages and other
                        communications from DotPe to understand about DotPe's
                        services, offerings, promotions and other related
                        information.
                      </div>

                      <div className="formlead_btn">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            if (validateSecondForm()) {
                              validateAndOpenModal2();
                            }
                          }}
                          // onClick={validateAndOpenModal2}
                        >
                          Request Demo
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>

      <div className="personal_modal_new business_modal_new">
        <Modal
          isOpen={modalIsOpen2}
          onRequestClose={closeModal2}
          style={customStyles}
          contentLabel="Example Modal"
          className="customModalContent"
        >
          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2> */}
          {/* <button onClick={closeModal}>close</button> */}
          <div className="dotpe_popouter dotpe_popouter_new">
            <span
              className="closebtn_new"
              onClick={() => {
                closeModal2();
              }}
            >
              <img src={require("../../images/close-cross.png")} alt="Logo" />
            </span>
            <div className="row dotpe_popup dotpe_popup2 dotpe_popup3">
              <div className="dotpe_left col-md-5 dotpe_left2_mode">
                <div className="dotpe_left_infos  dotpe_left2_mode_infos">
                  <div className="dotpe_popcheck">
                    <img src={successcheck2} alt="Icon" />
                  </div>
                  <h4>You have successfully scheduled a call with us.</h4>
                  <div className="dotpe_poplogo">
                    <img src={require("../../images/logo-w.png")} alt="Logo" />
                  </div>
                </div>
              </div>
              <div className="dotpe_right  col-md-7">
                <div className="dotpe_right_infos">
                  <div className="busi_loan_form">
                    <form>
                      <div className="form_heading">
                        <h3>
                          Our team will reach out to you in next few minutes
                        </h3>
                        <p>Meanwhile tell us more about your 1 business day.</p>
                      </div>

                      <div className="form-group form_group_nice">
                        <label>Select a business category</label>
                        <NiceSelect
                          id="a-select"
                          placeholder="Select..."
                          className="sampleClass"
                          name="incomeSource"
                          value={formData.incomeSource}
                          onChange={handleChange}
                        >
                          <option value="Food and beverage">
                            Food and beverage
                          </option>
                          <option value="Clothing and accessories">
                            Clothing and accessories
                          </option>
                          <option value="Bakery">Bakery</option>
                          <option value="Salon">Salon</option>
                          <option value="Home Decor and furnishing">
                            Home Decor and furnishing
                          </option>
                          <option value="eCommerce">eCommerce</option>
                          <option value="Services">Services</option>
                        </NiceSelect>
                        {formErrors.incomeSource && (
                          <span className="error">
                            {formErrors.incomeSource}
                          </span>
                        )}
                      </div>
                      <div className="form-group form_group_nice form_group_nice2">
                        <label>Select your role in this business.</label>
                        <NiceSelect
                          id="a-select1"
                          placeholder="Select..."
                          className="sampleClass"
                          name="role"
                          value={formData.role}
                          onChange={handleChange}
                        >
                          <option value="Owner">Owner</option>
                          <option value="Store Manager">Store Manager</option>
                          <option value="Accounts">Accounts</option>
                          <option value="Sales">Sales</option>
                        </NiceSelect>
                        {formErrors.role && (
                          <span className="error">{formErrors.role}</span>
                        )}
                      </div>
                      <div className="formlead_btn formlead_btn2">
                        <button
                          type="button"
                          className="btn btn-primary"
                          // onClick={() => {
                          //   if (validateSecondForm()) {
                          //     saveDetails();
                          //   }
                          // }}
                          onClick={saveDetails}
                        >
                          Done
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
