import React, { useState, useEffect, useRef } from 'react';
import './common.css';
import './main.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const BrandLogo = () => {
  return (
    <div className='owl-slider'>
      <OwlCarousel
        items={20}
        className='owl-theme'
        loop
        nav={false}
        autoplay={true}
        dot={false}
        autoplayTimeout={2000}
        // dot={false}
        margin={16}
        responsive={{
          0: { items: 5 }, // Set 1 item for small screens (width < 600px)
          600: { items: 8 }, // Set 2 items for medium screens (600px <= width < 960px)
          1025: { items: 10 },
          1299: { items: 15 }, // Set 4 items for larger screens (width >= 960px)
        }}
      >
        <div className='item'>
          <div className='restaurants-img'>
            <img
              src={require('../../images/Brand-Logos-webp/1.webp')}
              alt='Barista logo, Customer of Rista restaurant point of sale system'
              title='Barista logo, Customer of Rista restaurant point of sale system'
            ></img>
          </div>
        </div>
        <div className='item'>
          <div className='restaurants-img'>
            <img
              src={require('../../images/Brand-Logos-webp/2.webp')}
              alt='Social Logo, Client of Rista Restaurant Billing Software'
              title='Social Logo, Client of Rista Restaurant Billing Software'
            ></img>
          </div>
        </div>
        <div className='item'>
          <div className='restaurants-img'>
            <img
              src={require('../../images/Brand-Logos-webp/3.webp')}
              alt='Blue Tokia Logo, Client of Rista Cafe Billing Software'
              title='Blue Tokia Logo, Client of Rista Cafe Billing Software'
            ></img>
          </div>
        </div>
        <div className='item'>
          <div className='restaurants-img'>
            <img
              src={require('../../images/Brand-Logos-webp/4.webp')}
              alt='The Big Chill Cafe Logo, customer of rista billing software'
              title='The Big Chill Cafe Logo, customer of rista billing software'
            ></img>
          </div>
        </div>
        {/* <div className='item'>
          <div className='restaurants-img'>
            <img
              src={require('../../images/Brand-Logos-webp/5.webp')}
              alt="Haldiram's logo, customer of rista restaurant pos"
              title="Haldiram's logo, customer of rista restaurant pos"
            ></img>
          </div>
        </div> */}
        <div className='item'>
          <div className='restaurants-img'>
            <img
              src={require('../../images/Brand-Logos-webp/6.webp')}
              alt='wow momo logo, customer of rista restaurant billing pos'
              title='wow momo logo, customer of rista restaurant billing pos'
            ></img>
          </div>
        </div>
        <div className='item'>
          <div className='restaurants-img'>
            <img
              src={require('../../images/Brand-Logos-webp/7.webp')}
              alt='Biryani Blues Logo, Client utilising Rista Restaurant POS'
              title='Biryani Blues Logo, Client utilising Rista Restaurant POS'
            ></img>
          </div>
        </div>
        <div className='item'>
          <div className='restaurants-img'>
            <img
              src={require('../../images/Brand-Logos-webp/8.webp')}
              alt='Frozen Bottle Logo, User of Rista Restaurant Billing Software'
              title='Frozen Bottle Logo, User of Rista Restaurant Billing Software'
            ></img>
          </div>
        </div>
        <div className='item'>
          <div className='restaurants-img'>
            <img
              src={require('../../images/Brand-Logos-webp/9.webp')}
              alt='belgian fries'
              title='belgian fries'
            ></img>
          </div>
        </div>
        <div className='item'>
          <div className='restaurants-img'>
            <img
              src={require('../../images/Brand-Logos-webp/10.webp')}
              alt='Truffles'
              title='Truffles'
            ></img>
          </div>
        </div>
        <div className='item'>
          <div className='restaurants-img'>
            <img
              src={require('../../images/Brand-Logos-webp/11.webp')}
              alt='Charcoal Eats'
              title='Charcoal Eats'
            ></img>
          </div>
        </div>
        <div className='item'>
          <div className='restaurants-img'>
            <img
              src={require('../../images/Brand-Logos-webp/12.webp')}
              alt='Pizza Wings'
              title='Pizza Wings'
            ></img>
          </div>
        </div>
        <div className='item'>
          <div className='restaurants-img'>
            <img
              src={require('../../images/Brand-Logos-webp/13.webp')}
              alt='Tibbs Frankie'
              title='Tibbs Frankie'
            ></img>
          </div>
        </div>
        <div className='item'>
          <div className='restaurants-img'>
            <img
              src={require('../../images/Brand-Logos-webp/14.webp')}
              alt="Nirula's"
              title="Nirula's"
            ></img>
          </div>
        </div>
        <div className='item'>
          <div className='restaurants-img'>
            <img
              src={require('../../images/Brand-Logos-webp/15.webp')}
              alt='The Brooklyn Creamery'
              title='The Brooklyn Creamery'
            ></img>
          </div>
        </div>
        <div className='item'>
          <div className='restaurants-img'>
            <img
              src={require('../../images/Brand-Logos-webp/16.webp')}
              alt='Kanti Sweets'
              title='Kanti Sweets'
            ></img>
          </div>
        </div>
        <div className='item'>
          <div className='restaurants-img'>
            <img
              src={require('../../images/Brand-Logos-webp/17.webp')}
              alt='Zomoz'
              title='Zomoz'
            ></img>
          </div>
        </div>
        <div className='item'>
          <div className='restaurants-img'>
            <img
              src={require('../../images/Brand-Logos-webp/18.webp')}
              alt='Meghana Foods'
              title='Meghana Foods'
            ></img>
          </div>
        </div>
        <div className='item'>
          <div className='restaurants-img'>
            <img
              src={require('../../images/Brand-Logos-webp/19.webp')}
              alt='Nic'
              title='Nic'
            ></img>
          </div>
        </div>
        <div className='item'>
          <div className='restaurants-img'>
            <img
              src={require('../../images/Brand-Logos-webp/20.webp')}
              alt='Giani Ice Cream'
              title='Giani Ice Cream'
            ></img>
          </div>
        </div>
        <div className='item'>
          <div className='restaurants-img'>
            <img
              src={require('../../images/Brand-Logos-webp/21.webp')}
              alt='Smoor'
              title='Smoor'
            ></img>
          </div>
        </div>
        <div className='item'>
          <div className='restaurants-img'>
            <img
              src={require('../../images/Brand-Logos-webp/22.webp')}
              alt='Ribbons Balloons'
              title='Ribbons Balloons'
            ></img>
          </div>
        </div>
        <div className='item'>
          <div className='restaurants-img'>
            <img
              src={require('../../images/Brand-Logos-webp/23.webp')}
              alt='Absolute Barbeeues'
              title='Absolute Barbeeues'
            ></img>
          </div>
        </div>
        <div className='item'>
          <div className='restaurants-img'>
            <img
              src={require('../../images/Brand-Logos-webp/24.webp')}
              alt='Zepto'
              title='Zepto'
            ></img>
          </div>
        </div>
        <div className='item'>
          <div className='restaurants-img'>
            <img
              src={require('../../images/Brand-Logos-webp/25.webp')}
              alt='Burgrill'
              title='Burgrill'
            ></img>
          </div>
        </div>
        <div className='item'>
          <div className='restaurants-img'>
            <img
              src={require('../../images/Brand-Logos-webp/26.webp')}
              alt='Zing'
              title='Zing'
            ></img>
          </div>
        </div>
        <div className='item'>
          <div className='restaurants-img'>
            <img
              src={require('../../images/Brand-Logos-webp/27.webp')}
              alt='Eatfit'
              title='Eatfit'
            ></img>
          </div>
        </div>
        <div className='item'>
          <div className='restaurants-img'>
            <img
              src={require('../../images/Brand-Logos-webp/28.webp')}
              alt='Biryani Blues Logo, Client utilising Rista Restaurant POS'
              title='Biryani Blues Logo, Client utilising Rista Restaurant POS'
            ></img>
          </div>
        </div>
        <div className='item'>
          <div className='restaurants-img'>
            <img
              src={require('../../images/Brand-Logos-webp/29.webp')}
              alt='Indigo'
              title='Indigo'
            ></img>
          </div>
        </div>
        <div className='item'>
          <div className='restaurants-img'>
            <img
              src={require('../../images/Brand-Logos-webp/30.webp')}
              alt='Indigo'
              title='Indigo'
            ></img>
          </div>
        </div>
      </OwlCarousel>
    </div>
  );
};

export default BrandLogo;
